import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { postReq } from "../../configs/ApiUrl.js";
import MoblerHeader from "../MoblerHeader.js";
import AlwaysTop from "./util/AlwaysTop.js";
import InputField from "../InputField.js";
import AuthContext from "./util/AuthContext.js";

function Admin() {
  const { t } = useTranslation(["admin", "forms", "altText"]);
  const { login } = useContext(AuthContext); // Destructure the login function from AuthContext

  const [background, setBackground] = useState({
    email: "bg-white",
    password: "bg-white",
  });

  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [emailState, setEmailState] = useState({
    value: "",
    emailBackground: "",
    emailHolder: "",
  });
  const [passwordState, setPasswordState] = useState({
    value: "",
    passwordBackground: "",
    passwordHolder: "",
  });
  const navigateMenu = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;

    if (!email || !password) {
      setLoginError("Please enter your email and password.");
      setBackground({
        email: !email ? "bg-red-200" : "bg-white",
        password: !password ? "bg-red-200" : "bg-white",
      });
      return;
    }

    try {
      setIsLoading(true);
      const adminLoginData = { email, password };
      const loginResponse = await postReq("/user/check-admin", adminLoginData);

      if (loginResponse?.success) {
        login(loginResponse.user, loginResponse.token); // Use login from AuthContext
        navigateMenu("/admin-menu");
      } else {
        setLoginError("Incorrect email or password.");
      }
    } catch (error) {
      setLoginError("An error occurred while logging in.");
      console.error("Internal server error at logging in:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    AlwaysTop();
  }, []);

  return (
    <div className='flex flex-col items-center min-h-screen'>
      <div className='w-full bg-cromboGray'>
        <MoblerHeader />
      </div>
      <div className='max-w-md w-full px-6'>
        <header className='flex items-center pt-10 -ml-3'>
          <img
            alt={t("altText:verticalDivider")}
            src='./../assets/icons/divider-vertical.svg'
            className='w-8 h-10'
          />
          <h1 className='text-3xl font-bold'>{t("admin:loginTitle")}</h1>
        </header>
        <main className='mt-6'>
          <form onSubmit={handleSubmit}>
            <InputField
              label={t("forms:emailLabel")}
              name='email'
              value={emailState.value}
              placeholder={emailState.emailHolder}
              className={`${background.email} form-field px-4 py-2`}
              onClick={() => {
                setBackground({ ...background, email: "bg-white" });
                setEmailState({ ...emailState, emailBackground: "bg-white" });
                setLoginError(null);
              }}
              onChange={(e) =>
                setEmailState({ ...emailState, value: e.target.value })
              }
            />
            <InputField
              label={t("forms:passwordLabel")}
              name='password'
              type='password'
              value={passwordState.value}
              placeholder={passwordState.passwordHolder}
              className={`${background.password} form-field px-4 py-2`}
              onClick={() => {
                setBackground({ ...background, password: "bg-white" });
                setPasswordState({
                  ...passwordState,
                  passwordBackground: "bg-white",
                });
                setLoginError(null);
              }}
              onChange={(e) =>
                setPasswordState({ ...passwordState, value: e.target.value })
              }
            />
            <div className='flex flex-col items-center mt-8'>
              {loginError && (
                <h2 className='text-red-500 font-semibold mb-4'>
                  {loginError}
                </h2>
              )}
              <button
                className='btn-main flex items-center justify-center mb-5 w-full py-2'
                disabled={isLoading}>
                <h2 className='text-white text-lg lg:text-2xl text-center font-semibold'>
                  {t("forms:login")}
                </h2>
              </button>
            </div>
          </form>
        </main>
      </div>
    </div>
  );
}

export default Admin;
