import React from "react";
import { Result } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageFooter from "../components/PageFooter";

const ProductSuccess = () => {
  const { t } = useTranslation("utilityPages");
  const navigate = useNavigate();
  const resultStyle = {
    margin: "40px 0",
  };
  const handleShoping = () => {
    navigate("/shopping-home");
  };
  const handleAddProduct = () => {
    navigate("/selling-add-furniture");
  };
  return (
    <div className='flex flex-col items-center mb-44'>
      <div className='max-w-md'>
        <main className='font-bold text-lg'>
          <Result
            style={resultStyle}
            status='success'
            title={t("creationSuccess")}
            subTitle={t("creationSubtext")}
            extra={[
              <div
                className='btn-main mb-6'
                onClick={handleShoping}
                key='console'>
                {t("shoppingReturnButton")}
              </div>,
              <div
                className='btn-secondary mb-3'
                onClick={handleAddProduct}
                key='buy'>
                {t("addProductButton")}
              </div>,
            ]}
          />
        </main>
        <footer>
        <PageFooter />
        </footer>
      </div>
    </div>
  );
};

export default ProductSuccess;
