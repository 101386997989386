import React from "react";
import { useNavigate } from "react-router-dom";
import successTick from "../../../images/successTick.svg";
import { useTranslation } from "react-i18next";

export const OrderModalKlarna = ({ orderData }) => {
  const { t } = useTranslation('utilityPages');
  const navigate = useNavigate();

  if (!orderData) return null;

  const { order_amount, purchase_currency, billing_address = {}, status } = orderData;
  const { given_name, family_name, street_address, postal_code, city, country } = billing_address;

  const paymentStatusMessage = status === 'checkout_complete' ? t('Payment Successful') : t('Payment Pending');

  return (
    <div className="flex flex-col items-center">
      <div className="w-[450px] flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="w-[350px] flex flex-col items-center justify-center p-5 mt-10 mb-10 py-8 bg-white rounded-[30px]">
          <div className="text-center flex flex-col justify-center items-center space-y-4">
            <img className="w-[128px] h-[90px] mt-5 mb-5" src={successTick} alt="success-tick" />
            <h1 className="text-3xl font-bold text-green-500">{t('orderCongrats')}</h1>
            <p className="text-xl text-gray-800">{paymentStatusMessage}</p>
            <ul className="text-gray-600 flex flex-col items-center justify-center">
              <li>{`${t('Order Amount')}:`} <strong>{`${order_amount /100} ${purchase_currency}`}</strong></li>
              {billing_address && (
                <li>{`${t('Billing Address')}:`} <strong>{`${given_name || ''} ${family_name || ''}, ${street_address || ''}, ${postal_code || ''} ${city || ''}, ${country ? country.toUpperCase() : ''}`}</strong></li>
              )}
            </ul>
          </div>
          <button onClick={() => navigate('/shopping-home')} className="mt-8 px-4 py-2 bg-cromboOrange text-white rounded-[20px] shadow-sm hover:bg-cromboOrangeFade focus:outline-none">
            {t('shoppingReturnButton')}
          </button>
        </div>
      </div>
    </div>
  );
};
