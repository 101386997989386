/* This is an NPM package that allows the translation of the app to Swedish. The docs can be found
here: https://www.i18next.com/. the line to install the packages is npm install react-i18next i18next-browser-languagedetector i18next-http-backend

A cliffnotes version however is that the package uses a key/value pair format. The key must be the
same in each language object (en and sv), and the value must be the translation of the text. The translations are stored in
the locales folder, with each language needing identical .json files.

The JSON files are organised by order of which they appear on the page (top to bottom on the page and the file).
Sometimes however (to avoid duplication), a page may borrow a translation from an unrelated page. To help with debugging 
and maintainability, I have included explicit calls to where each translation came from, making them easier to locate.

*/

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

// Function to get the language from local storage
const getSavedLanguage = () => {
  const savedLanguage = localStorage.getItem('i18nextLng');
  return savedLanguage || 'sv'; // default to 'sv' if no saved language
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    lng: getSavedLanguage(),
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      ns: ['forms', 'homepage', 'productPage', 'favouritesPage', 'cartPage', 'userProfile'],
      defaultNS: 'homepage',
    },
  });

// Listen for language change and save it to local storage
i18n.on('languageChanged', (lng) => {
  localStorage.setItem('i18nextLng', lng);
});

export default i18n;
