import React from "react";
import { useTranslation } from 'react-i18next';

//Header icon: Gray 24Mobler icon in a gray background

function MoblerHeader() {
  const { t } = useTranslation('moblerHeader');
    return (
        <article className="bg-cromboGray h-64 flex justify-center items-center">
          <img alt={t('logo')} src="./../assets/images/24Mobler.png" className="w-64 relative top-6"></img>
        </article>
    );
}

export default MoblerHeader;