import React, { useState, useEffect } from "react";

const AddDiscountModal = ({ isOpen, onClose, onSave, type, flatRateData, percentageData, errorMessage, setErrorMessage }) => {
  const [newCode, setNewCode] = useState("");
  const [newValue, setNewValue] = useState("");

  useEffect(() => {
    if (isOpen) {
      setErrorMessage(""); // Clear the error message when the modal is opened
    }
  }, [isOpen, setErrorMessage]);

  const handleSave = () => {
    const existingData = type === "flatRate" ? flatRateData : percentageData;
    const codeExists = existingData.some(item => item.code === newCode);

    if (codeExists) {
      setErrorMessage(`The code "${newCode}" already exists.`);
      return; // Stop execution if the code already exists
    }

    onSave(newCode, newValue, type);
    setNewCode("");
    setNewValue("");
    setErrorMessage(""); // Clear the error message on successful save
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-12 rounded-lg">
        <h2 className="text-2xl mb-4">Add New {type === "flatRate" ? "Flat Rate" : "Percentage"} Code</h2>
        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
        <label className="block mb-2">
          Code:
          <input
            type="text"
            value={newCode}
            onChange={(e) => setNewCode(e.target.value)}
            className="form-field py-1 w-full"
          />
        </label>
        <label className="block mb-4">
          Value:
          <input
            type="number"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            className="form-field py-1 w-full"
          />
        </label>
        <div className="flex justify-end mt-6">
          <button onClick={onClose} className="btn-secondary mr-2">
            Cancel
          </button>
          <button onClick={handleSave} className="btn-main">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddDiscountModal;