import React from "react";

const Checkbox = ({ name, label, inputDisplay, setInputDisplay }) => {
  const handleChange = () => {
    const updatedRooms = Array.isArray(inputDisplay.rooms)
      ? [...inputDisplay.rooms]
      : []; // Ensure inputDisplay.rooms is an array
    const roomIndex = updatedRooms.indexOf(name);
    if (roomIndex !== -1) {
      updatedRooms.splice(roomIndex, 1); // Remove room if already selected
    } else {
      updatedRooms.push(name); // Add room if not selected
    }
    setInputDisplay({ ...inputDisplay, rooms: updatedRooms });
  };

  return (
    <div className="w-40">
      <input
        type="checkbox"
        name={name}
        checked={inputDisplay.rooms && inputDisplay.rooms.includes(name)} // Ensure inputDisplay.rooms is not null or undefined before checking its contents
        className="text-cromboOrange mr-1 ml-1 cursor-pointer"
        onChange={handleChange}
      />
      <label className="text-xs">{label}</label>
    </div>
  );
};

export default Checkbox;