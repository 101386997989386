// OrderCard.js
import defaultImg from "../../../images/defaultImg.webp";
import { useTranslation } from "react-i18next";

function OrderCard({
  name,
  image,
  productCost,
  movingCost,
  extras,
  extraTotal,
  bonusDiscount,
  grandTotal,
}) {
  const { t } = useTranslation(["admin", "productPage", "utilityPages", "altText"]);
  
  return (
    // Minimal styling so that multiple OrderCards line up side by side
    <div className="flex flex-row items-center space-x-4 py-2 px-4">
      {/* Image */}
      <div className="w-24 h-24 flex-shrink-0">
        <img
          alt={name}
          src={image || defaultImg}
          onError={(e) => { e.target.src = defaultImg; }}
          className="object-contain h-full w-full"
        />
      </div>

      {/* Details */}
      <div className="flex flex-col">
        {/* Name */}
        <h3 className="font-semibold text-gray-800">{name}</h3>

        {/* Price rows */}
        <div className="text-sm text-gray-600 mt-1">
          <span className="font-medium">{t("admin:price")}:</span>{" "}
          {productCost} kr.
        </div>
        <div className="text-sm text-gray-600">
          <span className="font-medium">{t("productPage:deliveryLabel")}:</span>{" "}
          {movingCost} kr.
        </div>

        {extras?.map((extra, i) => (
          <div key={i} className="text-sm text-gray-600">
            <span className="font-medium">{extra.quantity} x {extra.item}:</span>{" "}
            {extra.cost} kr.
          </div>
        ))}

        <div className="text-sm text-gray-600">
          <span className="font-medium">{t("utilityPages:addonTotal")}:</span>{" "}
          {extraTotal} kr.
        </div>

        {bonusDiscount > 0 && (
          <div className="text-sm text-green-600">
            <span className="font-medium">{t("utilityPages:bonusDiscount")}:</span>{" "}
            -{bonusDiscount} kr.
          </div>
        )}

        {/* Grand Total */}
        <div className="mt-2 text-base font-bold text-cromboOrange">
          {t("admin:grandTotalHeader")}: {grandTotal} kr.
        </div>
      </div>
    </div>
  );
}

export default OrderCard;
