import React from 'react';

const InputField = ({ label, name, value, onChange, as = 'input', className = '', ...props }) => (
  <div className="text-base mb-3 font-semibold mt-2">
    <h2>{label}</h2>
    {as === 'textarea' ? (
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        rows={props.rows || 3}
        className={`form-field pl-2 mt-1 w-full rounded-md ${className}`}  // Merge className with default classes
        {...props}
      />
    ) : (
      <input
        name={name}
        value={value}
        onChange={onChange}
        className={`form-field pl-2 mt-1 h-10 w-full rounded-md ${className}`}  // Merge className with default classes
        {...props}
      />
    )}
  </div>
);

export default InputField;