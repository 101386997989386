import { FaTruck } from "react-icons/fa";
import Label from "./ConditionLabel";

// This props component is used to generate cards for each item of furniture presented in the Catalogue section of the webpage.
function FurnitureCard(props) {
  // Determine the label text and whether the badge should be shown based on the props
  const condition = props.condition;
  const verified = props.verified === "yes";
  let labelText = condition === "used" ? "Used" : "New";

  return (
    <article>
      <div className="px-1">
        <div
          id={props.id}
          name={props.name}
          className="rounded-3xl cursor-pointer relative"
        >
          <div className="absolute top-[0px] z-10">
            {props.showConditionLabel && (
              <Label text={labelText} approved={verified} />
            )}
          </div>
          <div className="flex justify-center items-center">
            <img
              id={props.id}
              name={props.name}
              src={props.url}
              alt={props.name}
              className="h-[250px] w-full p-3"
            />
          </div>
          <div>
            <div
              id={props.id}
              name={props.name}
              className="text-stone-500 font-semibold truncate text-ellipsis pl-2"
            >
              {props.name}
            </div>
            <div
              id={props.id}
              name={props.name}
              className="font-semibold pl-2"
            >
              {props?.price
                ?.toFixed(0)
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + " kr"}
            </div>
            <div className="text-stone-500 pl-2 flex text-sm font-semibold">
              <FaTruck size={20} />
              <div className="pl-1 mb-2">
                {props?.moving
                  ?.toFixed(0)
                  .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + " kr"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default FurnitureCard;