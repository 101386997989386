import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import { FaRulerHorizontal, FaRulerVertical, FaTruck } from "react-icons/fa";
import { GoHeart, GoHeartFill } from "react-icons/go";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FurnitureCard from "../FurnitureCard.js";
import PageHeader from "../PageHeader.js";
import { FurnitureSearch } from "../../App.js";
import defaultImg from "../../images/defaultImg.webp";
import { useTranslation } from "react-i18next";
import { RiVerifiedBadgeFill, RiEmotionSadLine } from "react-icons/ri";
import DepthIcon from "../../images/depthicon.png";
import "../pages/util/custom-slick.css";
import AlwaysTop from "./util/AlwaysTop.js";
import { postReq, getReq } from "../../configs/ApiUrl.js";
import PageFooter from "../PageFooter.js";

function CatalogueProduct() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [extraGrandTotal, setExtraGrandTotal] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0);
  const [favourite, setFavourite] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation(["productPage", "altText", "utilityPages"]);
  const [searchTerm, setSearchTerm] = useContext(FurnitureSearch);

  const [display, setDisplay] = useState({
    mainDisplay: searchTerm.productDisplayMain,
    zoomDisplay: searchTerm.productDisplayZoom,
  });

  const [addProduct, setAddProduct] = useState({
    plusMinusDisplay: 0,
    discountDisplay: 1,
    totalDisplay: 0,
    productDisplay: "",
  });

  const [addMoving, setAddMoving] = useState({
    movingDisplay: 0,
    Discount: 1,
  });

  const [extra, setExtra] = useState({
    activeObject: null,
    extraArray: [],
    numbers: [],
    plusButton: [],
    extraTotal: [],
    allAddOnsSelected: false,
  });

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await getReq(`/product/${productId}`);
        if (response?.product) {
          setProduct(response.product);
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  useEffect(() => {
    if (product && !initialized) {
      setAddProduct({
        plusMinusDisplay: 1,
        discountDisplay: 1,
        totalDisplay: parseFloat(product.price),
        productDisplay:
          product.price.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          " kr",
      });

      setAddMoving({
        movingDisplay: parseFloat(product.deliveryPrice),
        Discount: 1,
      });

      setExtra((prev) => ({
        ...prev,
        extraArray: product.addOns,
        numbers: Array(product.addOns?.length || 0).fill(0),
        plusButton: Array(product.addOns?.length || 0).fill("visible"),
        extraTotal: Array(product.addOns?.length || 0).fill(0),
      }));

      setInitialized(true);
    }
  }, [product, initialized]);

  useEffect(() => {
    if (product) {
      // Calculate original total without applying any discount
      const originalTotal =
        addProduct.totalDisplay + addMoving.movingDisplay + extraGrandTotal;

      // Apply bonus discount if applicable to calculate the final total
      let calculatedTotal = originalTotal;

      if (extra.allAddOnsSelected && product.bonusDiscount) {
        calculatedTotal -= product.bonusDiscount;
      }

      // Set both totals: final total (with discount) and original total (without discount)
      setFinalTotal({ calculatedTotal, originalTotal });
    }
  }, [
    addProduct.totalDisplay,
    addMoving.movingDisplay,
    extraGrandTotal,
    extra.allAddOnsSelected,
    product,
  ]);

  const NextArrow = (props) => (
    <div
      className={`${props.className} custom-arrow next-arrow drop-shadow-lg`}
      style={{ ...props.style }}
      onClick={props.onClick}>
      <MdChevronRight />
    </div>
  );

  const PrevArrow = (props) => (
    <div
      className={`${props.className} custom-arrow prev-arrow drop-shadow-lg`}
      style={{ ...props.style }}
      onClick={props.onClick}>
      <MdChevronLeft />
    </div>
  );

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const handleAddToCart = async () => {
    if (addProduct.plusMinusDisplay === 0) return;

    const calculatedTotal = addProduct.totalDisplay + addMoving.movingDisplay + extraGrandTotal;
    const finalTotal = extra.allAddOnsSelected && product.bonusDiscount ? calculatedTotal - product.bonusDiscount : calculatedTotal;

    const orderObject = {
        productId: product._id,
        quantity: addProduct.plusMinusDisplay,
        discount: product.discount || 0,
        bonusDiscount: extra.allAddOnsSelected ? product.bonusDiscount || 0 : 0,
        productCost: addProduct.totalDisplay,
        movingCost: addMoving.movingDisplay || 0,
        extras: extra.extraArray.map((item, i) => ({
            item: item.item,
            quantity: extra.numbers[i],
            cost: extra.extraTotal[i],
        })),
        grandTotal: finalTotal,
        image: product.mainImage || product.imageNames[0],
    };

    // Send the updated cart to the backend
    try {
        const response = await postReq("/user/update-cart", {
            userId: localStorage.getItem("userId"),
            cartItems: [orderObject],
        });

        if (!response.success) {
            console.error("Failed to update cart on the server:", response.message);
        } else {
            console.log("Cart updated successfully on the server:", response.cart);
        }
    } catch (error) {
        console.error("Error updating cart on the server:", error);
    }

    // Navigate to checkout
    navigate("/checkout");
};

  const resetSelections = () => {
    setAddProduct({
      plusMinusDisplay: 0,
      discountDisplay: 1,
      totalDisplay: 0,
      productDisplay: "",
    });
    setAddMoving({ movingDisplay: 0, Discount: 1 });
    setExtra({
      ...extra,
      numbers: Array(extra.extraArray?.length || 0).fill(0),
      plusButton: Array(extra.extraArray?.length || 0).fill("visible"),
      extraTotal: Array(extra.extraArray?.length || 0).fill(0),
      allAddOnsSelected: false,
    });
    setExtraGrandTotal(0);
  };

  useEffect(() => {
    AlwaysTop();
  }, []);

  return (
    <div className='flex flex-col min-h-screen bg-white'>
      <header className='mb-10'>
        <PageHeader />
      </header>
      {!loading && !product ? (
        <div className='flex flex-grow flex-col justify-center items-center p-4'>
          <RiEmotionSadLine
            size={100}
            className='mb-2 text-stone-500'
          />
          <h2 className='mb-8 text-center font-semibold text-stone-500'>
            {t("productPage:noMatch")}
          </h2>
          <Link
            className='w-full'
            to='/shopping-home'>
            <button className='btn-main w-full'>
              {t("utilityPages:returnHomeButton")}
            </button>
          </Link>
        </div>
      ) : (
        <>
          <main className='flex-grow flex flex-col items-center w-full'>
            <header className='w-full py-4 mt-6 flex items-center'>
              <div
                className='flex items-center cursor-pointer ml-6'
                onClick={() => navigate("/shopping-home")}>
                <img
                  src='./../assets/icons/arrow.svg'
                  alt={t("altText:backArrow")}
                  width={45}
                  className='back-btn flex inline-flex drop-shadow cursor-pointer'
                  onClick={() => {
                    resetSelections();
                    navigate("/shopping-home");
                  }}
                />
              </div>
            </header>

            <section className='w-full relative flex flex-col items-center'>
              <div className={display.mainDisplay}>
                <div className='w-full mb-10'>
                  {!loading &&
                  searchTerm.searchFilter.length === 0 &&
                  !product ? (
                    <div className='pt-4 px-8'>{t("noMatch")}</div>
                  ) : (
                    <div
                      onClick={() =>
                        setDisplay({
                          ...display,
                          mainDisplay: "hidden",
                          zoomDisplay: "visible",
                        })
                      }>
                      {searchTerm.searchFilter?.map((currentObject) => (
                        <FurnitureCard
                          key={currentObject.id}
                          {...currentObject}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <article
                className={`${display.zoomDisplay} flex justify-center w-full`}>
                <div className='relative w-[350px] h-[350px] flex justify-center items-center overflow-hidden'>
                  <Slider
                    {...settings}
                    className='w-full'>
                    {product &&
                      [product.mainImage]
                        .concat(product.imageNames || [])
                        .filter(
                          (item, index, arr) =>
                            arr.indexOf(item) === index && item
                        )
                        .map((item, i) => {
                          return (
                            <div
                              key={i}
                              className='flex justify-center items-center w-full h-full'>
                              <img
                                src={item || defaultImg}
                                alt={product?.name}
                                className='object-contain max-w-full max-h-full'
                                onError={(e) => (e.target.src = defaultImg)}
                                style={{ margin: "auto", display: "block" }}
                              />
                            </div>
                          );
                        })}
                  </Slider>
                </div>
              </article>
              <hr className='w-full border-1.5 border-stone-200 mt-6' />
              <div className='w-full flex flex-col items-center rounded-t-lg p-3'>
                <div className='p-3 mt-4 w-full flex flex-row justify-center mt-2'>
                  <h1 className='mb-4 pr-4 text-2xl font-semibold break-words flex-grow'>
                    {product?.title}
                  </h1>
                  <h1 className='mb-4 text-cromboOrangeFade font-bold text-2xl whitespace-nowrap'>
                    {product?.price
                      .toFixed(0)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    kr
                  </h1>
                </div>
                <div className='w-full flex flex-row items-start relative'>
                  <div>
                    <div className='relative inline-flex items-center'>
                      <div className='text-cromboOrange bg-amber-100 p-1 flex justify-center items-center rounded-sm text-sm font-bold'>
                        Used
                      </div>
                      {product?.isQualityVerified && (
                        <RiVerifiedBadgeFill
                          className='absolute -top-2 -right-2 text-green-600'
                          size={16}
                        />
                      )}
                    </div>
                  </div>
                  <div className='w-full text-sm pl-4 pr-12 mt-1 text-stone-500'>
                    {product?.isQualityVerified
                      ? t("qualityVerified")
                      : t("qualityNotVerified")}
                  </div>

                  {/* Quantity controls */}
                  <div
                    className={`border-2 rounded-lg w-6 h-6 mx-4 flex items-center justify-center ${
                      addProduct.plusMinusDisplay <= 1
                        ? "border-stone-400 text-stone-500"
                        : "border-cromboOrange text-cromboOrange"
                    }`}
                    onClick={() => {
                      if (addProduct.plusMinusDisplay > 1) {
                        setAddProduct((prev) => ({
                          ...prev,
                          plusMinusDisplay: prev.plusMinusDisplay - 1,
                          totalDisplay:
                            (prev.plusMinusDisplay - 1) *
                            prev.discountDisplay *
                            product.price,
                        }));
                      }
                    }}>
                    <div className='text-2xl pt-2 p-3 cursor-pointer'>-</div>
                  </div>

                  <div>{addProduct.plusMinusDisplay}</div>

                  <div
                    className={
                      addProduct.plusMinusDisplay >= product?.quantity
                        ? "border-stone-400 text-stone-500 border-2 rounded-lg w-6 h-6 p-1 flex items-center justify-center mx-4"
                        : "border-cromboOrange text-cromboOrange border-2 rounded-lg w-6 h-6 p-1 flex items-center justify-center mx-4"
                    }
                    onClick={() => {
                      if (addProduct.plusMinusDisplay < product?.quantity) {
                        setAddProduct((prev) => ({
                          ...prev,
                          plusMinusDisplay: prev.plusMinusDisplay + 1,
                          totalDisplay:
                            (prev.plusMinusDisplay + 1) *
                            prev.discountDisplay *
                            product.price,
                        }));
                      }
                    }}>
                    <div className='text-2xl pt-2 p-3 cursor-pointer'>+</div>
                  </div>
                </div>

                <div className='w-full flex-col my-4'>
                  <div className='flex justify-start items-center -ml-3 mb-2'>
                    <img
                      alt={t("altText:verticalDivider")}
                      src='./../assets/icons/divider-vertical.svg'
                      className='w-8 h-8'
                    />
                    <div className='font-bold my-2'>{t("descLabel")}</div>
                  </div>
                  <div className='mb-3 w-full text-wrap'>
                    {product?.description}
                  </div>
                </div>

                <div className='flex text-stone-500 my-4'>
                  <div className='border-stone-500 border-2 rounded-xl text-[10px] w-[70px] h-[70px] text-center mr-2 flex flex-col items-center'>
                    <h2>{t("color")}</h2>
                    <img
                      alt={t("altText:colour")}
                      src='./../assets/icons/colour.svg'
                      width={30}></img>
                    <div>{product?.color}</div>
                  </div>
                  <div className='border-stone-500 border-2 rounded-xl text-[10px] w-[85px] h-[70px] text-center mr-2 flex flex-col items-center'>
                    <h2 className='text-stone-500'>{t("condition")}</h2>
                    <img
                      alt={t("altText:sofa")}
                      src='./../assets/icons/chair.svg'
                      width={30}></img>
                    <h2 className='text-stone-500'>{product?.condition}</h2>
                  </div>
                </div>

                <div className='flex text-stone-500 mb-8'>
                  <div className='border-stone-500 border-2 rounded-xl text-[10px] w-[70px] h-[70px] text-center mr-2 flex flex-col items-center'>
                    <p className='mb-1'>{t("height")}</p>
                    <div>
                      <FaRulerVertical size={30} />
                    </div>
                    <div>{product?.dimension?.height + " cm"}</div>
                  </div>
                  <div className='border-stone-500 border-2 rounded-xl text-[10px] w-[70px] h-[70px] text-center mr-2 flex flex-col items-center'>
                    <p className='mb-1'>{t("length")}</p>
                    <div>
                      <FaRulerHorizontal size={30} />
                    </div>
                    <div>{product?.dimension?.width + " cm"}</div>
                  </div>
                  <div className='border-stone-500 border-2 rounded-xl text-[10px] w-[70px] h-[70px] text-center mr-2 flex flex-col items-center'>
                    <p className='mb-1'>{t("depth")}</p>
                    <div>
                      <img
                        src={DepthIcon}
                        className='w-7 h-8'
                        alt={t("altText:depth")}
                      />
                    </div>
                    <div>{product?.dimension?.depth + " cm"}</div>
                  </div>
                </div>

                <img
                  alt={t("altText:horizontalDivider")}
                  src='./../assets/icons/divider-horizontal.svg'
                  className='w-60'
                />

                <div className='text-stone-800 flex mt-4'>
                  <div className='pr-3 font-bold'>{t("deliveryLabel")}</div>
                  <div className='pl-1'>
                    <FaTruck size={22} />
                  </div>
                  {product?.deliveryPrice ? (
                    <div className='pl-3 font-bold'>
                      {product?.deliveryPrice} kr.
                    </div>
                  ) : (
                    <p className=' pl-3 font-bold text-green-600'>
                      Free Delivery
                    </p>
                  )}
                </div>

                {extra.extraArray.length > 0 && (
                  <>
                    <div className='w-full flex items-center justify-start'>
                      <div className='font-semibold text-lg mb-4 mt-6 ml-6'>
                        {t("extrasMessage")}
                      </div>
                    </div>

                    <form className='w-full flex flex-col items-center'>
                      <div>
                        {extra.extraArray.map((item, i) => (
                          <div
                            key={i}
                            className='flex font-semibold'>
                            <div className='flex'>
                              <div
                                className={`border-2 rounded-lg w-6 h-6 flex items-center justify-center mr-3 cursor-pointer ${
                                  extra.numbers[i] > 0
                                    ? "border-orange-500 text-orange-500"
                                    : "border-stone-400 text-stone-500"
                                }`}
                                onClick={() => {
                                  if (extra.numbers[i] === 0) return;

                                  const updatedNumbers = [...extra.numbers];
                                  updatedNumbers[i]--;

                                  const updatedExtraTotal = [
                                    ...extra.extraTotal,
                                  ];
                                  updatedExtraTotal[i] =
                                    item.price * updatedNumbers[i];

                                  const newExtraGrandTotal =
                                    updatedExtraTotal.reduce(
                                      (acc, curr) => acc + curr,
                                      0
                                    );

                                  setExtra((prevState) => ({
                                    ...prevState,
                                    numbers: updatedNumbers,
                                    extraTotal: updatedExtraTotal,
                                    allAddOnsSelected: updatedNumbers.every(
                                      (num) => num > 0
                                    ),
                                  }));

                                  setExtraGrandTotal(newExtraGrandTotal);
                                }}>
                                <div className='text-2xl pb-1'>-</div>
                              </div>

                              <div className='font-semibold'>
                                {extra.numbers[i]}
                              </div>

                              <div
                                className={`border-2 rounded-lg w-6 h-6 flex items-center justify-center ml-3 cursor-pointer ${
                                  extra.numbers[i] < item.number
                                    ? "border-cromboOrange text-cromboOrange"
                                    : "border-stone-400 text-stone-500"
                                }`}
                                onClick={() => {
                                  if (extra.numbers[i] === item.number) return;

                                  const updatedNumbers = [...extra.numbers];
                                  updatedNumbers[i]++;

                                  const updatedExtraTotal = [
                                    ...extra.extraTotal,
                                  ];
                                  updatedExtraTotal[i] =
                                    item.price * updatedNumbers[i];

                                  const newExtraGrandTotal =
                                    updatedExtraTotal.reduce(
                                      (acc, curr) => acc + curr,
                                      0
                                    );

                                  setExtra((prevState) => ({
                                    ...prevState,
                                    numbers: updatedNumbers,
                                    extraTotal: updatedExtraTotal,
                                    allAddOnsSelected: updatedNumbers.every(
                                      (num) => num > 0
                                    ),
                                  }));

                                  setExtraGrandTotal(newExtraGrandTotal);
                                }}>
                                <div className='text-2xl pb-1'>+</div>
                              </div>
                            </div>
                            <div
                              className={`${
                                extra.numbers[i] > 0
                                  ? "text-cromboOrange"
                                  : "text-stone-500"
                              } w-full ml-2 mb-4`}>
                              {`${item.item} = ${extra.extraTotal[i]} kr`}
                            </div>
                          </div>
                        ))}
                      </div>

                      {extra.extraArray.length > 0 &&
                        (extra.allAddOnsSelected && product?.bonusDiscount ? (
                          <div className='text-stone-500 flex align-center'>
                            <div className='pl-2 mb-2 text-stone-500 font-semibold text-md mt-6'>
                              {`${t("productPage:bonusDiscountLabel")} ${
                                product?.bonusDiscount || 0
                              } kr. ${t("productPage:bonusDiscountApplied")}`}
                            </div>
                          </div>
                        ) : (
                          <div className='pl-2 mb-2 text-stone-500 font-semibold text-md text-center mt-6'>
                            {product?.bonusDiscount === 0
                              ? `${t("productPage:noBonusDiscount")}`
                              : `${t("productPage:bonusDiscountMessage")} ${
                                  product?.bonusDiscount || 0
                                } kr.`}
                          </div>
                        ))}
                    </form>
                  </>
                )}

                <img
                  alt={t("altText:horizontalDivider")}
                  src='./../assets/icons/divider-horizontal.svg'
                  className='w-60 mb-4 mt-2'
                />

                <div className='flex justify-center text-cromboOrange font-bold text-3xl mt-4 mb-8'>
                  {t("totalLabel")}{" "}
                  {finalTotal.calculatedTotal
                    ?.toFixed(0)
                    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  kr
                  {extra.allAddOnsSelected && product.bonusDiscount > 0 && (
                    <span className='text-stone-500 line-through ml-2 text-xl'>
                      {finalTotal.originalTotal
                        ?.toFixed(0)
                        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                      kr
                    </span>
                  )}
                </div>

                <hr className='w-full border-1 border-stone-200 mb-6' />

                <div className='flex justify-center'>
                  <div
                    className={`border-2 rounded-lg p-2 flex items-center h-12 mr-2 ${
                      favourite ? "border-cromboOrange" : "border-stone-400"
                    }`}
                    onClick={() => {
                      setFavourite(!favourite);
                      const updatedFavourites = favourite
                        ? searchTerm.favourites.filter(
                            (id) => id !== product._id
                          )
                        : [...searchTerm.favourites, product._id];
                      setSearchTerm({
                        ...searchTerm,
                        favourites: updatedFavourites,
                      });
                    }}>
                    {favourite ? (
                      <GoHeartFill
                        size={30}
                        className='text-red-500'
                      />
                    ) : (
                      <GoHeart
                        size={30}
                        className='text-stone-400'
                      />
                    )}
                  </div>

                  <div
                    className='p-3 bg-cromboOrange text-white text-center font-semibold mb-3 rounded-lg w-[250px] cursor-pointer'
                    onClick={handleAddToCart}>
                    {t("addButton")}
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      )}
      <div className='block md:hidden'>
        <PageFooter />
      </div>
    </div>
  );
}

export default CatalogueProduct;
