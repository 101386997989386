import React from "react";
import { Link } from "react-router-dom";
import { HiOutlineArrowLongLeft } from "react-icons/hi2";

const PageSubheader = ({ backLink, title, description }) => {
  return (
    <header className='flex items-center bg-white w-full drop-shadow mb-10 px-4 p-4'>
      <div className="flex items-center">
        {backLink && (
          <Link
            to={backLink}
            className='flex inline-flex back-btn mr-6'>
            <HiOutlineArrowLongLeft
              size={30}
              color='white'
            />
          </Link>
        )}
        <article className='flex flex-col'>
          <div className='flex items-center'>
            <img
              alt="a vertical divider"
              src='./../assets/icons/divider-vertical.svg'
              className='h-9 mr-2'
            />
            <h1 className='text-4xl font-bold'>{title}</h1>
          </div>
          <p className='mt-1 text-md font-semibold text-gray-500'>
            {description}
          </p>
        </article>
      </div>
    </header>
  );
};

export default PageSubheader;