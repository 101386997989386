import { FaTruck } from "react-icons/fa";
import Label from "../components/ConditionLabel"
import defaultImg from "../images/defaultImg.webp";

function ProductCard({
  id,
  url,
  price,
  moving,
  condition,
  name,
  verified,
  onProductSelect,
}) {
  // Determine label text and verification status
  const labelText = condition?.toLowerCase() === "new" ? "New" : "Used";
  
  return (
    <article>
      <div className='px-4 mb-8 drop-shadow-lg'>
        <div
          data-product-id={id}
          data-product-name={name}
          className='cursor-pointer relative'
          onClick={(e) => {
            e.stopPropagation(); // Prevent the event from bubbling up to parent elements
            onProductSelect(e);
          }}>
          <div className='absolute top-[0px] right-[3%] z-10'>
            <Label text={labelText} approved={verified} />
          </div>
          <div className='flex justify-center items-center'>
            {url && (
              <img
                data-product-id={id}
                data-product-name={name}
                className='h-[150px] object-cover rounded-t-md w-full'
                src={url}
                alt={name}
                onError={(e) => {
                  e.target.src = defaultImg;
                }}
              />
            )}
          </div>
          <div className='bg-white'>
            <div
              data-product-id={id}
              data-product-name={name}
              className='text-stone-500 font-semibold truncate mb-1 text-ellipsis pl-5'>
              <div className='mt-2'></div>
              {name}
            </div>
            <div
              data-product-id={id}
              data-product-name={name}
              className='font-semibold pl-5 text-xl'>
              {price
                ?.toFixed(0)
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + " kr"}
            </div>
            <div className='text-stone-500 pl-5 flex text-sm font-semibold mt-2'>
              <FaTruck size={20} />
              {moving ? (
                <div className='pl-1 mb-2'>
                  {moving
                    ?.toFixed(0)
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + " kr"}
                </div>
              ) : (
                <p className='pl-1 text-green-600 mb-2'>Free Delivery</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}

export default ProductCard;