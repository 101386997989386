export const loadProductData = async (language, setProductData) => {
    let productData;
    if (language === "sv") {
      productData = await import("../util/ProductMockDataSwedish.json");
    } else {
      productData = await import("../util/ProductMockDataEnglish.json");
    }
    setProductData(productData.default);
  };
  
  export const validateProductForm = (product) => {
    if (
      !product ||
      !product.imageNames ||
      product.imageNames.length === 0 ||
      !product.category ||
      product.category.trim() === "" ||
      !product.rooms ||
      product.rooms.length === 0 ||
      !product.price ||
      product.price === 0 ||
      !product.condition ||
      product.condition.trim() === "" ||
      !product.dimension ||
      product.dimension.width === 0 ||
      product.dimension.height === 0 ||
      product.dimension.depth === 0 ||
      !product.model ||
      product.model.trim() === "" ||
      !product.title ||
      product.title.trim() === "" ||
      !product.description ||
      product.description.trim() === ""
    ) {
      return false;
    }
    return true;
  };  