import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getReq } from "../../configs/ApiUrl.js";
import { LoadingSpinner } from "./util/LoadingSpinner.js";
import locationMark from "../../images/locationMark.svg";
import AlwaysTop from "./util/AlwaysTop.js";
import defaultImg from "../../images/defaultImg.webp";

function UserBuy() {
  const { t } = useTranslation(["userProfile", "tables", "altText", "admin"]);
  const [loading, setLoading] = useState(false);
  const [customerOrders, setCustomerOrders] = useState([]);

  useEffect(() => {
    const getCustomerOrders = async () => {
      setLoading(true);
      try {
        const ordersResp = await getReq("/order/customer");
        if (ordersResp?.success) {
          setCustomerOrders(ordersResp.customerOrders);
        } else {
          console.error("Failed to fetch orders:", ordersResp.message);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setLoading(false);
      }
    };
    getCustomerOrders();
    AlwaysTop();
  }, []);

  const renderOrders = () => {
    if (loading) {
      return <LoadingSpinner />;
    }

    const hasSoldItems = customerOrders.some((order) =>
      order.orderItems.some((item) => item.product.status === "sold")
    );

    if (!hasSoldItems) {
      return (
        <div className="flex items-center justify-center w-full h-96">
          <h1 className="text-xl font-bold text-center text-gray-500">
            {t("admin:noHistory")}
          </h1>
        </div>
      );
    }

    return (
      <div className="flex flex-col mt-8">
        {customerOrders.map((order, orderIndex) =>
          order.orderItems
            ?.filter((item) => item.product.status === "sold")
            .map((item, itemIndex) => (
              <React.Fragment key={`${orderIndex}-${itemIndex}`}>
                <div className="order-card flex flex-col md:flex-row w-full px-4 py-6">
                  {/* Product Image */}
                  <div className="w-full md:w-1/3">
                    <div className="relative">
                      <img
                        className="w-full h-48 object-cover"
                        src={item.product?.imageNames?.[0] || defaultImg}
                        alt={t("altText:firstImage")}
                        onError={(e) => {
                          e.target.src = defaultImg;
                        }}
                      />
                      <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-1">
                        <span className="text-xs truncate">
                          {item.product?.title?.length > 15
                            ? `${item.product.title.substring(0, 15)}...`
                            : item.product?.title}
                        </span>
                      </div>
                    </div>
                  </div>

                  {/* Address */}
                  <div className="w-full md:w-1/3 mt-4 md:mt-0 md:pl-4 flex items-center">
                    <div className="flex items-center">
                      <img
                        className="w-8 h-8 mr-2"
                        src={locationMark}
                        alt={t("altText:location")}
                      />
                      <span className="text-center">
                        {`${order.shippingAddress?.street}, ${order.shippingAddress?.state}, ${order.shippingAddress?.city}.`}
                      </span>
                    </div>
                  </div>

                  {/* Price Details */}
                  <div className="w-full md:w-1/3 mt-4 md:mt-0 md:pl-4">
                    <div className="flex flex-col">
                      <div className="flex items-center justify-between mb-1">
                        <div className="text-sm">{t("admin:price")}</div>
                        <div className="text-xl font-bold">
                          {item.product?.price ?? 0} kr.
                        </div>
                      </div>
                      <div className="flex items-center justify-between mb-1">
                        <div className="text-sm">
                          {t("admin:deliveryPriceLabel")}
                        </div>
                        <div className="text-xl font-bold">
                          {item.product?.deliveryPrice ?? 0} kr.
                        </div>
                      </div>
                      {Array.isArray(item.product?.addOns) &&
                      item.product.addOns.length > 0 ? (
                        item.product.addOns.map((addon, index) => (
                          <div
                            key={index}
                            className="flex items-center justify-between mb-1"
                          >
                            <div className="text-sm">{addon.item}</div>
                            <div className="text-xl font-bold">
                              {addon.price ?? 0} kr.
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="flex items-center justify-between mb-1">
                          <div className="text-sm">{t("admin:noAddons")}</div>
                          <div className="text-xl font-bold">0 kr.</div>
                        </div>
                      )}
                      <div className="flex items-center justify-between mb-1">
                        <div className="text-sm">
                          {t("productPage:bonusDiscountLabel")}
                        </div>
                        <div className="text-xl font-bold">
                          {item.product?.bonusDiscount ?? 0} kr.
                        </div>
                      </div>
                      <div className="flex items-center justify-between mb-1">
                        <div className="text-sm">{t("admin:discount")}</div>
                        <div className="text-xl font-bold">
                          {order.orderItems?.[0]?.extraTotal ?? 0} kr.
                        </div>
                      </div>
                      <div className="flex items-center justify-between mt-2">
                        <div className="text-sm font-bold text-slate-800">
                          {t("admin:grandTotalHeader")}
                        </div>
                        <div className="text-xl font-bold text-cromboOrange">
                          {order.orderItems?.[0]?.grandTotal ?? "N/A"} kr.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {(itemIndex < order.orderItems.length - 1 ||
                  orderIndex < customerOrders.length - 1) && (
                  <hr className="border-t-2 border-cromboOrange" />
                )}
              </React.Fragment>
            ))
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center min-h-screen">
      <main className="flex flex-col justify-start bg-white w-full h-full flex-grow">
        {renderOrders()}
      </main>
    </div>
  );
}

export default UserBuy;
