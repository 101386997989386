import React, { useEffect, useState } from 'react';
import { postReq } from '../../configs/ApiUrl';
import { Navigate, useLocation } from 'react-router-dom';
import PageHeader from '../PageHeader.js';
import { useNavigate } from 'react-router-dom';
import ShoppingHome from './ShoppingHome.js';

function WelcomeEmailValidated() {
    const [message, setMessage] = useState('Validating your email...');
    const location = useLocation();
    const navigate = useNavigate();

    const handleLoginClick = () => {
        navigate('/welcome-login');
    };

    useEffect(() => {
        const validateEmail = async () => {
            const queryParams = new URLSearchParams(location.search);
            const token = queryParams.get('token');
    
            if (!token) {
                setMessage('Invalid validation link, please return to this page using a valid link');
                return;
            }
    
            try {
                // Send the token to the backend
                const response = await postReq('/user/validate-email', { token });
                
                // Check if the email has already been validated
                if (response.alreadyValidated) {
                    setMessage('This email has already been validated, please login via the homepage');
                } else {
                    // If validation is successful
                    setMessage('Email validated successfully!');
                }
            } catch (error) {
                // Handle the error and show an error message
                setMessage('Email validation failed.');
                console.error('Email validation error:', error);
            }
        };
    
        validateEmail();
    }, [location]);    

    return (
        <div className="flex flex-col items-center mt-72 bg-white">
            <PageHeader />
            <div className='flex flex-col items-center p-4'>
            <h1 className='text-2xl text-center'>{message}</h1>
            <button onClick={handleLoginClick} className='btn-main w-full mt-12'>Login</button>
            </div>  
        </div>
    );
}

export default WelcomeEmailValidated;
