import { RiVerifiedBadgeFill } from "react-icons/ri";

function ConditionLabel({ text, approved }) {
    return (
        <div className="absolute right-[-9.5px] text-cromboOrange bg-amber-100 p-1 ml-4 flex justify-center items-center rounded-bl-lg text-sm w-12 font-bold -translate-x--5 translate-y-0">
            {approved && (
                <div className="absolute top-[-8px] right-[-10px] text-green-600 text-xl">
                    <RiVerifiedBadgeFill />
                </div>
            )}
            <div>{text}</div>
        </div>
    );
}

export default ConditionLabel;