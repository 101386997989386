import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Edit } from "../../icons/Edit-Icon.svg";
import { ReactComponent as Delete } from "../../icons/Trash-Icon.svg";
import { ReactComponent as View } from "../../icons/View-Icon.svg";
import { ReactComponent as Delivery } from "../../icons/Delivery-Icon.svg";
import { ReactComponent as Toggle } from "../../icons/Toggle-Icon.svg";
import { useTranslation } from "react-i18next";
import { getReq, delReq, putReq } from "../../configs/ApiUrl";
import { FurnitureSearch } from "../../App.js";
import { LoadingSpinner } from "./util/LoadingSpinner";
import DeleteWarningModal from "./util/DeleteWarningModal";
import ApprovedLabel from "../ApprovedLabel.js";
import PreApprovedLabel from "../PreApprovedLabel.js";
import AlwaysTop from "./util/AlwaysTop.js";
import defaultImg from "../../images/defaultImg.webp";

function LiveListings() {
  const { t } = useTranslation(["admin", "subadmin", "tables", "altText"]);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useContext(FurnitureSearch);
  const [loading, setLoading] = useState(false);
  const [historyProducts, setHistoryProducts] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [loadingStates, setLoadingStates] = useState({});
  const [userRole, setUserRole] = useState("");
  const [subadminEmail, setSubadminEmail] = useState("");
  const [courier, setCourier] = useState("");
  const [userEmail, setUserEmail] = useState(""); // Track user email for filtering products

  const length = 10;

  const fetchUserProfile = async () => {
    try {
      const userProfileResp = await getReq("/user/profile");
      const userId = userProfileResp?.user?._id;
      const email = userProfileResp?.user?.email; // Get email from the profile response
      setUserEmail(email); // Set userEmail from userProfileResp

      if (!userId) {
        console.error("User ID not found in profile response");
        return;
      }

      // Fetch role based on user ID
      const roleResp = await getReq(`/user/${userId}`);
      if (roleResp?.success) {
        const { role } = roleResp;
        setUserRole(role);

        // If additional subadmin-specific details are needed, they can be set here
        if (role === "subadmin") {
          setSubadminEmail(email); // Already fetched from profile response
        }
      } else {
        console.error("Failed to fetch user role:", roleResp.message);
      }
    } catch (error) {
      console.error("Error fetching user role:", error.message);
    }
  };

  const getAllHistoryProducts = async () => {
    setLoading(true);
    const allProductsResp = await getReq("/product/all");
    if (allProductsResp?.allProducts) {
      const filteredProducts = allProductsResp.allProducts.filter((product) => {
        if (userRole === "admin") {
          return (
            product.status === "approved" || product.status === "preApproved"
          );
        } else if (userRole === "subadmin") {
          return (
            (product.status === "approved" ||
              product.status === "preApproved") &&
            product.subadminEmail === userEmail
          );
        }
        return false;
      });

      setHistoryProducts(filteredProducts);
    } else {
      setHistoryProducts([]);
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    try {
      setLoadingStates((prevState) => ({
        ...prevState,
        [id]: true,
      }));
      const updatedProductResp = await delReq(`/product/history/${id}`, {
        status: "declined",
      });
      if (updatedProductResp?.success) {
        await getAllHistoryProducts();
      }
      setLoadingStates((prevState) => ({
        ...prevState,
        [id]: false,
      }));
    } catch (error) {
      setLoadingStates((prevState) => ({
        ...prevState,
        [id]: false,
      }));
    }
  };

  useEffect(() => {
    AlwaysTop();
    fetchUserProfile();
  }, []);

  useEffect(() => {
    if (userRole) {
      getAllHistoryProducts();
    }
  }, [userRole, userEmail]);

  const handlePreApprove = async (id) => {
    try {
      setLoadingStates((prevState) => ({
        ...prevState,
        [id]: true,
      }));
      const updatedProductResp = await putReq(`/product/status`, {
        id,
        status: "preApproved",
      });
      if (updatedProductResp?.success) {
        location.reload();
      }
      setLoadingStates((prevState) => ({
        ...prevState,
        [id]: false,
      }));
    } catch (error) {
      setLoadingStates((prevState) => ({
        ...prevState,
        [id]: false,
      }));
    }
  };

  const openDeleteModal = (id) => {
    setSelectedProductId(id);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setSelectedProductId(null);
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    if (selectedProductId) {
      await handleDelete(selectedProductId);
      closeDeleteModal();
      await getAllHistoryProducts();
    }
  };

  const renderEditButton = (currentObject) => {
    const selection = currentObject._id;
    const filter = historyProducts.filter((x) => x._id === selection);
    setSearchTerm({
      ...searchTerm,
      adminSearch: filter,
    });
    if (userRole === "admin") {
      navigate("/admin-product-view");
    } else if (userRole === "subadmin") {
      navigate("/subadmin-edit-approval");
    }
  };

  const handleToggleVisibility = async (currentObject) => {
    const productId = currentObject._id;
    const newVisibility = !currentObject.isProductVisible; // Toggle visibility
    try {
      setLoadingStates((prevState) => ({
        ...prevState,
        [productId]: true,
      }));

      // Update visibility state on the backend
      const response = await putReq("/product/visibility", {
        id: productId,
        isProductVisible: newVisibility,
      });

      if (response?.success) {
        // Update the product's visibility in the local state
        setHistoryProducts((prevProducts) =>
          prevProducts.map((product) =>
            product._id === productId
              ? { ...product, isProductVisible: newVisibility }
              : product
          )
        );
      } else {
        console.error("Failed to toggle visibility");
      }

      setLoadingStates((prevState) => ({
        ...prevState,
        [productId]: false,
      }));
    } catch (error) {
      console.error("Error toggling product visibility:", error.message);
      setLoadingStates((prevState) => ({
        ...prevState,
        [productId]: false,
      }));
    }
  };

  const handleView = (currentObject) => {
    const productId = currentObject._id;
    const displayName = currentObject.title;

    setSearchTerm({
      ...searchTerm,
      searchFilter: historyProducts.filter((x) => x._id === productId),
      displayName,
      productDisplayMain: "hidden",
      productDisplayZoom: "visible",
      productArrow: "hidden",
      generalArrow: "visible",
      arrowNavigation: "/shopping-home",
      searchID: productId,
    });
    window.open(
      `/catalogue-product/${productId}`,
      "_blank",
      "rel=noopener noreferrer"
    );
  };

  return (
    <div className='flex flex-col items-center min-h-screen'>
      {showDeleteModal && (
        <DeleteWarningModal
          onConfirm={handleConfirmDelete}
          onClose={closeDeleteModal}
        />
      )}

      <div className='flex flex-col items-center px-2'>
        <main className='w-full flex flex-col justify-center mb-10 bg-white drop-shadow'>
          <div className='overflow-x-auto'>
            {loading ? (
              <LoadingSpinner />
            ) : historyProducts.length > 0 ? (
              <table className='table-auto w-full border-collapse rounded-t-xl -p-1 text-center'>
                <thead>
                  <tr>
                    <th className='px-4 py-2 bg-none border-b-4 border-cromboOrangeFade text-black font-semibold'>
                      {t("tables:image")}
                    </th>
                    <th className='px-4 border-b-4 border-cromboOrangeFade text-black font-semibold'>
                      {t("admin:actionHeader")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {historyProducts.length === 0 ? (
                    <tr>
                      <td
                        colSpan='2'
                        className='p-48 text-center font-semibold text-stone-400'>
                        {t("noHistory")}
                      </td>
                    </tr>
                  ) : (
                    historyProducts.map((currentObject) => {
                      const trimmedName =
                        currentObject.name?.length > length
                          ? `${currentObject.name.substring(0, length)}...`
                          : currentObject.name;

                      let statusLabel;
                      switch (currentObject.status) {
                        case "approved":
                          statusLabel = <ApprovedLabel />;
                          break;
                        case "preApproved":
                          statusLabel = <PreApprovedLabel />;
                          break;
                        default:
                          statusLabel = null;
                      }

                      return (
                        <tr
                          key={currentObject._id}
                          className='hover:bg-background'>
                          <td className='relative border-b-4 border-b-cromboOrangeFade'>
                            <div className='flex-col items-center'>
                              <img
                                className='w-full max-w-lg h-auto mx-auto'
                                src={
                                  currentObject.mainImage ||
                                  currentObject.imageNames[0] ||
                                  defaultImg
                                }
                                alt={t("altText:firstImage")}
                                onError={(e) => {
                                  e.target.src = defaultImg;
                                }}
                              />
                              <div className='table-overlay'>
                                <span className='text-xs truncate'>
                                  {currentObject.title.length > 10
                                    ? `${currentObject.title.substring(
                                        0,
                                        15
                                      )}...`
                                    : currentObject.title}
                                </span>
                              </div>
                              <div className='absolute top-1 right-1 w-full flex items-end justify-end'>
                                <span className='mt-2 text-xs p-1 rounded-sm'>
                                  {statusLabel}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className='px-8 py-2 bg-background border-b-4 border-b-cromboOrangeFade border-2 border-r text-slate-500 border-white text-center'>
                            {loadingStates[currentObject._id] ? (
                              <div className='animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900'></div>
                            ) : (
                              <div className='flex flex-col sm:flex-row justify-center items-center gap-4'>
                                <div
                                  className='flex items-center justify-center p-4 rounded-lg bg-white border-2 border-white text-cromboOrange hover:text-cromboOrangeFade cursor-pointer drop-shadow'
                                  onClick={() => handleView(currentObject)}>
                                  <View className='h-10 w-10' />
                                  <h4 className='ml-2 text-sm font-bold'>
                                    {t("admin:viewProduct")}
                                  </h4>
                                </div>
                                <div
                                  className='flex items-center justify-center p-4 rounded-lg bg-white border-2 border-white text-cromboOrange hover:text-cromboOrangeFade cursor-pointer drop-shadow'
                                  onClick={() =>
                                    renderEditButton(currentObject)
                                  }>
                                  <Edit className='h-10 w-10' />
                                  <h4 className='ml-2 text-sm font-bold pointer-events-none'>
                                    {t("editButton")}
                                  </h4>
                                </div>
                                <div
                                  className='flex items-center justify-center p-4 rounded-lg bg-white border-2 border-white text-cromboOrange hover:text-cromboOrangeFade cursor-pointer drop-shadow'
                                  onClick={() =>
                                    handleToggleVisibility(currentObject)
                                  }>
                                  <Toggle className='h-10 w-10' />
                                  <h4 className='ml-2 text-sm font-bold pointer-events-none'>
                                    {currentObject.isProductVisible
                                      ? t("admin:hideProduct")
                                      : t("admin:showProduct")}
                                  </h4>
                                </div>
                                <div
                                  className='flex items-center justify-center p-4 rounded-lg bg-white border-2 border-white text-red-600 hover:text-red-700 cursor-pointer drop-shadow'
                                  onClick={() =>
                                    openDeleteModal(currentObject._id)
                                  }>
                                  <Delete className='h-10 w-10' />
                                  <h4 className='ml-2 text-sm font-bold'>
                                    {t("deleteButton")}
                                  </h4>
                                </div>
                                {userRole === "admin" && (
                                  <div
                                    className='flex items-center justify-center p-2 rounded-lg bg-white border-8 border-white text-cromboOrange hover:text-cromboOrangeFade cursor-pointer drop-shadow'
                                    onClick={() =>
                                      handlePreApprove(currentObject._id)
                                    }>
                                    <Delivery className='h-10 w-10 mr-2' />
                                    <h4 className='ml-2 text-sm font-bold pointer-events-none'>
                                      {t("subadmin:deliveryNeeded")}
                                    </h4>
                                  </div>
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            ) : (
              <div className='flex items-center justify-center w-96 h-96'>
                <h1 className='text-xl font-bold text-center text-gray-500'>
                  {t("admin:noProducts")}
                </h1>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default LiveListings;
