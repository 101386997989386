import React from "react";
import { HiOutlineMail } from "react-icons/hi";
import PageHeader from "../PageHeader";

function WelcomeSignupConfirmation() {
  return (
    <div>
      <div className="flex flex-col items-center mt-48 p-4 bg-white">
      <HiOutlineMail className="text-8xl text-center mb-6" />
      <h2 className="text-2xl text-center">Thanks for registering, an email has been sent, please check the junk folder.</h2>
      </div>
    </div>
  );
}

export default WelcomeSignupConfirmation;
