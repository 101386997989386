import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdMenu } from "react-icons/md";
import LanguageSelect from "../components/pages/util/LanguageSelect";
import { ReactComponent as Buy } from "../icons/Store-Icon.svg";
import { ReactComponent as Sell } from "../icons/Coin-Icon-Outline.svg";
import { ReactComponent as Favourite } from "../icons/Heart-Icon-Outline.svg";
import { ReactComponent as ShoppingCart } from "../icons/Shopping Cart Icon.svg";
import { ReactComponent as UserProfile } from "../icons/User-Circle-Icon.svg";

export default function PageHeader({ onMenuClick, closeMenu }) {
  const { t } = useTranslation(["altText", "homepage"]);
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  return (
    <header className='fixed top-0 left-0 right-0 bg-cromboGray flex justify-between items-center px-4 py-2 shadow-md z-50 h-16'>
      <div className='flex items-center space-x-4'>
        {onMenuClick && (
          <button
            onClick={onMenuClick}
            className='text-white md:hidden'>
            <MdMenu size={24} />
          </button>
        )}
        <Link
          to='/shopping-home'
          className='flex items-center'
          onClick={closeMenu}>
          <img
            alt={t("logo")}
            src='./../assets/images/24Mobler.png'
            className='h-10 md:h-12 w-auto object-contain'
          />
        </Link>
      </div>

      <div className='flex items-center space-x-6'>
        <div className='hidden md:flex items-center justify-evenly w-full space-x-12'>
          <Link
            to='/shopping-home'
            className={`flex items-center space-x-2 hover:text-cromboOrangeFade ${
              isActive("/shopping-home")
                ? "text-cromboOrangeFade"
                : "text-white"
            }`}
            onClick={closeMenu}>
            <Buy />
            <span className='text-md font-bold'>{t("homepage:buyButton")}</span>
          </Link>
          <Link
            to='/selling-add-furniture'
            className={`flex items-center space-x-2 hover:text-cromboOrangeFade ${
              isActive("/selling-add-furniture")
                ? "text-cromboOrangeFade"
                : "text-white"
            }`}
            onClick={closeMenu}>
            <Sell />
            <span className='text-md font-bold'>
              {t("homepage:sellButton")}
            </span>
          </Link>
          <Link
            to='/catalogue-favourites'
            className={`flex items-center space-x-2 hover:text-cromboOrangeFade ${
              isActive("/catalogue-favourites")
                ? "text-cromboOrangeFade"
                : "text-white"
            }`}
            onClick={closeMenu}>
            <Favourite />
            <span className='text-md font-bold'>
              {t("homepage:favouriteButton")}
            </span>
          </Link>
          <Link
            to='/checkout'
            className={`flex items-center space-x-2 hover:text-cromboOrangeFade ${
              isActive("/checkout") ? "text-cromboOrangeFade" : "text-white"
            }`}
            onClick={closeMenu}>
            <ShoppingCart />
            <span className='text-md font-bold'>
              {t("homepage:cartButton")}
            </span>
          </Link>
          <Link
            to='/user-profile'
            className={`flex items-center space-x-2 hover:text-cromboOrangeFade ${
              isActive("/user-profile") ? "text-cromboOrangeFade" : "text-white"
            }`}
            onClick={closeMenu}>
            <UserProfile />
            <span className='text-md font-bold'>
              {t("homepage:profileButton")}
            </span>
          </Link>
        </div>

        <div className='flex items-center space-x-2 md:space-x-6'>
          <Link
            to='/user-profile'
            className='text-white md:hidden'
            onClick={closeMenu}>
            <UserProfile />
          </Link>
          <LanguageSelect />
        </div>
      </div>
    </header>
  );
}
