import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageHeader from "../PageHeader";
import { AdminContext } from "./util/AdminContext";
import { FaTruck, FaTruckLoading, FaPlusSquare, FaLongArrowAltLeft } from "react-icons/fa";
import { postReq, getReq } from "../../configs/ApiUrl.js";
import AlwaysTop from "./util/AlwaysTop";

function SubadminMenu() {
    const { t } = useTranslation(['admin', 'subadmin', 'altText', 'userProfile']);
    const navigate = useNavigate();
    const { setIsAdmin } = useContext(AdminContext);
    const [loading, setLoading] = useState(false);
    const [welcomeMessage, setWelcomeMessage] = useState('');

    const handleAdminAccess = () => {
        setIsAdmin(true);
    };

    const handleLogOut = async () => {
        try {
            setLoading(true);
            const logOutResp = await postReq('/user/logout');
            if (logOutResp?.success) {
                setLoading(false);
                localStorage.removeItem('userId');
                navigate('/welcome-login');
            }
        } catch (error) {
            console.error("Logout failed:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        AlwaysTop();

        const fetchUserData = async () => {
            try {
                const userId = localStorage.getItem("userId");
                if (!userId) throw new Error("User ID not found in localStorage");

                const response = await getReq(`/user/${userId}`);
                if (response?.success) {
                    const { companyName, username } = response;
                    setWelcomeMessage(companyName || username || '');
                } else {
                    console.error("Failed to fetch user data");
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, [t]);

    const subadminLinks = [
        {
            to: "/subadmin-product-status",
            icon: <img style={{ width: "48px", height: "48px" }} src="/assets/icons/bed-icon.svg" alt={t('altText:bed')} />,
            label: t('subadmin:productStatusButton'),
        },
        {
            to: "/subadmin-add-product",
            icon: <FaPlusSquare style={{ width: "48px", height: "48px" }} />,
            label: t('admin:addProductButton'),
        },
        {
            to: "/live-listings",
            icon: <img style={{ width: "48px", height: "48px" }} className="text-black" src="/assets/icons/shop.svg" alt={t("altText:liveListings")} />,
            label: t('admin:liveButton'),
        },
        {
            to: "/delivery-needed",
            icon: <FaTruck style={{ width: "48px", height: "48px" }} />,
            label: t('admin:deliveryNeededButton'),
        },
        {
            to: "/active-orders",
            icon: <img style={{ width: "48px", height: "48px" }} src="/assets/icons/sell-icon.svg" alt={t('altText:dollar')} />,
            label: t("admin:activeButton"),
        },
        {
            to: "/delivery-status",
            icon: <FaTruckLoading style={{ width: "48px", height: "48px" }} />,
            label: t('admin:deliveryStatusButton'),
        },
        {
            to: "/products-history",
            icon: <img style={{ width: "48px", height: "48px" }} src="/assets/icons/order-history.svg" alt={t('altText:orderHistory')} />,
            label: t('admin:historyButton'),
        },
        {
            to: "/discount-codes",
            icon: <img style={{ width: "48px", height: "48px" }} src="/assets/icons/percent.svg" alt={t('altText:percentage')} />,
            label: t('admin:discountButton'),
        },
        {
            to: "/user-profile",
            icon: <FaLongArrowAltLeft style={{ width: "48px", height: "48px" }} />,
            label: t('subadmin:mainSite'),
        },
    ];

    return (
        <div className="bg-gray-100 min-h-screen">
            <PageHeader />
            <div className="max-w-screen-xl mx-auto px-6 py-24">
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {subadminLinks.map((link, index) => (
                        <Link to={link.to} key={index} className="group">
                            <div className="bg-white shadow-lg rounded-lg p-8 flex flex-col items-center transition duration-300 hover:bg-cromboOrange hover:text-white hover:shadow-xl w-full h-full">
                                {typeof link.icon === "string" ? (
                                    <img
                                        src={link.icon}
                                        alt={link.alt}
                                        style={{ width: "48px", height: "48px" }}
                                        className="mb-4"
                                    />
                                ) : (
                                    React.cloneElement(link.icon, {
                                        className: "text-gray-700 group-hover:text-white transition duration-200",
                                    })
                                )}
                                <h3 className="text-2xl font-semibold text-gray-800 group-hover:text-white mb-2 transition duration-200">
                                    {link.label}
                                </h3>
                                <div className="h-1 w-16 bg-cromboOrange group-hover:bg-white transition duration-200"></div>
                            </div>
                        </Link>
                    ))}
                </div>

                <div className="flex justify-center mt-10">
                    <button
                        onClick={handleLogOut}
                        className={`bg-red-600 text-white py-3 px-8 rounded-lg font-semibold shadow-lg hover:bg-red-700 transition duration-200 ${
                            loading ? "opacity-50 cursor-not-allowed" : ""
                        }`}
                        disabled={loading}
                    >
                        {loading ? "Logging out..." : t('userProfile:logoutButton')}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SubadminMenu;
