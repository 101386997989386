import React, { useState, useEffect } from 'react';
import { GoHeart, GoHeartFill } from 'react-icons/go';
import { postReq } from '../../../configs/ApiUrl';

const AddFavouriteProduct = ({ userId, productId, isFavourited, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [favourited, setFavourited] = useState(isFavourited);

  useEffect(() => {
    setFavourited(isFavourited);
  }, [isFavourited]);

  const handleToggleFavourite = async () => {
    try {
      setLoading(true);
      let response;
      if (favourited) {
        response = await postReq("/user/remove-favourite", { userId, productId });
      } else {
        response = await postReq("/user/add-favourite", { userId, productId });
      }

      if (response && response.message) {
        setFavourited(!favourited);
        if (onSuccess) {
          onSuccess();
        }
      } else {
        console.error("Failed to update favourite status.");
      }
    } catch (error) {
      console.error("Error updating favourite status:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div onClick={handleToggleFavourite}>
      {loading ? (
        <GoHeartFill size={25} color="gray" className="cursor-pointer ml-3 p-0.5 rounded-md bg-white" />
      ) : favourited ? (
        <GoHeartFill size={25} color="red" className="cursor-pointer ml-3 p-0.5 rounded-md bg-white" />
      ) : (
        <GoHeart size={25} color="red" className="cursor-pointer ml-3 p-0.5 rounded-md bg-white" />
      )}
    </div>
  );
};

export default AddFavouriteProduct;