import React from 'react';
import { useTranslation } from 'react-i18next';

function DeleteWarningModal({ onConfirm, onClose }) {
  const { t } = useTranslation("utilityPages");
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 w-full p-4 z-50">
      <div className="bg-white p-6 rounded-xl max-w-md">
      <img
          className="w-24 h-24 mx-auto max-w-md h-auto"
          src="./../assets/icons/warning.svg"
          alt="warning icon"
      />
        <h1 className="text-2xl text-center font-bold">{t("utilityPages:deleteModalHeader")}</h1>
        <p className="mt-2 mb-12 text-sm text-gray-500 text-center font-bold">{t("utilityPages:deleteModalSubtext")}</p>
        <div className="flex-row justify-end items-center">
          <button
            className="w-full mb-4 btn-danger"
            onClick={onConfirm}
          >
            {t("utilityPages:deleteModalButton")}
          </button>
          <button
            className="w-full btn-secondary"
            onClick={onClose}
          >
            {t("utilityPages:deleteModalCancel")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteWarningModal;
