import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MdMenu, MdClose } from "react-icons/md";
import PageHeader from "../PageHeader";
import PageFooter from "../PageFooter";
import PageSubheader from "../PageSubheader";
import { ReactComponent as Plus } from "../../icons/Plus-Icon.svg";
import { ReactComponent as Favourite } from "../../icons/Heart-Icon-Outline.svg";
import { ReactComponent as Sell } from "../../icons/Coin-Icon-Outline.svg";
import { ReactComponent as Delivery } from "../../icons/Delivery-Icon.svg";
import { ReactComponent as Warning } from "../../icons/Warning-Icon.svg";
import { ReactComponent as Clipboard } from "../../icons/Clipboard-Icon.svg";
import { ReactComponent as History } from "../../icons/History-Icon.svg";
import { ReactComponent as Discount } from "../../icons/Discount-Icon.svg";
import { ReactComponent as Logout } from "../../icons/Logout-Icon.svg";
import { AdminContext } from "./util/AdminContext";
import { postReq } from "../../configs/ApiUrl.js";
import AdminAddProduct from "./AdminAddProduct.js";
import AdminProductTable from "./AdminProductTable.js";
import LiveListings from "./LiveListings.js";
import DeliveryNeeded from "./DeliveryNeeded.js";
import ActiveOrders from "./ActiveOrders.js";
import DeliveryStatus from "./DeliveryStatus.js";
import ProductsHistory from "./ProductsHistory.js";
import DiscountCodes from "./DiscountCodes.js";

function AdminMenu() {
  const { t } = useTranslation(["admin", "altText", "userProfile"]);
  const navigateLogout = useNavigate();
  const { setIsAdmin } = useContext(AdminContext);
  const [loading, setLoading] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [activeComponent, setActiveComponent] = useState(null);
  const [subheaderTitle, setSubheaderTitle] = useState(t("admin:menuTitle"));
  const [subheaderDescription, setSubheaderDescription] = useState("");

  const handleLogOut = async () => {
    setLoading(true);
    try {
      const logOutResp = await postReq("/user/logout");
      if (logOutResp?.success) {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("u");
        localStorage.removeItem("r");
        navigateLogout("/admin");
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const adminLinks = [
    {
      to: "/admin-add-product",
      icon: <Plus />,
      label: t("admin:addProductButton"),
      description: t("admin:addProductExplainer"),
      component: <AdminAddProduct />,
    },
    {
      to: "/admin-product-table",
      icon: <Favourite />,
      label: t("admin:approvalButton"),
      description: t("admin:approvalExplainer"),
      component: <AdminProductTable />,
    },
    {
      to: "/live-listings",
      icon: <Sell />,
      label: t("admin:liveButton"),
      description: t("admin:listingsExplainer"),
      component: <LiveListings />,
    },
    {
      to: "/delivery-needed",
      icon: <Warning />,
      label: t("admin:deliveryNeededButton"),
      description: t("admin:deliveryNeededExplainer"),
      component: <DeliveryNeeded />,
    },
    {
      to: "/active-orders",
      icon: <Clipboard />,
      label: t("admin:activeButton"),
      description: t("admin:activeOrdersExplainer"),
      component: <ActiveOrders />,
    },
    {
      to: "/delivery-status",
      icon: <Delivery />,
      label: t("admin:deliveryStatusButton"),
      description: t("admin:deliveryStatusExplainer"),
      component: <DeliveryStatus />,
    },
    {
      to: "/products-history",
      icon: <History />,
      label: t("admin:historyButton"),
      description: t("admin:historyExplainer"),
      component: <ProductsHistory />,
    },
    {
      to: "/discount-codes",
      icon: <Discount />,
      label: t("admin:discountButton"),
      description: t("admin:discountExplainer"),
      component: <DiscountCodes />,
    },
  ];

  return (
    <div className='flex flex-col md:flex-row min-h-screen h-full'>
      <PageHeader
        onMenuClick={() => setSidebarOpen(!isSidebarOpen)}
        closeMenu={() => setSidebarOpen(false)}
      />
      <aside
        className={`bg-gray-50 w-64 p-6 border-r flex flex-col md:h-screen h-full md:static fixed md:w-1/6 z-20 transform pt-24 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 md:translate-x-0`}>
        <h2 className='text-lg font-bold pb-4'>{t("admin:menuTitle")}</h2>
        <nav className='flex flex-col gap-6 flex-1'>
          {adminLinks.map((link, index) => (
            <button
              key={index}
              onClick={() => {
                setActiveComponent(() => link.component);
                setSubheaderTitle(link.label);
                setSubheaderDescription(link.description);
                setSidebarOpen(false);
              }}
              className='flex items-center font-semibold hover:text-cromboOrange text-sm'>
              {React.cloneElement(link.icon, { className: "mr-2 w-7 h-7" })}{" "}
              {link.label}
            </button>
          ))}
          <button
            onClick={handleLogOut}
            className='flex items-center text-red-600 font-semibold hover:text-red-800 mt-auto'>
            <Logout className='w-10 h-10 mr-2' />{" "}
            {t("userProfile:logoutButton")}
          </button>
        </nav>
      </aside>

      <section className='flex-1 pt-16 flex flex-col min-h-screen'>
        <PageSubheader
          title={subheaderTitle}
          description={subheaderDescription}
        />
        {activeComponent && <div>{activeComponent}</div>}
      </section>

      <div className='block md:hidden'>
        <PageFooter />
      </div>
    </div>
  );
}

export default AdminMenu;
