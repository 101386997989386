import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import { ReactComponent as Help } from "../icons/Help-Icon.svg";
import { useTranslation } from "react-i18next";

function HelpButton({ footerRef, contentRef }) {
  const { t } = useTranslation("homepage");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // On mobile, we won't fix the button at the bottom.
  // Instead, we rely on the natural document flow or place it just above the footer.
  const buttonStyle = isMobile
    ? {
        position: "absolute",
        // Adjust these values as needed depending on your layout
        bottom: "100px", // place it above the mobile footer/navigation
        right: "24px",
        width: "50px",
        height: "50px",
        zIndex: 1000,
      }
    : {
        position: "fixed",
        bottom: "36px", // On desktop, fixed at bottom
        right: "24px",
        width: "50px",
        height: "50px",
        zIndex: 1000,
      };

  return (
    <>
      <Button
        type="primary"
        shape="circle"
        icon={<Help size={28} />}
        onClick={showModal}
        style={buttonStyle}
        className="z-20 text-white help-btn-ok"
      />
      <Modal
        title="Help"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Close"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{
          className: "help-btn-ok",
        }}
      >
        <p>
          {t("homepage:contactMessage")}{" "}
          <a
            href="https://wa.me/46729208253"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:text-blue-400 hover:underline"
          >
            {t("homepage:whatsappMessage")}
          </a>
        </p>
      </Modal>
    </>
  );
}

export default HelpButton;
