import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Modal, message, Button } from "antd";
import { OrderModalKlarna } from "./OrderModelKlarna";
import { OrderContext } from "./OrderContext";
import { postReq, getReq } from "../../../configs/ApiUrl";

const KlarnaPaymentConfirmation = () => {
  const { orderData, setOrderData } = useContext(OrderContext);
  const [klarnaOrderData, setKlarnaOrderData] = useState(null); // State for Klarna order data
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const orderId = query.get("order_id");

    const storedOrderData = localStorage.getItem("orderData");
    if (storedOrderData) {
      const parsedOrderData = JSON.parse(storedOrderData);
      setOrderData(parsedOrderData);

      if (orderId) {
        confirmPayment(orderId);
      } else {
        setLoading(false);
        message.error("Order ID not found", 1);
      }
    } else {
      message.error("Order data not found", 1);
      setLoading(false);
    }
  }, [location.search]);

  const confirmPayment = async (orderId) => {
    try {
      const response = await getReq(`/klarna/order-detail/${orderId}`);
      setKlarnaOrderData(response); // Set Klarna order data for the modal
      createOrder();
    } catch (error) {
      message.error("Failed to confirm payment", 1);
      setLoading(false);
    }
  };

  const createOrder = async () => {
    setLoading(true);

    const storedOrderData = localStorage.getItem("orderData");
    if (!storedOrderData) {
      message.error("Order data not available", 1);
      setLoading(false);
      return;
    }

    const orderDataForBackend = {
      ...JSON.parse(storedOrderData),
      paymentMethod: "klarna",
    };

    if (!orderDataForBackend.customerId) {
      message.error("Customer ID is missing", 1);
      setLoading(false);
      return;
    }

    if (!orderDataForBackend.subadminEmail) {
      try {
        const productId = orderDataForBackend.orderItems[0]?.id;
        if (productId) {
          const productDetails = await fetchProductDetails(productId);
          if (productDetails) {
            orderDataForBackend.subadminEmail = productDetails.subadminEmail;
          } else {
            throw new Error("Product details not found");
          }
        } else {
          throw new Error("Product ID not found");
        }
      } catch (error) {
        message.error(
          `Failed to fetch product details for subadminEmail: ${error.message}`,
          1
        );
        setLoading(false);
        return;
      }
    }

    try {
      const orderCreateResp = await postReq(
        "/order/create",
        orderDataForBackend
      );
      if (orderCreateResp?.createdOrder?._id) {
        message.success("Order Success", 2);
        localStorage.removeItem("orderData");
      } else {
        message.error("Order Failed", 1);
      }
    } catch (error) {
      message.error("Order Failed", 1);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='flex justify-center mt-4'>
      <div className='card'>
        <div className='card-body text-center'>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <>
              <OrderModalKlarna orderData={klarnaOrderData} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default KlarnaPaymentConfirmation;
