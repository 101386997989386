import React from 'react'
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { FaPlus, FaHome } from 'react-icons/fa'

function SubadminProductSubmitted() {
    const { t } = useTranslation('utilityPages', 'altText');

  return (
    <div className="flex flex-col items-center justify-center h-screen">
        <img src='/assets/icons/checkbox.svg' alt={t("altText:checkbox")} width={320}></img>
        <h1 className='text-3xl font-bold'>{t('utilityPages:submissionHeader')}</h1>
        <p className='mt-2 text-center text-lg text-gray-500 font-semibold'>{t('utilityPages:submissionSubtext')}</p>
        <div className='w-full p-4'>
        <Link to="/subadmin-add-product">
        <button className='w-full flex items-center justify-center my-8 p-4 rounded-lg bg-white border-2 border-white text-cromboOrange font-bold hover:text-cromboOrangeFade cursor-pointer drop-shadow'>
        <FaPlus className='mr-2'/>
        {t('utilityPages:addAnotherProductButton')}</button>
        </Link>
        <Link to="/subadmin-menu">
        <button className='w-full flex items-center justify-center p-4 rounded-lg bg-white border-2 border-white text-cromboOrange font-bold hover:text-cromboOrangeFade cursor-pointer drop-shadow'>
        <FaHome className='mr-2'/>
        {t('utilityPages:returnHomeButton')}</button>
        </Link>
        </div>
    </div>
  )
}

export default SubadminProductSubmitted