import { useContext, useEffect, useState } from "react";
import ProductImages from "../../uploads/ProductImages.js";
import Select from "react-dropdown-select";
import { useNavigate, Link } from "react-router-dom";
import { postReq, getReq } from "../../configs/ApiUrl.js";
import { FurnitureSearch } from "../../App.js";
import { useTranslation } from "react-i18next";
import { AdminContext } from "./util/AdminContext.js";
import { message } from "antd";
import PageSubheader from "../PageSubheader.js";
import i18next from "i18next";
import AlwaysTop from "./util/AlwaysTop.js";
import PageFooter from "../PageFooter.js";

let valueDefault = true;
let valueManual = false;

function SellingAddFurniture() {
  const { t } = useTranslation([
    "addProductPage",
    "forms",
    "productTypes",
    "productPage",
    "altText",
    "admin",
  ]);
  const currentLanguage = i18next.language;
  const [searchTerm, setSearchTerm] = useContext(FurnitureSearch);
  const { isAdmin } = useContext(AdminContext);
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [subadminEmail, setSubadminEmail] = useState("");
  const [posterId, setPosterId] = useState("");
  const [initialAddress, setInitialAddress] = useState({
    street: "",
    postalCode: "",
    phone: "",
    state: "",
    city: "",
  });

  const [productData, setProductData] = useState({
    options: [],
    colors: [],
    condition: [],
    defaultAddress: {},
  });

  useEffect(() => {
    const loadProductData = async () => {
      let productData;
      if (currentLanguage === "sv") {
        productData = await import("./util/ProductMockDataSwedish.json");
      } else {
        productData = await import("./util/ProductMockDataEnglish.json");
      }
      setProductData(productData.default);
    };

    loadProductData();
  }, [currentLanguage]);

  const fetchUserProfile = async () => {
    try {
      const profileResp = await getReq("/user/profile");
      if (profileResp?.user) {
        setSubadminEmail(profileResp.user.email);
        setPosterId(profileResp.user._id);
        setInitialAddress({
          ...profileResp.user.address,
          phone: profileResp.user.phone || "",
        });

        // If the default box is supposed to be checked at start,
        // go ahead and set product.location right away:
        if (defaultCheckbox.checked) {
          setProduct((prev) => ({
            ...prev,
            location: {
              ...profileResp.user.address,
              phone: profileResp.user.phone || "",
            },
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching user profile:", error.message);
    }
  };

  const [product, setProduct] = useState({
    posterId: "",
    imageNames: [],
    category: "",
    rooms: [],
    price: 0,
    condition: "",
    dimension: { width: 0, height: 0, depth: 0 },
    model: "",
    location: initialAddress,
    title: "",
    description: "",
    subadminEmail: subadminEmail,
  });

  useEffect(() => {
    fetchUserProfile(); // Fetch user profile on component mount
  }, []);

  const { options, colors, condition, defaultAddress } = productData;

  const validateProductForm = () => {
    if (
      product.imageNames.length === 0 ||
      product.category.trim() === "" ||
      product.rooms.length === 0 ||
      product.price === 0 ||
      product.condition.trim() === "" ||
      product.dimension.width === 0 ||
      product.dimension.height === 0 ||
      product.dimension.depth === 0 ||
      product.model.trim() === "" ||
      product.title.trim() === "" ||
      product.description.trim() === "" ||
      product.location.street.trim() === "" ||
      product.location.postalCode.trim() === "" ||
      product.location.phone.trim() === "" ||
      product.location.state.trim() === "" ||
      product.location.city.trim() === ""
    ) {
      return false;
    }
    return true;
  };

  const [inputDisplay, setInputDisplay] = useState({
    category: "",
    rooms: "",
    price: "",
    condition: "",
    color: "",
    height: "",
    width: "",
    depth: "",
    title: "",
  });

  const [warning, setWarning] = useState("");

  const [locationDisplay, setLocationDisplay] = useState({
    location: "hidden",
    defaultAddress: "visible",
    manualAddress: "hidden",
  });

  const [defaultCheckbox, setDefaultCheckbox] = useState({
    checked: valueDefault,
  });
  const [manualCheckbox, setManualCheckbox] = useState({
    checked: valueManual,
  });

  const [validAddress, setValidAddress] = useState("");

  useEffect(() => {
    if (locationDisplay?.defaultAddress === "visible") {
      setProduct((prev) => ({
        ...prev,
        location: initialAddress,
      }));
    } else {
      setProduct((prev) => ({
        ...prev,
        location: {
          street: "",
          postalCode: "",
          phone: "",
          state: "",
          city: "",
        },
      }));
    }
  }, [locationDisplay?.defaultAddress, initialAddress]);

  useEffect(() => {
    if (posterId) {
      setProduct({ ...product, posterId });
    }
  }, [posterId]);

  const handleFileListChange = (images) => {
    setProduct({ ...product, imageNames: images });
  };

  const handleCreateProduct = async (e) => {
    e.preventDefault();
    setErrors("");
    if (!Array.isArray(product.imageNames) || product.imageNames.length === 0) {
      return setErrors("Please upload at least one image.");
    }
    const formData = new FormData();
    formData.append("posterId", product?.posterId);
    formData.append("title", product?.title);
    formData.append("price", product?.price);
    formData.append("color", product?.color);
    formData.append("category", product?.category);
    formData.append("condition", product?.condition);
    product?.rooms.forEach((room, index) => {
      formData.append(`rooms[${index}]`, room);
    });
    formData.append("model", product?.model);
    formData.append("description", product?.description);
    formData.append("dimension", JSON.stringify(product?.dimension));
    formData.append("location", JSON.stringify(product?.location));
    // While it doesn't need data, this subadminEmail needs to be passed to allow for the product to be created
    formData.append("subadminEmail", product?.subadminEmail);

    // Append image files
    product.imageNames.forEach((image) => {
      formData.append(`imageNames`, image?.originFileObj);
    });

    if (validateProductForm()) {
      setIsLoading(true);
      try {
        setSearchTerm({
          ...searchTerm,
          productFormData: formData,
        });
        navigate("/calendar", {
          state: {
            isSeller: true,
          },
        });
      } catch (error) {
        setIsLoading(false);
        setErrors("Product creation failed due to an error");
        message.error(t("uploadFailed"), 1);
      }
    } else {
      setIsLoading(false);
      setErrors("Please fill in the missing fields");
    }
  };

  useEffect(() => {
    AlwaysTop();
  }, []);

  return (
    <div className='flex flex-col items-center'>
      <div
        className='w-full'
        style={{ backgroundColor: "#F4F5F9" }}>
          <PageSubheader
            backLink='/shopping-home'
            title={t("admin:addProductButton")}
            description={t("admin:addProductExplainer")}
          />
          <main className='font-bold text-lg container mx-auto px-6 py-4'>
            <form onSubmit={handleCreateProduct}>
              <article className='mb-4'>
                <div className='relative'>
                  {/* <div className="absolute text-red-500 text-base font-semibold top-[-45px]">{validAddress}</div> */}
                  <div className='absolute text-red-500 text-base font-semibold top-[-25px]'>
                    {errors && errors}
                  </div>
                </div>
                <div className='bg-white p-4 mb-8 shadow-lg'>
                  <div className='flex'>
                    <img
                      alt={t("altText:verticalDivider")}
                      src='./../assets/icons/divider-vertical.svg'
                      className='w-8 h-7'
                    />
                    <h2 className='text-lg mb-3 font-semibold'>
                      {t("addProductPage:photosHeader")}
                    </h2>
                  </div>
                  <div className='flex flex-col items-center'>
                    <ProductImages onFileListChange={handleFileListChange} />
                  </div>
                </div>
              </article>
              <div className='flex justify-center'>
                <img
                  alt={t("altText:horizontalDivider")}
                  src='./../assets/icons/divider-horizontal.svg'
                  className='w-80'
                />
              </div>
              <article>
                <div className='bg-white p-4 mt-8 mb-8 shadow-lg'>
                  <div className='flex'>
                    <img
                      alt={t("altText:verticalDivider")}
                      src='./../assets/icons/divider-vertical.svg'
                      className='w-8 h-7'
                    />
                    <h2 className='mb-4'>
                      {t("addProductPage:detailsHeader")}
                    </h2>
                  </div>
                  <div className='text-base pl-3 mb-3 font-semibold mt-2'>
                    <h2>{t("addProductPage:namePlace")}</h2>
                    <input
                      name='title'
                      placeholder={t("addProductPage:nameLabel")}
                      className={
                        inputDisplay.title +
                        " focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] pl-2 mt-1 rounded-md w-full"
                      }
                      onChange={(e) => {
                        setProduct({
                          ...product,
                          title: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className='text-base pl-3 mb-3 mt-6 font-semibold mt-2'>
                    <h2>{t("addProductPage:priceLabel")}</h2>
                    <input
                      name='price'
                      type='number'
                      min='0'
                      className={
                        inputDisplay.price +
                        " focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] pl-2 mt-1 h-10 w-full rounded-md"
                      }
                      value={product.price}
                      onChange={(e) => {
                        setProduct({ ...product, price: e.target.value });
                      }}
                    />
                  </div>
                  <div className='text-base pl-3 mb-3 mt-6 font-semibold mt-2'>
                    <h2>{t("addProductPage:conditionLabel")}</h2>
                    <Select
                      className={inputDisplay.condition + " mt-1 mb-5"}
                      options={condition}
                      labelField='condition'
                      valueField='id'
                      keepOpen={false}
                      dropdownHandle={false}
                      searchable={false}
                      backspaceDelete={false}
                      style={{
                        outline: "none",
                        borderColor: "rgb(168 162 158)",
                        borderRadius: "0.375rem",
                        fontSize: "1rem",
                        width: "100%",
                      }}
                      // values={[condition[0]]}
                      onChange={(selectedOptions) => {
                        const selectedCondition =
                          selectedOptions.length > 0
                            ? selectedOptions[0].condition
                            : "";
                        setProduct({
                          ...product,
                          condition: selectedCondition,
                        });
                      }}
                    />
                  </div>
                  <div className='text-base pl-3 mb-3 mt-6 font-semibold mt-2'>
                    <h2>{t("addProductPage:modelLabel")}</h2>
                    <input
                      name='name'
                      type='text'
                      placeholder={t("addProductPage:modelPlace")}
                      className={
                        "focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] pl-2 mt-1 h-10 w-full rounded-md " +
                        inputDisplay.model
                      }
                      onChange={(e) => {
                        setProduct({ ...product, model: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </article>
              <div className='flex justify-center'>
                <img
                  alt={t("altText:horizontalDivider")}
                  src='./../assets/icons/divider-horizontal.svg'
                  className='w-80'
                />
              </div>
              <article>
                <div className='bg-white p-4 mt-8 mb-8 shadow-lg'>
                  <div className='flex'>
                    <img
                      alt={t("altText:verticalDivider")}
                      src='./../assets/icons/divider-vertical.svg'
                      className='w-8 h-7'
                    />
                    <h2 className='text-lg mb-3 font-semibold'>
                      {t("addProductPage:furtherDetailsHeader")}
                    </h2>
                  </div>
                  <div className='text-base pl-3 mb-3 font-semibold mt-2'>
                    <h2>{t("addProductPage:typeLabel")}</h2>
                    <Select
                      className={inputDisplay.category + " mt-1 mb-5"}
                      options={options}
                      labelField='category'
                      valueField='id'
                      keepOpen={false}
                      dropdownHandle={false}
                      searchable={false}
                      backspaceDelete={false}
                      style={{
                        borderColor: "rgb(168 162 158)",
                        borderRadius: "0.375rem",
                        fontSize: "1rem",
                        width: "100%",
                      }}
                      // values={[options[0]]}
                      onChange={(selectedOptions) => {
                        const selectedCategory =
                          selectedOptions.length > 0
                            ? selectedOptions[0].category
                            : "";
                        setProduct({ ...product, category: selectedCategory });
                      }}
                    />
                  </div>
                  <div className='text-base pl-3 mb-3 font-semibold mt-2'>
                    <h2>{t("addProductPage:colorLabel")}</h2>
                    <Select
                      className={inputDisplay.color + " mt-1 mb-5"}
                      options={colors}
                      labelField='color'
                      valueField='id'
                      keepOpen={false}
                      dropdownHandle={false}
                      searchable={false}
                      backspaceDelete={false}
                      style={{
                        borderColor: "rgb(168 162 158)",
                        borderRadius: "0.375rem",
                        fontSize: "1rem",
                        width: "100%",
                      }}
                      // values={[colors[0]]}
                      onChange={(selectedOptions) => {
                        const selectedColor =
                          selectedOptions.length > 0
                            ? selectedOptions[0].color
                            : "";
                        setProduct({ ...product, color: selectedColor });
                      }}
                    />
                  </div>
                  <div className='text-base pl-3 mb-3 font-semibold mt-2'>
                    <h2>{t("addProductPage:dimensionsLabel")}</h2>
                    <div className='flex flex-row justify-between'>
                      <div className='text-base mb-3 font-semibold mt-2 mr-2'>
                        <h2>{t("productPage:height")}</h2>
                        <input
                          name='height'
                          type='number'
                          min='0'
                          className={
                            inputDisplay.height +
                            " focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] w-[70px] pl-2 h-10 rounded-md"
                          }
                          onChange={(e) => {
                            setProduct({
                              ...product,
                              dimension: {
                                ...product.dimension,
                                height: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                      <div className='text-base mb-3 font-semibold mt-2 mr-2'>
                        <h2>{t("productPage:length")}</h2>
                        <input
                          name='width'
                          type='number'
                          min='0'
                          className={
                            inputDisplay.width +
                            " focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] w-[70px] pl-2 h-10 rounded-md"
                          }
                          onChange={(e) => {
                            setProduct({
                              ...product,
                              dimension: {
                                ...product.dimension,
                                width: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                      <div className='text-base mb-3 font-semibold mt-2 mr-2'>
                        <h2>{t("productPage:depth")}</h2>
                        <input
                          name='depth'
                          type='number'
                          min='0'
                          className={
                            inputDisplay.depth +
                            " focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] w-[70px] pl-2 h-10 rounded-md"
                          }
                          onChange={(e) => {
                            setProduct({
                              ...product,
                              dimension: {
                                ...product.dimension,
                                depth: e.target.value,
                              },
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='text-base pl-3 mb-3 mt-6 font-semibold mt-2'>
                    <h2>{t("addProductPage:roomLabel")}</h2>
                    <div
                      style={{ width: "320px" }}
                      className='flex flex-wrap '>
                      <Checkbox
                        name='balcony'
                        label={t("productTypes:balcony")}
                        inputDisplay={product}
                        setInputDisplay={setProduct}
                      />
                      <Checkbox
                        name='bathroom'
                        label={t("productTypes:bathroom")}
                        inputDisplay={product}
                        setInputDisplay={setProduct}
                      />
                      <Checkbox
                        name='bedroom'
                        label={t("productTypes:bedroom")}
                        inputDisplay={product}
                        setInputDisplay={setProduct}
                      />
                      <Checkbox
                        name='foyer'
                        label={t("productTypes:foyer")}
                        inputDisplay={product}
                        setInputDisplay={setProduct}
                      />
                      <Checkbox
                        name='kitchen'
                        label={t("productTypes:kitchen")}
                        inputDisplay={product}
                        setInputDisplay={setProduct}
                      />
                      <Checkbox
                        name='living room'
                        label={t("productTypes:livingRoom")}
                        inputDisplay={product}
                        setInputDisplay={setProduct}
                      />
                      <Checkbox
                        name='office'
                        label={t("productTypes:office")}
                        inputDisplay={product}
                        setInputDisplay={setProduct}
                      />
                    </div>
                  </div>
                  <div className='text-base pl-3 mb-3 mt-6 font-semibold'>
                    <h2>{t("addProductPage:descLabel")}</h2>
                    <textarea
                      name='description'
                      className='focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] w-full h-[150px] pl-2 pt-2 rounded-md'
                      onChange={(e) => {
                        setProduct({
                          ...product,
                          description: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </article>
              <div className='flex justify-center'>
                <img
                  alt={t("altText:horizontalDivider")}
                  src='./../assets/icons/divider-horizontal.svg'
                  className='w-80'
                />
              </div>
              <article>
                <div className='bg-white p-4 mt-8 mb-8 shadow-lg'>
                  <div className='flex'>
                    <img
                      alt={t("altText:verticalDivider")}
                      src='./../assets/icons/divider-vertical.svg'
                      className='w-8 h-7'
                    />
                    {/* Location Input with options to entry Default Address or to enter address Manually */}
                    <div className={"text-lg mb-3 font-semibold"}>
                      <h2>{t("addProductPage:locationHeader")}</h2>

                      <div
                        className={locationDisplay.location}
                        onClick={() => {
                          let location = document.getElementById("location");
                          location.className =
                            "border-stone-400 border-[1px] pl-2 h-20 w-full rounded-md bg-white";
                        }}>
                        {/* <Places /> */}
                        {/* <input name="location" className={inputDisplay.location + " border-stone-400 border-[1px] pl-2 h-10 rounded-md"}
                            onClick={() => {
                              setInputDisplay(
                                {
                                  ...inputDisplay, location: "bg-white"
                                }
                              )
                            }}></input> */}
                      </div>

                      {/* Default Address Form */}
                      <div
                        className={
                          locationDisplay.defaultAddress +
                          " text-base font-semibold mt-3"
                        }>
                        <div className='mb-2'>
                          <h2>{t("forms:streetLabel")}</h2>
                          <input
                            value={initialAddress?.street}
                            disabled
                            className='w-full border-stone-400 border-[1px] rounded-md'
                          />
                        </div>
                        <div className='mb-2 flex'>
                          <div>
                            <h2>{t("forms:postalLabel")}</h2>
                            <input
                              value={initialAddress?.postalCode}
                              disabled
                              className='w-[100px] mr-3 border-stone-400 border-[1px] rounded-md'
                            />
                          </div>
                          <div>
                            <h2>{t("forms:phoneLabel")}</h2>
                            <input
                              value={initialAddress?.phone || ""}
                              disabled
                              className='w-[135px] border-stone-400 border-[1px] rounded-md'
                            />
                          </div>
                        </div>
                        <div className='mb-2 flex'>
                          <div>
                            <h2>{t("forms:provinceLabel")}</h2>
                            <input
                              value={initialAddress?.state}
                              disabled
                              className='w-[100px] mr-3 border-stone-400 border-[1px] rounded-md'
                            />
                          </div>
                          <div>
                            <h2>{t("forms:cityLabel")}</h2>
                            <input
                              value={initialAddress?.city}
                              disabled
                              className='w-[135px] border-stone-400 border-[1px] rounded-md'
                            />
                          </div>
                        </div>
                      </div>

                      {/* Manual Entry Address Form */}
                      <div
                        className={
                          locationDisplay.manualAddress +
                          " text-base font-semibold mt-3"
                        }>
                        <div className='mb-2'>
                          <h2>{t("forms:streetLabel")}</h2>
                          <input
                            value={product?.location?.street}
                            name='street'
                            className={
                              " w-full border-stone-400 border-[1px] rounded-md"
                            }
                            onChange={(e) => {
                              setProduct({
                                ...product,
                                location: {
                                  ...product.location,
                                  street: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                        <div className='mb-2 flex'>
                          <div>
                            <h2>{t("forms:postalLabel")}</h2>
                            <input
                              value={product?.location?.postalCode}
                              name='postalCode'
                              className={
                                " w-[100px] mr-3 border-stone-400 border-[1px] rounded-md"
                              }
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  location: {
                                    ...product.location,
                                    postalCode: e.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                          <div>
                            <h2>{t("forms:phoneLabel")}</h2>
                            <input
                              value={product?.location?.phone}
                              name='phone'
                              className={
                                " w-[135px] border-stone-400 border-[1px] rounded-md"
                              }
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  location: {
                                    ...product.location,
                                    phone: e.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className='mb-2 flex'>
                          <div>
                            <h2>{t("forms:provinceLabel")}</h2>
                            <input
                              value={product?.location?.state}
                              name='state'
                              className={
                                " w-[100px] mr-3 border-stone-400 border-[1px] rounded-md"
                              }
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  location: {
                                    ...product.location,
                                    state: e.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                          <div>
                            <h2>{t("forms:cityLabel")}</h2>
                            <input
                              value={product?.location?.city}
                              name='city'
                              className={
                                " w-[135px] border-stone-400 border-[1px] rounded-md"
                              }
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  location: {
                                    ...product.location,
                                    city: e.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      {/* Address Checkboxes to select preferred options.*/}
                      <div className='text-sm mt-4 font-semibold relative'>
                        <input
                          name='addressDefault'
                          id='addressDefault'
                          className='text-cromboOrange cursor-pointer mb-1'
                          type='checkbox'
                          checked={defaultCheckbox.checked}
                          onClick={(e) => {
                            let addressDefault =
                              document.getElementById("addressDefault");
                            if (addressDefault.checked === true) {
                              setManualCheckbox({
                                ...manualCheckbox,
                                checked: (valueManual = false),
                              });
                            }
                          }}
                          onChange={(e) => {
                            let addressDefault =
                              document.getElementById("addressDefault");
                            setDefaultCheckbox({
                              ...defaultCheckbox,
                              checked: (valueDefault = !valueDefault),
                            });
                            setInputDisplay({
                              ...inputDisplay,
                              location: "bg-white",
                            });
                            if (addressDefault.checked === true) {
                              setLocationDisplay({
                                ...locationDisplay,
                                location: "hidden",
                                defaultAddress: "visible",
                                manualAddress: "hidden",
                              });
                            } else {
                              setLocationDisplay({
                                ...locationDisplay,
                                location: "visible",
                                defaultAddress: "hidden",
                                manualAddress: "hidden",
                              });
                            }
                          }}
                        />
                        <label className='ml-2 w-full'>
                          {t("defaultAddress")}
                        </label>
                      </div>
                      <div className='text-sm mt-4 font-semibold relative'>
                        <input
                          name='addressManual'
                          id='addressManual'
                          className='text-cromboOrange cursor-pointer mb-1'
                          type='checkbox'
                          checked={manualCheckbox.checked}
                          onClick={(e) => {
                            let addressManual =
                              document.getElementById("addressManual");
                            if (addressManual.checked === true) {
                              setDefaultCheckbox({
                                ...defaultCheckbox,
                                checked: (valueDefault = false),
                              });
                            }
                          }}
                          onChange={(e) => {
                            setManualCheckbox({
                              ...manualCheckbox,
                              checked: (valueManual = !valueManual),
                            });
                            let addressManual =
                              document.getElementById("addressManual");
                            setInputDisplay({
                              ...inputDisplay,
                              location: "bg-white",
                            });
                            if (addressManual.checked === true) {
                              setLocationDisplay({
                                ...locationDisplay,
                                location: "hidden",
                                defaultAddress: "hidden",
                                manualAddress: "visible",
                              });
                            } else {
                              setLocationDisplay({
                                ...locationDisplay,
                                location: "visible",
                                defaultAddress: "hidden",
                                manualAddress: "hidden",
                              });
                            }
                          }}
                        />
                        <label className='ml-2 w-full'>
                          {t("manualAddress")}
                        </label>
                      </div>
                    </div>

                    <div className='text-base mb-3 font-semibold mt-2 relative'>
                      <div className='text-red-500 text-base font-semibold'>
                        {validAddress}
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <div className=' text-red-500 text-base font-semibold '>
                {errors && errors}
              </div>
              <div className='flex justify-center p-5 '>
                <button
                  type='submit'
                  disabled={isLoading}>
                  <div
                    className={`btn-main flex items-center justify-center ${
                      isLoading ? "opacity-50 pointer-events-none" : ""
                    }`}>
                    {isLoading ? (
                      <h1 className='text-white px-10 text-lg text-center font-semibold'>
                        Loading...
                      </h1>
                    ) : (
                      <h1 className='text-white px-10 text-lg text-center font-semibold'>
                        {t("submitButton")}
                      </h1>
                    )}
                  </div>
                </button>
              </div>
            </form>
          </main>
      </div>
    </div>
  );
}

const Checkbox = ({ name, label, inputDisplay, setInputDisplay }) => {
  const handleChange = () => {
    const updatedRooms = Array.isArray(inputDisplay.rooms)
      ? [...inputDisplay.rooms]
      : []; // Ensure inputDisplay.rooms is an array
    const roomIndex = updatedRooms.indexOf(name);
    if (roomIndex !== -1) {
      updatedRooms.splice(roomIndex, 1); // Remove room if already selected
    } else {
      updatedRooms.push(name); // Add room if not selected
    }
    setInputDisplay({ ...inputDisplay, rooms: updatedRooms });
  };

  return (
    <div className='w-40'>
      <input
        type='checkbox'
        name={name}
        checked={inputDisplay.rooms && inputDisplay.rooms.includes(name)} // Ensure inputDisplay.rooms is not null or undefined before checking its contents
        className='text-cromboOrange mr-1 ml-1 cursor-pointer'
        onChange={handleChange}
      />
      <label className='text-xs'>{label}</label>
    </div>
  );
};

export default SellingAddFurniture;
