import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getReq } from "../../configs/ApiUrl";
import ProductCard from "../../screens/ProductCard";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { LoadingSpinner } from "./util/LoadingSpinner";
import PageHeader from "../PageHeader";
import PageSubheader from "../PageSubheader.js";
import PageFooter from "../PageFooter.js";
import { FurnitureSearch } from "../../App.js";
import { useTranslation } from "react-i18next";
import AddFavouriteProduct from "./util/AddFavouriteProduct.js";

const RoomSearch = () => {
  const { room } = useParams();
  const { t } = useTranslation("productTypes");
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useContext(FurnitureSearch);
  const [favourite, setFavourite] = useState({
    activeObject: null,
    favouriteArray: [],
  });

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const selectedRoom = room.charAt(0).toUpperCase() + room.slice(1);
        const response = await getReq(`product/room/${selectedRoom}`);
        if (response && response.success) {
          setProducts(response.products);

          const userId = localStorage.getItem("userId");
          if (userId) {
            const favouriteResponse = await getReq(`/user/${userId}/get-favourite`);
            if (favouriteResponse && favouriteResponse.success) {
              const favouriteIds = favouriteResponse.favouriteProducts;
              setFavourite({
                activeObject: null,
                favouriteArray: response.products.map((product) =>
                  favouriteIds.includes(product._id)
                ),
              });
            } else {
              setFavourite((prev) => ({
                ...prev,
                favouriteArray: response.products.map(() => false),
              }));
            }
          }
        } else {
          console.warn("No products found, or API returned success: false");
          setProducts([]);
        }
      } catch (err) {
        console.error("Error fetching products:", err);
        setError("Failed to fetch products. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [room]);

  const roomName = t(`productTypes:${room}`, {
    defaultValue: room.charAt(0).toUpperCase() + room.slice(1),
  });


  const handleFavourite = (i, id) => {
    const updatedFavourites = [...favourite.favouriteArray];
    updatedFavourites[i] = !updatedFavourites[i];
    setFavourite({
      ...favourite,
      activeObject: updatedFavourites[i],
      favouriteArray: updatedFavourites,
    });
  };

  const handleProductSelect = (productId) => {
    setSearchTerm({
      ...searchTerm,
      searchFilter: products.filter((x) => x._id === productId),
      productDisplayMain: "hidden",
      productDisplayZoom: "visible",
      productArrow: "hidden",
      generalArrow: "visible",
      arrowNavigation: `/room-search/${room}`, // Navigation back to the room
      searchID: productId,
    });

    navigate(`/catalogue-product/${productId}`);
  };

  if (loading)
    return (
      <div className='flex justify-center items-center h-full'>
        <LoadingSpinner />
      </div>
    );
  if (error)
    return <div className='text-red-500 text-center mt-4'>{error}</div>;

  const userId = localStorage.getItem("userId");

  return (
    <div className='flex flex-col mt-16 min-h-screen'>
      <div>
        <PageHeader />
      </div>
      <PageSubheader
        backLink='/shopping-home'
        title=''
        description=''
      />
      <div className='w-full max-w-5xl mx-auto px-4'>
        <div className='flex items-center mb-6'>
          <img
            alt={t("altText:verticalDivider")}
            src='./../assets/icons/divider-vertical.svg'
            className='w-8 h-12'
          />
          <h2 className='font-bold text-4xl'>{roomName}</h2>
        </div>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2'>
          {products.length > 0 ? (
            products.map((product, i) => (
              <div
                key={product._id}
                className='relative w-full'>
                {userId && (
                  <div className='absolute z-10 left-2 top-1'>
                    <AddFavouriteProduct
                      userId={userId}
                      productId={product._id}
                      isFavourited={favourite.favouriteArray[i]}
                      onSuccess={() => handleFavourite(i, product._id)}
                    />
                  </div>
                )}
                <ProductCard
                  key={product._id}
                  id={product._id}
                  name={product.title}
                  price={product.price}
                  url={product.mainImage || product.imageNames[0]}
                  moving={product.deliveryPrice}
                  condition={product.condition}
                  verified={product.isQualityVerified}
                  onProductSelect={() => handleProductSelect(product._id)}
                />
              </div>
            ))
          ) : (
            <div className='w-[450px] flex items-center'>
              <span className='text-gray-500 mr-2'>
                <AiOutlineInfoCircle size={20} />
              </span>
              <span className='text-stone-400 font-bold'>
                {t("homepage:noProduct")}
              </span>
            </div>
          )}
        </div>
      </div>
      <footer className='mb-5'>
        <PageFooter />
      </footer>
    </div>
  );
};

export default RoomSearch;
