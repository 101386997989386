// A label to display that a new item for sale has been "Approved" by an administrator. Used in conjunction with the AdminProductCard
// component and with the AdminProductTable page component.

function ApprovedLabel() {
    return (
        <div className="text-white bg-green-500 px-1 flex justify-center items-center rounded-lg text-xs w-20 h-5 font-bold">
            Approved
        </div>
    )
}

export default ApprovedLabel;