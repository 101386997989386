import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGlobe } from 'react-icons/fa';

const LanguageSelect = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(() => {
    // Set initial state from localStorage or default to the current i18n language
    return localStorage.getItem('i18nextLng') || i18n.language;
  });
  const [isVisible, setIsVisible] = useState(false);
  const toggleRef = useRef(null);

  useEffect(() => {
    // Set the direction of the document based on the language
    document.body.dir = i18n.dir();
  }, [i18n.language]);

  const toggleLanguage = (newLanguage) => {
    if (i18n.language !== newLanguage) {
      i18n.changeLanguage(newLanguage);
      setLanguage(newLanguage);
      localStorage.setItem('i18nextLng', newLanguage); // Save the selected language to localStorage
    }
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleClickOutside = (event) => {
    if (toggleRef.current && !toggleRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="mr-4 relative" ref={toggleRef}>
      <button
        onClick={toggleVisibility}
        className="bg-gray-400 bg-opacity-20 p-2 rounded-full ml-2 flex items-center justify-center text-white"
      >
        <FaGlobe size={20} />
      </button>
      <div
        className={`absolute top-[-6px] right-[-12px] transform transition-all duration-300 ease-out ${
          isVisible ? 'scale-x-100 opacity-100' : 'scale-x-0 opacity-0'
        } origin-right bg-gray-400 bg-opacity-20 border-2 border-gray-300 rounded-full p-1 drop-shadow flex items-center`}
        style={{ width: isVisible ? '188px' : '40px' }}
      >
        <div className="relative -right-1 flex items-center bg-gray-200 rounded-full transition-width duration-300" style={{ width: '126px' }}>
          <div
            className={`absolute top-0 left-0 w-1/2 h-full bg-cromboOrange rounded-full transform transition-transform duration-300 ${
              language === 'en' ? 'translate-x-0' : 'translate-x-full'
            }`}
          ></div>
          <button
            className={`relative w-20 h-7 z-16 text-center text-xs focus:outline-none ${
              language === 'en' ? 'text-white' : 'text-black'
            }`}
            onClick={() => toggleLanguage('en')}
          >
            English
          </button>
          <button
            className={`relative w-20 z-10 text-center text-xs focus:outline-none ${
              language === 'sv' ? 'text-white' : 'text-black'
            }`}
            onClick={() => toggleLanguage('sv')}
          >
            Swedish
          </button>
        </div>
        <button
          className="bg-gray-400 bg-opacity-20 p-2 rounded-full ml-2 flex items-center justify-center"
          onClick={toggleVisibility}
        >
          <FaGlobe className="text-white" size={20} />
        </button>
      </div>
    </div>
  );
};

export default LanguageSelect;