import React, { useEffect, useState } from "react";
import { Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

// Function to convert file to base64
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const FileUpload = ({ onFileListChange }) => {
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  // Handle preview of uploaded file
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      try {
        file.preview = await getBase64(file.originFileObj);
      } catch (error) {
        console.error("Error converting file to base64:", error);
      }
    }
    setPreviewImage(file.url || file.preview);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
    setPreviewOpen(true);
  };

  // Handle file list change
  const handleChange = ({ fileList: newFileList }) => {
    // Check for errors in the files being uploaded
    const errors = newFileList.filter((file) => file.status === "error");
    if (errors.length > 0) {
      // Display error message or handle errors as needed
      console.error("Error uploading files:", errors);
      // You can also display an error notification to the user
      // notification.error({ message: 'Error uploading files' });
    }

    setFileList(newFileList);
    onFileListChange(newFileList);
  };

  // Handle modal close
  const handleCancel = () => setPreviewOpen(false);

  useEffect(() => {
    // Reset preview and file list on component unmount
    return () => {
      setPreviewOpen(false);
      setFileList([]);
    };
  }, []);

  // Add translation for "Upload"
  const { t } = useTranslation('addProductPage');

  // Upload button component
  const uploadButton = (
    
    <button
      style={{
        background: "none",
      }}
      type="button"
      className="hover:text-cromboOrange"
      >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t('addProductPage:photosUpload')}
      </div>
    </button>
  );
  const dummyRequest = ({ file, onSuccess }) => {
    onSuccess(null, file);
  };

  return (
    <div className="mt-5">
      <Upload
        customRequest={dummyRequest}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        maxCount={5}
        multiple
      >
        {fileList.length >= 5 ? null : uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="Preview" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </div>
  );
};

export default FileUpload;
