import { useState, useContext, useEffect, useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaPlusSquare } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { HiOutlineArrowLongLeft } from "react-icons/hi2";
import Select from "react-dropdown-select";
import { FurnitureSearch } from "../../App.js";
import ProductMockData from "../pages/util/ProductMockData.json";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import { putReq } from "../../configs/ApiUrl.js";
import { message } from "antd";
import Switch from "react-switch";
import moment from "moment";
import { useDropzone } from "react-dropzone";
import { FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import AlwaysTop from "./util/AlwaysTop.js";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

// The purpose of this page is to provide a form to administrators similar to that provided by sellers when they first upload
// information regarding new products. Here the administrators may adjust the original entry before it is approved and the product
// made available for purchase. The page also includes facilities to calculate hypothetical main product and additional item
// prices and discounts as well as delivery costs. The costs for a hypothetical delivery may be summarised by clicking a
// Calculate Grand Total button. Seller addresses may also be updated using Places API or entered manually.

// Variables to hold the values from the dropdown menus for category, color and condition of the furniture.
let categorySelected;
let colorSelected;
let conditionSelected;

// Variable used in the address section to control the checkbox which determines whether or not inputs are displayed to
// enter the address manually.
let valueManual = false;

// Holds the value of the item counter for the main product when calculating running totals and discounts. The user adjusts
// this value through the use of plus and minus buttons.
let items = 1;

// The length of this variable is used in conjunction with "items", the [priceDisplay, setPriceDisplay] state variable and the
// totalDisplay function to keep a running total for the cost of ordering a particular number of the main product including
// discounts. Every time the number of "items" is increased the value of items is added to the array, lengthening it; if the number
// of items is decreased the last value of "items" is removed. This lengthens or shortens "priceArray" and determines the number
// of times a price formula should be run to calculate and to display a runnning total using the map method.
let priceArray = [1];

// A variable used to keep track of the running total for the main product (including discounts) when this is calculated using
// the totalDisplay function.
let total = 0;

// This variable is used with the totalDisplay function to restrict the number of iterations of the function when used with the
// map method in order to caluculate the running total. It also forms part of the formula for calculating prices including
// discounts.
let totalCounter = 0;

// Tracks the number of units to be included when a batch of additional items is added as on option for the order (for instance,
// four chairs might be an additional item when a customer orders a table). The number of each item available for extra options
// is adjusted by the user with plus and minus buttons.
let extraCounter = 1;

// Used in conjunction with [extraDisplay, setExtraDisplay] this variable stores extra objects which describe
// the extra items which customers may purchase in addition to the main product.
let extraArray = [];

// This array tracks the number for each particular extra when the user wishes to calculate prices and discounts. A 0 is added
// to the array for each new extra and this is increased or decreased using the plus and minus buttons on the extra display which
// is automatically generated after it has been added. It is used with the extraTotal function to calculate running totals.
let extraTotalCounter = [];

// This array is used in conjunction with extraTotalCounter and the extraTotal function to calculate running totals for extras.
// A 0 is added or removed from the array whenever a new extra is added or removed. The relevant index of the array is updated
// and reset as required whenever extraTotal is called.
let extraTotalSums = [];

// Default values for the main product discount and price to prevent errors when the [calculate, setCalculate] state variable
// is run upon loading this page component. When the user makes calculations for the main product, these values are replaced
// with those from inputs. However, values from these inputs are not acquired when the page is first loaded leading to errors,
// unless a default value is supplied, which can later be replaced using state.
let discountDefault = 0;
let priceDefault = 0;

// Default value for delivery to prevent errors when the [deliveryCost, setDeliveryCost] state variable is run upon loading this
// page component. When the user makes calculations for the grand total this value is replaced with the value from the delivery
// input using state.
let deliveryDefault = 0;

// A default value for to prevent a NaN output from the grand total calculation when the page is first loaded. This variable
// is also used in conjuction with the productTotal and grandTotal functions to transfer the total product cost to the an
// equation for the total costs including extras and delivery.
let productTotalDisplay = 0;

// This array is used to hold the total costs of extras caclulated using the extraItemTotal function and to transfer these to form
// part of the grand total calculated using the grandTotal function.
let extraTotalDisplay = [];

let bonusDiscountArray = [];

function SubadminEditApproval() {
  const { t } = useTranslation([
    "admin",
    "forms",
    "tables",
    "addProductPage",
    "productTypes",
    "productPage",
    "altText",
  ]);
  const { options, colors, condition } = ProductMockData;
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  // The useContext hook is used to access the item filtered by id from the database in the AdminProductTable.js page component
  // and copied into the adminSearch key before this page component is loaded. The [item, setItem] state variable is used
  // to display this data, where applicable, in the inputs, textareas and background images of this page.
  const [searchTerm] = useContext(FurnitureSearch);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [preApproveLoading, setPreApproveLoading] = useState(false);
  const [existDiscount, setExistDiscount] = useState(null);
  const [isQualityVerified, setIsQualityVerified] = useState(false);

  const [item, setItem] = useState({
    _id: searchTerm?.adminSearch[0]?._id,
    price: searchTerm?.adminSearch[0]?.price,
    model: searchTerm?.adminSearch[0]?.model,
    category: searchTerm?.adminSearch[0]?.category,
    condition: searchTerm?.adminSearch[0]?.condition,
    color: searchTerm?.adminSearch[0]?.color,
    location: searchTerm?.adminSearch[0]?.location,
    title: searchTerm?.adminSearch[0]?.title,
    reason: searchTerm?.adminSearch[0]?.reason,
    rooms: searchTerm?.adminSearch[0]?.rooms,
    description: searchTerm?.adminSearch[0]?.description,
    dimension: searchTerm?.adminSearch[0]?.dimension,
    pickUpSlots: searchTerm?.adminSearch[0]?.pickUpSlots,
    // address: searchTerm?.adminSearch[0]?.address,
    // searchAddress: ""
  });

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [mainImage, setMainImage] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);

  useEffect(() => {
    if (searchTerm?.adminSearch[0]?.imageNames?.length) {
      const images = searchTerm.adminSearch[0].imageNames.map(
        (imageName, index) => {
          return {
            uid: `${index}`,
            name: imageName,
            status: "done",
            url: imageName,
          };
        }
      );
      setFileList(images);
      setMainImage(searchTerm.adminSearch[0].mainImage || images[0]?.url);
      setItem(searchTerm.adminSearch[0]);
    }
  }, [searchTerm]);

  useEffect(() => {
    AlwaysTop();
  }, []);

  const handleCancel = () => setPreviewOpen(false);
  const handleSwitchChange = (val) => {
    setIsQualityVerified(val);
  };

  const handlePreview = (file) => {
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleDrop = useCallback((acceptedFiles) => {
    const newFileList = acceptedFiles.map((file) => {
      const url = URL.createObjectURL(file);
      return {
        uid: file.name,
        name: file.name,
        status: "done",
        url,
        originFileObj: file,
      };
    });

    setFileList((prevFileList) => [...prevFileList, ...newFileList]);

    return () => {
      newFileList.forEach((file) => URL.revokeObjectURL(file.url));
    };
  }, []);

  const handleRemove = (file) => {
    setFileList((prevFileList) =>
      prevFileList.filter((item) => item.uid !== file.uid)
    );
    setRemovedImages((prevRemovedImages) => [...prevRemovedImages, file.url]);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: handleDrop,
    accept: "image/*",
    maxFiles: 5,
    noClick: true,
    noKeyboard: true,
  });

  const handleMainImageChange = (url) => {
    setMainImage(url);
  };

  const uploadButton = (
    <div
      onClick={open}
      style={{ cursor: "pointer" }}>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  // State variable to change the background to red upon form submit to indicate incomplete fields.
  const [inputDisplay, setInputDisplay] = useState({
    category: "",
    rooms: "",
    price: "",
    condition: "",
    color: "",
    height: "",
    width: "",
    depth: "",
    title: "",
    reason: "",
  });

  // Text warning variable used to request the user to coomplete fields that were incomplete on submit.
  const [warning, setWarning] = useState("");

  const [itemCounter, setItemCounter] = useState(items);

  const [buttonColor, setButtonColor] = useState({
    minus: "border-cromboOrange text-cromboOrange",
    plus: "border-cromboOrange text-cromboOrange",
  });

  const [priceDisplay, setPriceDisplay] = useState(priceArray);

  const [priceHeader, setPriceHeader] = useState("visible");

  const [extraButtons, setExtraButtons] = useState({
    ...buttonColor,
    minus: "border-cromboOrange text-cromboOrange",
    plus: "border-cromboOrange text-cromboOrange",
  });

  const [extras, setExtras] = useState(extraCounter);

  const [extraDisplay, setExtraDisplay] = useState({
    activeObject: null,
    extras: extraArray,
    counters: extraArray.map(() => 1),
    totalCounters: extraArray.map(() => 0),
    totalSums: extraArray.map(() => 0),
  });
  const [bonusDiscount, setBonusDiscount] = useState(
    extraArray.map(() => false)
  );
  const [itemArrays, setItemArrays] = useState({
    activeObject: null,
    arrays: [],
  });

  const [additionalItems, setAdditionalItems] = useState("text-cromboOrange");

  const [additionalHeaders, setAdditionalHeaders] = useState({
    activeObject: null,
    headers: [],
  });

  const [calculations, setCalculations] = useState({
    productPercentage: discountDefault,
    productPrice: priceDefault,
  });

  const [deliveryCost, setDeliveryCost] = useState(deliveryDefault);
  const [costDelivery, setCostDelivery] = useState(null);

  const [grandTotalDisplay, setGrandTotalDisplay] = useState("hidden");

  const [calculate, setCalculate] = useState({
    background: "bg-cromboOrange",
    text: "text-white",
  });

  // const [bonusDiscount, setBonusDiscount] = useState(bonusDiscountArray);
  const [discountBonus, setDiscountBonus] = useState(null);

  const [discountVisible, setDiscountVisible] = useState("hidden");

  const [discountNumber, setDiscountNumber] = useState(0);

  function categoryValue() {
    let categoryNumber = 0;
    switch (searchTerm?.adminSearch[0]?.category) {
      case "appliances":
        categoryNumber = 0;
        break;
      case "bed":
        categoryNumber = 1;
        break;
      case "chair":
        categoryNumber = 2;
        break;
      case "dresser":
        categoryNumber = 3;
        break;
      case "little things":
        categoryNumber = 4;
        break;
      case "sofa":
        categoryNumber = 5;
        break;
      case "table":
        categoryNumber = 6;
        break;
      case "wardrobe":
        categoryNumber = 7;
        break;
      default:
        categoryNumber = 0;
    }
    return categoryNumber;
  }

  function colorValue() {
    const color = searchTerm?.adminSearch[0]?.color;
    let colorNumber;
    switch (color) {
      case "Black":
        colorNumber = 0;
        break;
      case "Blue":
        colorNumber = 1;
        break;
      case "Brown":
        colorNumber = 2;
        break;
      case "Gray":
        colorNumber = 3;
        break;
      case "Green":
        colorNumber = 4;
        break;
      case "Orange":
        colorNumber = 5;
        break;
      case "Pink":
        colorNumber = 6;
        break;
      case "Purple":
        colorNumber = 7;
        break;
      case "Red":
        colorNumber = 8;
        break;
      case "White":
        colorNumber = 9;
        break;
      case "Yellow":
        colorNumber = 10;
        break;
      default:
        colorNumber = 0;
    }
    return colorNumber;
  }

  function conditionValue() {
    let conditionNumber = 0;
    switch (searchTerm?.adminSearch[0]?.condition) {
      case "New":
        conditionNumber = 0;
        break;
      case "Good as New":
        conditionNumber = 1;
        break;
      case "Good":
        conditionNumber = 2;
        break;
      case "Fair":
        conditionNumber = 3;
        break;
      case "Poor":
        conditionNumber = 4;
        break;
      default:
        conditionNumber = 0;
    }
    return conditionNumber;
  }

  function totalDisplay() {
    if (totalCounter === priceDisplay.length) {
      totalCounter = 0;
      total = 0;
    }
    totalCounter = totalCounter + 1;

    let priceEquation = Math.round(
      document.getElementById("price")?.value *
        Math.pow(
          1 - document.getElementById("discount")?.value / 100,
          totalCounter - 1
        )
    );

    total = total + priceEquation;

    return total;
  }

  const extraTotal = useCallback(
    (i) => {
      let iteration = extraTotalCounter[i] + 1;
      let priceEquation = Math.round(
        extraDisplay.extras[i].price *
          Math.pow(1 - extraDisplay.extras[i].discount / 100, iteration - 1)
      );
      let total = extraTotalSums[i] + priceEquation;

      if (iteration === extraDisplay.counters[i]) {
        extraTotalCounter[i] = 0;
        extraTotalSums[i] = 0;
      } else {
        extraTotalCounter[i] = iteration;
        extraTotalSums[i] = total;
      }

      return total;
    },
    [extraDisplay, extraTotalCounter, extraTotalSums]
  );

  const memoizedExtraTotal = useMemo(() => {
    return extraDisplay?.extras?.map((_, i) => extraTotal(i));
  }, [extraDisplay, extraTotal]);

  function productDiscountAverage() {
    let discount = Number(calculations.productPercentage);
    let percentageCounter = 0;
    let discountApplied = 1 - discount / 100;

    if (itemCounter === 0) {
      return 0;
    }

    for (let i = 0; i < itemCounter; i++) {
      let discountPercentage = Math.round(Math.pow(discountApplied, i) * 100);
      percentageCounter = percentageCounter + discountPercentage;
    }

    return <div>{Math.round(percentageCounter / itemCounter)}</div>;
  }

  function extraDiscountAverage(i) {
    if (extraDisplay.extras === "") {
      return 0;
    }

    if (extraDisplay.counters[i] === 0) {
      return 0;
    }

    let discount = extraDisplay.extras[i].discount;
    let percentageCounter = 0;
    let discountApplied = 1 - discount / 100;

    for (let j = 0; j < extraDisplay.counters[i]; j++) {
      let discountPercentage = Math.pow(discountApplied, j) * 100;
      percentageCounter = percentageCounter + discountPercentage;
    }

    return Math.round(percentageCounter / extraDisplay.counters[i]);
  }

  function extraPriceAverage(i) {
    if (extraDisplay.extras === "") {
      return 0;
    }

    if (extraDisplay.counters[i] === 0) {
      return 0;
    }

    let discount = extraDisplay.extras[i].discount;
    let priceCounter = 0;
    let discountApplied = 1 - discount / 100;
    let fullPrice = extraDisplay.extras[i].price;

    for (let j = 0; j < extraDisplay.counters[i]; j++) {
      let discountPrice = Math.pow(discountApplied, j) * fullPrice;
      priceCounter = priceCounter + discountPrice;
    }

    return Math.round(priceCounter / extraDisplay.counters[i]);
  }

  function productPriceAverage() {
    let discount = Number(calculations.productPercentage);
    let priceCounter = 0;
    let discountApplied = 1 - discount / 100;
    let fullPrice = calculations.productPrice;

    if (itemCounter === 0) {
      return 0;
    }

    for (let i = 0; i < itemCounter; i++) {
      let discountPrice = Math.round(Math.pow(discountApplied, i) * fullPrice);
      priceCounter = priceCounter + discountPrice;
    }

    return Math.round(priceCounter / itemCounter);
  }

  function productTotal() {
    let discount = calculations.productPercentage;
    let priceCounter = 0;
    let discountApplied = 1 - discount / 100;
    let fullPrice = calculations.productPrice;

    if (discount === 0) {
      return 0;
    }

    for (let i = 0; i < itemCounter; i++) {
      let discountPrice = Math.round(Math.pow(discountApplied, i) * fullPrice);
      priceCounter = priceCounter + discountPrice;
    }

    productTotalDisplay = Math.round(priceCounter);

    return Math.round(priceCounter);
  }

  function extraItemTotal(i) {
    if (extraDisplay.extras === "") {
      return 0;
    }

    if (extraDisplay.counters[i] === 0) {
      return 0;
    }

    let discount = extraDisplay.extras[i].discount;
    let priceCounter = 0;
    let discountApplied = 1 - discount / 100;
    let fullPrice = extraDisplay.extras[i].price;

    for (let j = 0; j < extraDisplay.counters[i]; j++) {
      let discountPrice = Math.pow(discountApplied, j) * fullPrice;
      priceCounter += discountPrice;
    }

    // Ensure the total for this extra is added to the correct index in the extraTotalDisplay array
    extraTotalDisplay[i] = Math.round(priceCounter);
    return Math.round(priceCounter);
  }

  function delivery() {
    if (deliveryCost === 0) {
      return 0;
    }

    return deliveryCost;
  }
  function productDiscount() {
    if (productTotalDisplay === 0) {
      return 0;
    }
    return existDiscount;
  }
  function bonusDiscountFunc() {
    if (productTotalDisplay === 0) {
      return 0;
    }
    if (discountBonus) {
      return discountBonus;
    } else {
      return 0;
    }
  }

  function totalDiscount() {
    if (productTotalDisplay === 0) {
      return 0;
    }
    return parseInt(discountDisplay()) + parseInt(bonusDiscountFunc());
  }

  function grandTotal() {
    if (productTotalDisplay === 0) {
      return 0;
    }

    let extraTotal = extraTotalDisplay.reduce((sum, value) => sum + value, 0);

    // Ensure these values are correctly set before calculation
    let discount = existDiscount ? existDiscount : 0;
    let bonusDiscountValue = discountBonus ? discountBonus : 0;

    let total =
      extraTotal +
      productTotalDisplay +
      Number(deliveryCost) -
      discount -
      bonusDiscountValue;

    return total;
  }

  // function bonusCheck(i){
  //   return i === false;
  // }

  function discountDisplay() {
    // let bonusDiscountValue = discountNumber;
    // if (bonusDiscountValue !== "" && bonusDiscountValue !== 0) {
    //   return bonusDiscountValue;
    // } else {
    //   return 0;
    // }
    return itemCounter * searchTerm?.adminSearch[0]?.price - productTotal();
  }

  const handleDeclineReason = async () => {
    try {
      setDeclineLoading(true);
      const { _id: id } = item;
      if (id) {
        const formData = new FormData();
        formData.append("status", "declined");
        formData.append("reason", item?.reason || "");

        const existingImages = [];
        fileList?.forEach((image, index) => {
          if (!image?.originFileObj) {
            existingImages.push(image?.name);
          }
        });
        if (existingImages?.length > 0) {
          existingImages.forEach((image, index) => {
            formData.append(`existingImages[${index}]`, image);
          });
        }

        const updateResp = await putReq(`/product/update/${id}`, formData);
        if (updateResp?.success) {
          messageApi.open({
            type: "success",
            content: updateResp?.message,
          });
          setDeclineLoading(false);
          navigate("/subadmin-live-listings");
        } else {
          messageApi.open({
            type: "error",
            content: updateResp?.message,
          });
          setDeclineLoading(false);
        }
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      setDeclineLoading(false);
    }
  };

  const handleApproved = async (status) => {
    try {
      if (status === "approved") {
        setApproveLoading(true);
      } else {
        setPreApproveLoading(true);
      }

      const formData = new FormData();
      formData.append(
        "status",
        status === "approved" ? "approved" : "preApproved"
      );
      formData.append("title", item?.title);
      formData.append("price", item?.price);
      formData.append("quantity", itemCounter);
      formData.append("discount", existDiscount);
      formData.append("bonusDiscount", discountBonus);
      formData.append("deliveryPrice", costDelivery);
      formData.append("color", item?.color);
      formData.append("category", item?.category);
      formData.append("condition", item?.condition);
      formData.append("isQualityVerified", isQualityVerified);
      formData.append("model", item?.model);
      formData.append("description", item?.description);
      formData.append("reason", item?.reason || "");
      formData.append("dimension", JSON.stringify(item?.dimension));
      formData.append("location", JSON.stringify(item?.location));
      formData.append("removeImages", removedImages.join(","));

      const mainImageIndex = fileList.findIndex(
        (file) => file.url === mainImage
      );
      formData.append("mainImageIndex", mainImageIndex);

      item?.rooms?.forEach((room, index) => {
        formData.append(`rooms[${index}]`, room);
      });
      formData.append("addOns", JSON.stringify(extraDisplay?.extras));

      const existingImages = [];
      fileList?.forEach((image, index) => {
        if (image?.originFileObj) {
          formData.append("imageNames", image?.originFileObj);
        } else {
          existingImages.push(image?.name);
        }
      });

      if (existingImages?.length > 0) {
        existingImages.forEach((image, index) => {
          formData.append(`existingImages[${index}]`, image);
        });
      }

      const productUpdateResp = await putReq(
        `product/update/${item?._id}`,
        formData
      );

      if (productUpdateResp?.success) {
        setApproveLoading(false);
        setPreApproveLoading(false);
        navigate("/subadmin-menu");
      } else {
        setApproveLoading(false);
        setPreApproveLoading(false);
      }
    } catch (error) {
      console.error("Error updating product:", error);
      setApproveLoading(false);
      setPreApproveLoading(false);
    }
  };

  const removeSlot = (indexToRemove) => {
    const newTimeSlots = item?.pickUpSlots?.filter(
      (_, index) => index !== indexToRemove
    );
    setItem({
      ...item,
      pickUpSlots: newTimeSlots,
    });
  };
  const dummyRequest = ({ file, onSuccess }) => {
    onSuccess(null, file);
  };
  return (
    <>
      {contextHolder}
      <div className='flex flex-col items-center'>
        <div
          className='w-full'
          style={{ backgroundColor: "#F4F5F9" }}>
          <header className='flex items-center justify-between bg-white w-full drop-shadow mb-10'>
            <Link
              to='/subadmin-menu'
              className='flex inline-flex back-btn mx-4'>
              <HiOutlineArrowLongLeft
                size={30}
                color='white'
              />
            </Link>
            <article className='flex items-center justify-between w-full'>
              <h1 className='text-3xl font-bold flex-grow text-center mr-12 my-6'>
                {t("admin:formTitle")}
              </h1>
            </article>
          </header>
          <main className='p-6 font-bold text-lg mt-8'>
            <div className='flex-col justify-center items-center'>
              <form>
                <article className='mb-4'>
                  <div className='bg-white p-4 mb-8 shadow-lg'>
                    <div className='flex'>
                      <img
                        alt={t("altText:verticalDivider")}
                        src='./../assets/icons/divider-vertical.svg'
                        className='w-2 h-7'
                      />
                      <h2 className='text-lg ml-2 mb-3 font-semibold'>
                        {t("addProductPage:photosHeader")}
                      </h2>
                    </div>
                    <div className='flex flex-wrap justify-center'>
                      <div
                        {...getRootProps({ className: "dropzone" })}
                        className='border-2 border-dashed border-gray-300 hover:border-cromboOrange hover:text-cromboOrange p-5 text-center w-48 h-48 flex items-center justify-center m-2'>
                        <input {...getInputProps()} />
                        {uploadButton}
                      </div>
                      {fileList.map((file) => (
                        <div
                          key={file.uid}
                          className='relative m-2'>
                          <img
                            src={file.url}
                            alt={file.name}
                            className='w-48 h-48 object-cover cursor-pointer'
                            onClick={() => handlePreview(file)}
                          />
                          <button
                            onClick={() => handleRemove(file)}
                            className='absolute top-2 right-2 bg-white p-1 rounded-md shadow-md'>
                            <FaTimes className='text-red-500' />
                          </button>
                          <div className='absolute bottom-0 w-full bg-black/50 px-2 py-1 text-white shadow-md'>
                            <input
                              type='checkbox'
                              checked={mainImage === file.url}
                              onChange={() => handleMainImageChange(file.url)}
                              className='text-cromboOrange'
                            />
                            <label className='ml-1 text-sm'>
                              {t("admin:mainImage")}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                    <Modal
                      visible={previewOpen}
                      title={previewTitle}
                      footer={null}
                      onCancel={handleCancel}>
                      <img
                        className='w-full'
                        src={previewImage}
                      />
                    </Modal>
                  </div>
                </article>

                <div className='flex justify-center'>
                  <img
                    alt={t("altText:horizontalDivider")}
                    src='./../assets/icons/divider-horizontal.svg'
                    className='w-80'
                  />
                </div>

                <div className='bg-white p-4 my-8 shadow-lg'>
                  <div className='flex'>
                    <img
                      alt={t("altText:verticalDivider")}
                      src='./../assets/icons/divider-vertical.svg'
                      className='w-2 h-7'
                    />
                    <h2 className='text-lg ml-2 mb-3 font-semibold'>
                      {t("addProductPage:detailsHeader")}
                    </h2>
                  </div>
                  <h2 className='text-base font-semibold mt-2'>
                    {t("addProductPage:nameLabel")}
                  </h2>
                  <input
                    name='title'
                    value={item.title}
                    className={
                      inputDisplay.title +
                      " focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] pl-2 mt-1 h-10 w-full rounded-md"
                    }
                    onClick={() => {
                      setInputDisplay({
                        ...inputDisplay,
                        title: "bg-white",
                      });
                    }}
                    onChange={(e) => {
                      setItem({
                        ...item,
                        title: e.target.value,
                      });
                    }}></input>

                  <div className='text-base mb-3 font-semibold mt-4'>
                    <h2>{t("addProductPage:conditionLabel")}</h2>
                    <Select
                      className={inputDisplay.condition + " mb-5"}
                      options={condition}
                      labelField='condition'
                      valueField='id'
                      keepOpen={false}
                      dropdownHandle={false}
                      searchable={false}
                      backspaceDelete={false}
                      style={{
                        outline: "none",
                        borderColor: "rgb(168 162 158)",
                        borderRadius: "0.375rem",
                        fontSize: "1rem",
                        width: "100%",
                      }}
                      values={[condition[conditionValue()]]}
                      onChange={(e) => {
                        conditionSelected = e[0].condition;
                        setItem({
                          ...item,
                          condition: e[0].condition,
                        });
                        setInputDisplay({
                          ...inputDisplay,
                          condition: "bg-white",
                        });
                      }}
                    />

                    <h2 className='mb-2'>{t("tables:category")}</h2>
                    <div className='mb-4'>
                      <Select
                        className={inputDisplay.category}
                        options={options}
                        labelField='category'
                        valueField='id'
                        keepOpen={false}
                        dropdownHandle={false}
                        searchable={false}
                        backspaceDelete={false}
                        style={{
                          outline: "none",
                          borderColor: "rgb(168 162 158)",
                          borderRadius: "0.375rem",
                          fontSize: "1rem",
                          width: "100%",
                        }}
                        values={[options[categoryValue()]]}
                        onChange={(e) => {
                          categorySelected = e[0].category;
                          setInputDisplay({
                            ...inputDisplay,
                            category: "bg-white",
                          });
                        }}
                      />
                    </div>

                    <div className='text-base mb-3 font-semibold mt-2'>
                      <h2>{t("addProductPage:modelLabel")}</h2>
                      <input
                        name='model'
                        value={item.model}
                        className='focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] pl-2 mt-1 h-10 w-full rounded-md'
                        onChange={(e) => {
                          setItem({
                            ...item,
                            model: e.target.value,
                          });
                        }}></input>
                    </div>
                  </div>
                </div>

                <div className='flex justify-center'>
                  <img
                    alt={t("altText:horizontalDivider")}
                    src='./../assets/icons/divider-horizontal.svg'
                    className='w-80'
                  />
                </div>

                <div className='bg-white p-4 my-8 shadow-lg'>
                  <div className='flex'>
                    <img
                      alt={t("altText:verticalDivider")}
                      src='./../assets/icons/divider-vertical.svg'
                      className='w-2 h-7'
                    />
                    <h2 className='text-lg ml-2 mb-3 font-semibold'>
                      {t("addProductPage:furtherDetailsHeader")}
                    </h2>
                  </div>
                  <div>{t("addProductPage:colorLabel")}</div>
                  <Select
                    className={inputDisplay.color}
                    options={colors}
                    labelField='color'
                    valueField='id'
                    keepOpen={false}
                    dropdownHandle={false}
                    searchable={false}
                    backspaceDelete={false}
                    style={{
                      outline: "none",
                      borderColor: "rgb(168 162 158)",
                      borderRadius: "0.375rem",
                      fontSize: "1rem",
                      width: "100%",
                    }}
                    values={[colors[colorValue()]]}
                    onChange={(e) => {
                      colorSelected = e[0].color;
                      setItem({
                        ...item,
                        color: e[0].color,
                      });
                      setInputDisplay({
                        ...inputDisplay,
                        color: "bg-white",
                      });
                    }}
                  />

                  <div className='text-base font-semibold mt-6'>
                    <h2>{t("addProductPage:dimensionsLabel")}</h2>
                  </div>
                  <div className='flex'>
                    <div className='text-base mb-3 font-semibold mt-2 mr-6'>
                      <h2>{t("productPage:height")}</h2>
                      <input
                        name='height'
                        type='number'
                        min='0'
                        value={item?.dimension?.height}
                        className={
                          inputDisplay.height +
                          " focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] w-[70px] pl-2 h-10 rounded-md"
                        }
                        onClick={() => {
                          setInputDisplay({
                            ...inputDisplay,
                            height: "bg-white",
                          });
                        }}
                        onChange={(e) => {
                          setItem({
                            ...item,
                            dimension: {
                              ...item.dimension,
                              height: Number(e.target.value),
                            },
                          });
                        }}></input>
                    </div>
                    <div className='text-base mb-3 font-semibold mt-2 mr-6'>
                      <h2>{t("productPage:length")}</h2>
                      <input
                        name='width'
                        type='number'
                        min='0'
                        value={item?.dimension?.width}
                        className={
                          inputDisplay.width +
                          " focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] w-[70px] pl-2 h-10 rounded-md"
                        }
                        onClick={() => {
                          setInputDisplay({
                            ...inputDisplay,
                            width: "bg-white",
                          });
                        }}
                        onChange={(e) => {
                          setItem({
                            ...item,
                            dimension: {
                              ...item.dimension,
                              width: Number(e.target.value),
                            },
                          });
                        }}></input>
                    </div>
                    <div className='text-base mb-3 font-semibold mt-2 mr-6'>
                      <h2>{t("productPage:depth")}</h2>
                      <input
                        name='depth'
                        type='number'
                        min='0'
                        value={item?.dimension?.depth}
                        className={
                          inputDisplay.depth +
                          " focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] w-[70px] pl-2 h-10 rounded-md"
                        }
                        onClick={() => {
                          setInputDisplay({
                            ...inputDisplay,
                            depth: "bg-white",
                          });
                        }}
                        onChange={(e) => {
                          setItem({
                            ...item,
                            dimension: {
                              ...item.dimension,
                              depth: Number(e.target.value),
                            },
                          });
                        }}></input>
                    </div>
                  </div>

                  <div className='text-base mb-3 font-semibold mt-2'>
                    <h2>{t("addProductPage:detailsHeader")}</h2>
                    <textarea
                      name={t("addProductPage:descLabel")}
                      value={item.description}
                      className='focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] w-full h-[150px] pl-2 pt-2 rounded-md'
                      onChange={(e) => {
                        setItem({
                          ...item,
                          description: e.target.value,
                        });
                      }}></textarea>
                  </div>

                  <article>
                    <div className='text-base mb-3 mt-6 font-semibold mt-2'>
                      <h2 className='mb-2'>{t("addProductPage:roomLabel")}</h2>
                      <div className='w-250 flex flex-wrap '>
                        <Checkbox
                          name='balcony'
                          label={t("productTypes:balcony")}
                          inputDisplay={item}
                          setInputDisplay={setItem}
                        />
                        <Checkbox
                          name='bathroom'
                          label={t("productTypes:bathroom")}
                          inputDisplay={item}
                          setInputDisplay={setItem}
                        />{" "}
                        <Checkbox
                          name='bedroom'
                          label={t("productTypes:bedroom")}
                          inputDisplay={item}
                          setInputDisplay={setItem}
                        />
                        <Checkbox
                          name='foyer'
                          label={t("productTypes:foyer")}
                          inputDisplay={item}
                          setInputDisplay={setItem}
                        />{" "}
                        <Checkbox
                          name='kitchen'
                          label={t("productTypes:kitchen")}
                          inputDisplay={item}
                          setInputDisplay={setItem}
                        />{" "}
                        <Checkbox
                          name='living room'
                          label={t("productTypes:livingRoom")}
                          inputDisplay={item}
                          setInputDisplay={setItem}
                        />{" "}
                        <Checkbox
                          name='office'
                          label={t("productTypes:office")}
                          inputDisplay={item}
                          setInputDisplay={setItem}
                        />
                      </div>
                      <div className='right-3 cursor-pointer'></div>
                    </div>
                  </article>
                </div>

                <div className='flex justify-center'>
                  <img
                    alt={t("altText:horizontalDivider")}
                    src='./../assets/icons/divider-horizontal.svg'
                    className='w-80'
                  />
                </div>

                <div className='bg-white p-4 my-8 shadow-lg'>
                  <div className='flex'>
                    <img
                      alt={t("altText:verticalDivider")}
                      src='./../assets/icons/divider-vertical.svg'
                      className='w-2 h-7'
                    />
                    <h2 className='text-lg ml-2 mb-3 font-semibold'>
                      {t("admin:grandTotalHeader")}
                    </h2>
                  </div>
                  <div className='text-base mb-3 font-semibold mt-2'>
                    <div className='flex'>
                      <h2>{t("admin:price")}</h2>
                      <h2 className='ml-[147px]'>{t("admin:discount")}</h2>
                    </div>
                    <div className='flex'>
                      <input
                        name='price'
                        id='price'
                        type='number'
                        min='0'
                        value={item.price}
                        className={
                          inputDisplay.price +
                          " focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] pl-2 w-20 h-10 rounded-md"
                        }
                        onClick={(e) => {
                          setInputDisplay({
                            ...inputDisplay,
                            price: "bg-white",
                          });
                        }}
                        onChange={(e) => {
                          let buttonMinus = "border-stone-400 text-stone-400";
                          let buttonPlus = "border-stone-400 text-stone-400";
                          priceArray = [];
                          items = 0;

                          setPriceHeader("hidden");

                          setItem({
                            ...item,
                            price: e.target.value,
                          });
                          if (
                            e.target.value !== "" &&
                            document.getElementById("discount").value !== ""
                          ) {
                            buttonPlus =
                              "border-cromboOrange text-cromboOrange";
                          }
                          if (e.target.value === "") {
                            buttonPlus = "border-stone-400 text-stone-400";
                          }

                          setButtonColor({
                            ...buttonColor,
                            minus: buttonMinus,
                            plus: buttonPlus,
                          });
                          setItemCounter(items);
                          setPriceDisplay(priceArray);
                          setCalculate({
                            ...calculate,
                            background: "bg-stone-300",
                            text: "text-stone-400",
                          });
                        }}></input>
                      <div className='relative'>
                        <div className='absolute top-[8px] right-[8px]'>
                          kr.
                        </div>
                      </div>

                      <div className='relative'>
                        <div
                          className='absolute top-2 left-[2px]'
                          onClick={() => {
                            let colorMinus =
                              "border-cromboOrange text-cromboOrange";
                            if (
                              items === 0 ||
                              document.getElementById("price").value === "" ||
                              document.getElementById("discount").value === ""
                            ) {
                              return;
                            }
                            total = 0;
                            totalCounter = 0;
                            items = items - 1;
                            priceArray.pop();
                            if (items === 0) {
                              colorMinus = "border-stone-400 text-stone-400";
                              setPriceHeader("hidden");
                              setCalculate({
                                ...calculate,
                                background: "bg-stone-300",
                                text: "text-stone-400",
                              });
                              setGrandTotalDisplay("hidden");
                            }
                            setItemCounter(items);
                            setButtonColor({
                              ...buttonColor,
                              minus: colorMinus,
                              plus: "border-cromboOrange text-cromboOrange",
                            });
                            setPriceDisplay(priceArray);
                          }}>
                          <div
                            className={
                              buttonColor.minus +
                              " border-[1px] rounded-md w-5 h-5 flex items-center justify-center ml-3 cursor-pointer"
                            }>
                            <div className='text-2xl pb-1'>-</div>
                          </div>
                        </div>
                        <div className='absolute top-2 left-[45px] text-sm'>
                          {itemCounter}
                        </div>
                        <div
                          className='absolute top-2 left-[52px]'
                          onClick={() => {
                            let colorPlus =
                              "border-cromboOrange text-cromboOrange";
                            let discount =
                              document.getElementById("discount").value;
                            let price = document.getElementById("price").value;
                            let deliveryInput =
                              document.getElementById("delivery").value;

                            if (
                              document.getElementById("price").value === "" ||
                              document.getElementById("discount").value === ""
                            ) {
                              return;
                            }
                            total = 0;
                            totalCounter = 0;
                            items = items + 1;

                            setPriceHeader("visible");
                            setItemCounter(items);
                            setButtonColor({
                              ...buttonColor,
                              minus: "border-cromboOrange text-cromboOrange",
                              plus: colorPlus,
                            });
                            priceArray.push(items);

                            setPriceDisplay(priceArray);
                            if (
                              discount !== "" &&
                              items !== 0 &&
                              price !== "" &&
                              deliveryInput !== ""
                            ) {
                              setCalculate({
                                ...calculate,
                                background: "bg-cromboOrange",
                                text: "text-white",
                              });
                            }
                          }}>
                          <div
                            className={
                              buttonColor.plus +
                              " border-[1px] rounded-md w-5 h-5 flex items-center justify-center ml-3 cursor-pointer"
                            }>
                            <div className='text-2xl pb-1'>+</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <input
                          type='number'
                          min='0'
                          name='discount'
                          id='discount'
                          defaultValue='0'
                          className='focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] pl-2 w-20 h-10 rounded-md ml-[105px]'
                          onClick={() => {
                            setInputDisplay({
                              ...inputDisplay,
                              price: "bg-white",
                            });
                          }}
                          onChange={(e) => {
                            setExistDiscount(e?.target?.value);
                            let buttonMinus = "border-stone-400 text-stone-400";
                            let buttonPlus;
                            priceArray = [];
                            items = 0;

                            setPriceHeader("hidden");

                            if (
                              e.target.value !== "" &&
                              document.getElementById("price").value !== ""
                            ) {
                              buttonPlus =
                                "border-cromboOrange text-cromboOrange";
                            }

                            if (e.target.value === "") {
                              buttonPlus = "border-stone-400 text-stone-400";
                            }

                            setButtonColor({
                              ...buttonColor,
                              minus: buttonMinus,
                              plus: buttonPlus,
                            });

                            setItemCounter(items);

                            setPriceDisplay(priceArray);

                            setCalculate({
                              ...calculate,
                              background: "bg-stone-300",
                              text: "text-stone-400",
                            });
                          }}></input>
                        <div className='relative'>
                          <div className='absolute top-[-31px] left-[160px]'>
                            %
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={priceHeader + " flex"}>
                      <div className='p-2 mt-4 mb-8 rounded-md bg-background overflow-x-auto overflow-y-auto'>
                        {priceDisplay?.map((item, i) => {
                          return (
                            <div
                              key={i}
                              className='flex'>
                              <div className='flex mr-4 items-center justify-between mb-1'>
                                <div className='text-sm mr-2 mt-1 whitespace-nowrap'>
                                  {t("admin:item")}
                                </div>
                                <div className='text-xl font-bold whitespace-nowrap'>
                                  {item} x{" "}
                                  {Math.round(
                                    document.getElementById("price")?.value *
                                      Math.pow(
                                        1 -
                                          document.getElementById("discount")
                                            ?.value /
                                            100,
                                        item - 1
                                      )
                                  )}
                                </div>
                              </div>
                              <div className='flex mr-4 items-center justify-between mb-1'>
                                <div className='text-sm mr-2 mt-1 whitespace-nowrap'>
                                  {t("admin:price")}
                                </div>
                                <div className='text-xl font-bold whitespace-nowrap'>
                                  {item} x{" "}
                                  {Math.round(
                                    Math.pow(
                                      1 -
                                        document.getElementById("discount")
                                          ?.value /
                                          100,
                                      item - 1
                                    ) * 100
                                  ) + "%"}
                                </div>
                              </div>
                              <div className='flex items-center justify-between mb-1'>
                                <div className='text-sm mr-2 mt-1 whitespace-nowrap'>
                                  {t("admin:total")}
                                </div>
                                <div className='text-xl text-cromboOrange font-bold whitespace-nowrap'>
                                  {totalDisplay()} kr.
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className='mb-3'>
                    <div>
                      <h2 className='text-base font-semibold mt-1'>Item</h2>
                      <input
                        name='item'
                        id='item'
                        defaultValue='Addon Name'
                        className='w-[250px] focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] pl-2 h-10 rounded-md font-semibold'
                        onChange={(e) => {
                          let buttonMinus = "border-stone-400 text-stone-400";
                          let buttonPlus = "border-stone-400 text-stone-400";
                          let additionalItemsButton = "text-stone-400";

                          if (
                            e.target.value !== "" &&
                            document.getElementById("itemPrice").value !== ""
                          ) {
                            buttonPlus =
                              "border-cromboOrange text-cromboOrange";
                          }
                          if (e.target.value === "") {
                            buttonPlus = "border-stone-400 text-stone-400";
                            additionalItemsButton = "text-stone-400";
                          }
                          if (
                            e.target.value !== "" &&
                            document.getElementById("itemPrice").value !== "" &&
                            extraCounter !== 0 &&
                            document.getElementById("extraDiscount").value !==
                              ""
                          ) {
                            additionalItemsButton = "text-cromboOrange";
                          }
                          setExtraButtons({
                            ...extraButtons,
                            minus: buttonMinus,
                            plus: buttonPlus,
                          });
                          setAdditionalItems(additionalItemsButton);
                        }}></input>
                    </div>
                    <div className='flex'>
                      <div>
                        <div className='flex'>
                          <div>
                            <h2 className='text-base font-semibold mt-1'>
                              {t("admin:price")}
                            </h2>
                            <input
                              name='itemPrice'
                              id='itemPrice'
                              type='number'
                              min='0'
                              defaultValue='0'
                              className='w-24 focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] pl-2 h-10 rounded-md font-semibold'
                              onChange={(e) => {
                                let buttonMinus =
                                  "border-stone-400 text-stone-400";
                                let buttonPlus;
                                let additionalItemsButton = "text-cromboOrange";
                                if (
                                  e.target.value !== "" &&
                                  document.getElementById("item").value !== ""
                                ) {
                                  buttonPlus =
                                    "border-cromboOrange text-cromboOrange";
                                }
                                if (e.target.value === "") {
                                  buttonPlus =
                                    "border-stone-400 text-stone-400";
                                  additionalItemsButton = "text-stone-400";
                                }
                                if (
                                  e.target.value !== "" &&
                                  document.getElementById("item").value !==
                                    "" &&
                                  extraCounter !== 0 &&
                                  document.getElementById("extraDiscount")
                                    .value !== ""
                                ) {
                                  additionalItemsButton = "text-cromboOrange";
                                }
                                setExtraButtons({
                                  ...extraButtons,
                                  minus: buttonMinus,
                                  plus: buttonPlus,
                                });
                                setAdditionalItems(additionalItemsButton);
                              }}></input>
                          </div>
                          <div className='flex relative'>
                            <div
                              className={
                                extraButtons.minus +
                                " absolute top-[40px] left-[1px] border-[1px] rounded-md w-5 h-5 flex items-center justify-center ml-3 cursor-pointer"
                              }
                              onClick={() => {
                                let additionalItemsButton = "text-stone-400";
                                if (extraCounter === 0) {
                                  return;
                                }
                                extraCounter = extraCounter - 1;
                                if (extraCounter === 0) {
                                  setExtraButtons({
                                    ...extraButtons,
                                    minus: "border-stone-400 text-stone-400",
                                  });
                                }
                                setExtras(extraCounter);
                                if (
                                  document.getElementById("item").value !==
                                    "" &&
                                  document.getElementById("itemPrice").value !==
                                    "" &&
                                  extraCounter !== 0 &&
                                  document.getElementById("extraDiscount")
                                    .value !== ""
                                ) {
                                  additionalItemsButton = "text-cromboOrange";
                                }
                                setAdditionalItems(additionalItemsButton);
                              }}>
                              <div className='text-2xl pb-1 font-semibold'>
                                -
                              </div>
                            </div>
                            <div className='absolute top-[40px] left-[43px] text-sm font-semibold'>
                              {extras}
                            </div>
                            <div
                              className={
                                extraButtons.plus +
                                " absolute top-[40px] left-[50px] border-[1px] border-cromboOrange text-cromboOrange rounded-md w-5 h-5 flex items-center justify-center ml-3 cursor-pointer"
                              }
                              onClick={() => {
                                let additionalItemsButton = "text-stone-400";
                                if (
                                  document.getElementById("item").value ===
                                    "" ||
                                  document.getElementById("itemPrice").value ===
                                    ""
                                ) {
                                  return;
                                }
                                extraCounter = extraCounter + 1;
                                setExtras(extraCounter);
                                setExtraButtons({
                                  ...extraButtons,
                                  minus:
                                    "border-cromboOrange text-cromboOrange",
                                });
                                if (
                                  document.getElementById("item").value !==
                                    "" &&
                                  document.getElementById("itemPrice").value !==
                                    "" &&
                                  document.getElementById("extraDiscount")
                                    .value !== ""
                                ) {
                                  additionalItemsButton = "text-cromboOrange";
                                }
                                setAdditionalItems(additionalItemsButton);
                              }}>
                              <div className='text-2xl pb-1 font-semibold'>
                                +
                              </div>
                            </div>
                          </div>
                          <div>
                            <h2 className='text-base font-semibold mt-1 ml-[105px]'>
                              {t("admin:discount")}
                            </h2>
                            <input
                              className='focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] pl-2 w-16 h-10 rounded-md ml-[105px] text-base font-semibold'
                              type='number'
                              min='0'
                              id='extraDiscount'
                              defaultValue='0'
                              onChange={(e) => {
                                let additionalItemsButton = "text-stone-400";
                                if (
                                  e.target.value !== "" &&
                                  document.getElementById("item").value !==
                                    "" &&
                                  extraCounter !== 0 &&
                                  document.getElementById("itemPrice").value !==
                                    ""
                                ) {
                                  additionalItemsButton = "text-cromboOrange";
                                }
                                setAdditionalItems(additionalItemsButton);
                              }}></input>
                          </div>
                          <div className='relative'>
                            <div className='absolute top-[37px] right-[12px] text-base font-semibold'>
                              %
                            </div>
                          </div>
                        </div>
                        <div className='relative'>
                          <div className='absolute text-base font-semibold top-[-31px] left-[65px]'>
                            kr.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='flex'>
                    <div
                      className={additionalItems + " cursor-pointer flex"}
                      onClick={() => {
                        if (
                          document.getElementById("item").value === "" ||
                          document.getElementById("itemPrice").value === "" ||
                          extraCounter === 0 ||
                          document.getElementById("extraDiscount").value === ""
                        ) {
                          return;
                        }
                        let item = document.getElementById("item").value;
                        let itemPrice =
                          document.getElementById("itemPrice").value;
                        let itemDiscount =
                          document.getElementById("extraDiscount").value;
                        let extraObject = {
                          item: item,
                          price: itemPrice,
                          number: extraCounter,
                          discount: itemDiscount,
                        };
                        let extraNumber = extraDisplay.counters;
                        let newArray = itemArrays.arrays;
                        let newHeader = "hidden";
                        let headerArray = [...additionalHeaders.headers];

                        extraNumber.push(1);
                        extraTotalCounter.push(1);
                        extraTotalSums.push(0);
                        newArray.push([1]);
                        headerArray.push(newHeader);
                        extraArray.push(extraObject);
                        document.getElementById("item").value = "";
                        document.getElementById("itemPrice").value = "";
                        document.getElementById("extraDiscount").value = "";
                        extraCounter = 1;
                        bonusDiscountArray.push(false);
                        setExtras(extraCounter);
                        setExtraButtons({
                          ...extraButtons,
                          minus: "border-stone-400 text-stone-400",
                          plus: "border-stone-400 text-stone-400",
                        });

                        setExtraDisplay({
                          ...extraDisplay,
                          extras: extraArray,
                          counters: extraNumber,
                        });
                        setItemArrays({
                          ...itemArrays,
                          arrays: newArray,
                        });
                        setAdditionalHeaders({
                          ...additionalHeaders,
                          headers: headerArray,
                        });
                        setAdditionalItems("text-stone-400");
                        setBonusDiscount(bonusDiscountArray);
                      }}>
                      <FaPlusSquare size={30} />
                      <h2 className={additionalItems + "text-base ml-1"}>
                        {t("admin:addItem")}
                      </h2>
                    </div>
                  </div>

                  <div>
                    {extraDisplay?.extras?.map((item, i) => {
                      return (
                        <div
                          key={i}
                          className='font-semibold text-base mb-1'>
                          <div className='flex bg-background pl-6 relative'>
                            <div
                              className='absolute top-[5px] left-[4px] cursor-pointer text-black'
                              id={i}
                              onClick={(e) => {
                                // https://stackoverflow.com/questions/71632997/how-to-get-around-react-setstate-delay [Shallow Copies]
                                let removeItem = [...extraDisplay.extras];
                                let removeNumber = extraDisplay.counters;
                                let removeArray = itemArrays.arrays;

                                removeItem.splice(i, 1);
                                removeNumber.splice(i, 1);
                                removeArray.splice(i, 1);
                                bonusDiscountArray.splice(i, 1);

                                setExtraDisplay({
                                  ...extraDisplay,
                                  extras: removeItem,
                                  counters: removeNumber,
                                });
                                setItemArrays({
                                  ...itemArrays,
                                  arrays: removeArray,
                                });
                                extraArray = removeItem;
                                setBonusDiscount(bonusDiscountArray);
                              }}>
                              <IoMdClose />
                            </div>
                            <div className='p-2 rounded-md mt-4 text-lg'>
                              <div className='flex items-center justify-between mb-1'>
                                <div className='text-sm mr-2 mt-1 whitespace-nowrap'>
                                  {t("admin:item")}
                                </div>
                                <div className='text-md font-bold whitespace-nowrap'>
                                  {item.number} x {item.item}
                                </div>
                              </div>

                              <div className='flex items-center justify-between mb-1'>
                                <div className='text-sm mr-2 mt-1 whitespace-nowrap'>
                                  {t("admin:price")}
                                </div>
                                <div className='text-md font-bold whitespace-nowrap'>
                                  {item.price} kr.
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='flex bg-background pb-1 pl-[100px]'>
                            <div
                              className='border-[1px] border-cromboOrange bg-white text-cromboOrange rounded-md w-5 h-5 flex items-center justify-center ml-3 cursor-pointer'
                              onClick={() => {
                                if (extraDisplay.counters[i] === 0) {
                                  return;
                                }
                                let subtraction = extraDisplay.counters[i];
                                let subtractionArray = itemArrays.arrays[i];
                                let activeHeader = [
                                  ...additionalHeaders.headers[i],
                                ];
                                subtractionArray.pop();

                                subtraction = subtraction - 1;
                                setExtraDisplay({
                                  ...extraDisplay,
                                  activeObject: (extraDisplay.counters[i] =
                                    subtraction),
                                });
                                setItemArrays({
                                  ...itemArrays,
                                  activeObject: (itemArrays.arrays[i] =
                                    subtractionArray),
                                });
                                if (subtraction === 0) {
                                  activeHeader = "hidden";
                                  bonusDiscountArray[i] = false;
                                }
                                setAdditionalHeaders({
                                  ...additionalHeaders,
                                  activeObject: (additionalHeaders.headers[i] =
                                    activeHeader),
                                });
                                setBonusDiscount(bonusDiscountArray);
                              }}>
                              <div className='text-2xl pb-1'>-</div>
                            </div>
                            <div className='text-sm font-semibold ml-3 w-2'>
                              {extraDisplay.counters[i]}
                            </div>
                            <div
                              className='border-[1px]  border-cromboOrange bg-white text-cromboOrange rounded-md w-5 h-5 flex items-center justify-center ml-3 cursor-pointer'
                              onClick={() => {
                                let addition = extraDisplay.counters[i];
                                let additionArray = itemArrays.arrays[i];
                                let activeHeader = [
                                  ...additionalHeaders.headers[i],
                                ];
                                activeHeader = "visible";
                                addition = addition + 1;
                                additionArray.push(addition);
                                bonusDiscountArray[i] = true;
                                setExtraDisplay({
                                  ...extraDisplay,
                                  activeObject: (extraDisplay.counters[i] =
                                    addition),
                                });
                                setItemArrays({
                                  ...itemArrays,
                                  activeObject: (itemArrays.arrays[i] =
                                    additionArray),
                                });
                                setAdditionalHeaders({
                                  ...additionalHeaders,
                                  activeObject: (additionalHeaders.headers[i] =
                                    activeHeader),
                                });
                                setBonusDiscount(bonusDiscountArray);
                              }}>
                              <div className='text-2xl pb-1'>+</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    {extraDisplay.extras.map((item, i) => (
                      <div
                        key={i}
                        className='text-base font-semibold mb-8'>
                        <h2>{"Item " + (i + 1) + ": " + item.item}</h2>
                        <div className='flex'>
                          <div>{t("admin:discount")}:</div>
                          <div className='ml-2'>{item.discount + "%"}</div>
                        </div>
                        <div className='flex-row relative pl-6 bg-background'>
                          <div
                            className={additionalHeaders.headers[i] + " flex"}>
                            <h3 className='w-[70px]'>{t("admin:quantity")}</h3>
                            <h3 className='w-[50px]'>{t("admin:price")}</h3>
                            <h3 className='w-[90px]'>% {t("admin:price")}</h3>
                            <h3 className='ml-4'>{t("admin:total")}</h3>
                          </div>
                          <div></div>
                          {Array.from(
                            { length: extraDisplay.counters[i] },
                            (_, j) => (
                              <div
                                key={j}
                                className='flex'>
                                <div className='ml-2 w-4 text-right'>x</div>
                                <div className='w-[20px] text-right mr-6'>
                                  {j + 1}
                                </div>
                                <div className='w-8 text-right'>
                                  {Math.round(
                                    item.price *
                                      Math.pow(1 - item.discount / 100, j)
                                  )}
                                </div>
                                <div className='ml-1'>kr.</div>
                                <div className='w-16 text-right'>
                                  {Math.round(
                                    Math.pow(1 - item.discount / 100, j) * 100
                                  ) + "%"}
                                </div>
                                <div className='w-16 text-right'>
                                  {extraItemTotal(i, j + 1)}
                                </div>
                                <div className='ml-2'>kr.</div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    ))}
                  </div>

                  <div>
                    <div className='text-base font-semibold mt-4'>
                      {t("productPage:bonusDiscountLabel")}
                    </div>
                    <div className='relative'>
                      <input
                        type='number'
                        min='0'
                        id='bonusDiscount'
                        defaultValue='0'
                        onChange={(e) => setDiscountBonus(e?.target?.value)}
                        className='w-28 focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] pl-2 h-10 rounded-md font-semibold'></input>
                      <div className='text-base font-semibold absolute top-[8px] left-[65px]'>
                        kr.
                      </div>
                    </div>
                  </div>

                  <div className='relative'>
                    <div className='text-base font-semibold mt-2'>
                      {t("admin:deliveryPriceLabel")}
                    </div>
                    <input
                      type='number'
                      min='0'
                      id='delivery'
                      defaultValue='0'
                      className='w-28 focus:border-cromboOrange focus:ring-0 border-stone-400 border-[2px] pl-2 h-10 rounded-md font-semibold'
                      onChange={(e) => {
                        setCostDelivery(e?.target?.value);
                        let discount =
                          document.getElementById("discount").value;
                        let price = document.getElementById("price").value;
                        let deliveryInput =
                          document.getElementById("delivery").value;
                        if (
                          discount !== "" &&
                          itemCounter !== 0 &&
                          price !== "" &&
                          deliveryInput !== ""
                        ) {
                          setCalculate({
                            ...calculate,
                            background: "bg-cromboOrange",
                            text: "text-white",
                          });
                        }
                        if (
                          discount === "" ||
                          itemCounter === 0 ||
                          price === "" ||
                          deliveryInput === ""
                        ) {
                          setCalculate({
                            ...calculate,
                            background: "bg-stone-300",
                            text: "text-stone-400",
                          });
                        }
                      }}></input>
                    <div className='text-base font-semibold absolute top-[32px] left-[65px]'>
                      kr.
                    </div>
                  </div>

                  <div className='flex justify-center'>
                    <div
                      className={calculate.background + " mt-4 btn-main"}
                      onClick={() => {
                        let discount =
                          document.getElementById("discount").value;
                        let price = document.getElementById("price").value;
                        let deliveryInput =
                          document.getElementById("delivery").value;
                        let bonusDiscountValue =
                          document.getElementById("bonusDiscount").value;
                        // const bonusDiscountTest = bonusDiscount.some(bonusCheck);
                        const bonusDiscountTest = bonusDiscount.includes(false);

                        if (
                          bonusDiscountTest === false &&
                          bonusDiscountValue !== "" &&
                          bonusDiscountValue !== 0
                        ) {
                          setDiscountVisible("visible");
                          setDiscountNumber(bonusDiscountValue);
                        }

                        if (bonusDiscountTest === true) {
                          setDiscountVisible("hidden");
                          setDiscountNumber(0);
                        }

                        if (
                          discount === "" ||
                          itemCounter === 0 ||
                          price === "" ||
                          deliveryInput === ""
                        ) {
                          return;
                        }

                        setDeliveryCost(deliveryInput);

                        extraTotalDisplay = [];

                        setCalculations({
                          ...calculations,
                          productPercentage: discount,
                          productPrice: price,
                        });

                        setGrandTotalDisplay("visible");
                      }}>
                      <h1
                        className={
                          calculate.text + " text-lg text-center font-semibold"
                        }>
                        Calculate Grand Total
                      </h1>
                    </div>
                  </div>

                  <article
                    className={
                      grandTotalDisplay + " text-sm font-semibold mt-8"
                    }>
                    <div className='p-2 mb-8 rounded-md bg-background'>
                      <div className='flex items-center justify-between mb-1'>
                        <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                          {t("admin:item")}
                        </div>
                        <div className='text-xl font-bold whitespace-nowrap'>
                          {itemCounter} x{" "}
                          {searchTerm?.adminSearch[0]?.title?.substring(0, 5)}
                        </div>
                      </div>

                      <div className='flex items-center justify-between mb-1'>
                        <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                          {t("admin:pricePer")}
                        </div>
                        <div className='text-xl font-bold whitespace-nowrap'>
                          {searchTerm?.adminSearch[0]?.price} kr.
                        </div>
                      </div>
                      <div className='flex items-center justify-between mb-1'>
                        <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                          {t("admin:unitDiscount")} ({productDiscount()} %)
                        </div>
                        <div className='text-xl font-bold whitespace-nowrap'>
                          {"-" + discountDisplay()} kr.
                        </div>
                      </div>
                      <div className='flex items-center justify-between mb-1'>
                        <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                          {t("admin:priceAverage")}
                        </div>
                        <div className='text-xl font-bold whitespace-nowrap'>
                          {productPriceAverage()} kr.
                        </div>
                      </div>
                      <div className='flex items-center justify-between mb-1'>
                        <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                          {t("admin:total")}
                        </div>
                        <div className='text-xl text-cromboOrange font-bold whitespace-nowrap'>
                          {productTotal()} kr.
                        </div>
                      </div>
                    </div>

                    {extraDisplay?.extras?.map((item, i) => {
                      return (
                        <div className='p-2 rounded-md bg-background mt-4 text-lg'>
                          {t("admin:addonHeader")}
                          <div className='flex items-center justify-between mb-1'>
                            <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                              {t("admin:item")}
                            </div>
                            <div
                              className='text-xl font-bold whitespace-nowrap'
                              key={i}>
                              {extraDisplay.counters[i]}x{" "}
                              {item.item.substring(0, 5)}
                            </div>
                          </div>
                          <div className='flex items-center justify-between mb-1'>
                            <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                              {t("admin:price")}
                            </div>
                            <div
                              className='text-xl font-bold whitespace-nowrap'
                              key={i}>
                              {item.price} kr.
                            </div>
                          </div>
                          <div className='flex items-center justify-between mb-1'>
                            <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                              {t("admin:unitDiscount")}
                            </div>
                            <div
                              className='text-xl font-bold whitespace-nowrap'
                              key={i}>
                              {extraDiscountAverage(i)} kr.
                            </div>
                          </div>
                          <div className='flex items-center justify-between mb-1'>
                            <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                              {t("admin:pricePer")}
                            </div>
                            <div
                              className='text-xl font-bold whitespace-nowrap'
                              key={i}>
                              {extraPriceAverage(i)} kr.
                            </div>
                          </div>
                          <div className='flex items-center justify-between mb-1'>
                            <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                              {t("admin:total")}
                            </div>
                            <div
                              className='text-xl text-cromboOrange font-bold whitespace-nowrap'
                              key={i}>
                              {extraItemTotal(i)} kr.
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <div className='flex items-center justify-between mb-1 px-2 mt-8'>
                      <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                        {t("deliveryPriceLabel")}
                      </div>
                      <div className='text-xl font-bold whitespace-nowrap'>
                        {delivery()} kr.
                      </div>
                    </div>
                    <div className='flex items-center justify-between mb-1 px-2'>
                      <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                        {t("productPage:bonusDiscountLabel")}
                      </div>
                      <div className='text-xl font-bold whitespace-nowrap'>
                        {"-" + bonusDiscountFunc()} kr.
                      </div>
                    </div>
                    <div className='flex items-center justify-between mb-1 px-2'>
                      <div className='text-sm mr-2 mb-0.5 whitespace-nowrap'>
                        {t("grandTotalLabel")}
                      </div>
                      <div className='text-2xl text-cromboOrange font-bold whitespace-nowrap'>
                        {grandTotal()} kr.
                      </div>
                    </div>
                  </article>

                  <div className='relative'>
                    <div className='absolute text-red-500 text-base font-semibold top-[-10px]'>
                      {warning}
                    </div>
                  </div>
                </div>

                <div className='flex justify-center'>
                  <img
                    alt={t("altText:horizontalDivider")}
                    src='./../assets/icons/divider-horizontal.svg'
                    className='w-80'
                  />
                </div>

                <div className='bg-white p-4 my-8 shadow-lg'>
                  <div className='flex'>
                    <img
                      alt={t("altText:verticalDivider")}
                      src='./../assets/icons/divider-vertical.svg'
                      className='w-2 h-7'
                    />
                    <h2 className='text-lg ml-2 mb-3 font-semibold'>
                      {t("admin:actionHeader")}
                    </h2>
                  </div>
                  <div className='flex flex-col items-center justify-center text-base font-semibold mt-2'>
                    <h2 className='mb-2 text-center'>{t("admin:quality")}</h2>
                    <Switch
                      onColor={"#E16A2E"}
                      checked={isQualityVerified}
                      onChange={handleSwitchChange}
                      className='items-center'
                    />
                  </div>
                  <div className='flex justify-center mt-8'></div>
                  <div className='flex justify-center mt-5'>
                    <button
                      onClick={() => handleApproved("approved")}
                      disabled={
                        item?.reason || approveLoading || preApproveLoading
                      }>
                      <div
                        className={`p-3 px-10 mb-4 text-xl text-center font-semibold rounded-lg cursor-pointer shadow-lg shadow-slate-300 text-white transition-all duration-100 delay-75 ${
                          item?.reason || approveLoading || preApproveLoading
                            ? "bg-green-600 cursor-not-allowed opacity-60"
                            : "bg-green-600 hover:bg-green-500 cursor-pointer"
                        }`}>
                        {approveLoading ? (
                          <span className='text-white text-lg text-center font-semibold'>
                            Loading...
                          </span>
                        ) : (
                          <h1 className='text-white text-lg text-center font-semibold'>
                            {t("admin:approved")}
                          </h1>
                        )}
                      </div>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default SubadminEditApproval;

const Checkbox = ({ name, label, inputDisplay, setInputDisplay }) => {
  const handleChange = () => {
    const updatedRooms = Array.isArray(inputDisplay.rooms)
      ? [...inputDisplay.rooms]
      : []; // Ensure inputDisplay.rooms is an array
    const roomIndex = updatedRooms.indexOf(name);
    if (roomIndex !== -1) {
      updatedRooms.splice(roomIndex, 1); // Remove room if already selected
    } else {
      updatedRooms.push(name); // Add room if not selected
    }
    setInputDisplay({ ...inputDisplay, rooms: updatedRooms });
  };

  return (
    <div className='w-40'>
      <input
        type='checkbox'
        name={name}
        checked={inputDisplay.rooms && inputDisplay.rooms.includes(name)} // Ensure inputDisplay.rooms is not null or undefined before checking its contents
        className='text-cromboOrange mr-1 ml-1 cursor-pointer'
        onChange={handleChange}
      />
      <label>{label}</label>
    </div>
  );
};
