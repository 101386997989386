import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getReq } from "../../../configs/ApiUrl";

function DiscountCodeCheck({ grandTotal, setGrandTotal, shoppingCart }) {
  const { t } = useTranslation(["cartPage", "altText"]);
  const [discountCode, setDiscountCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [appliedCodes, setAppliedCodes] = useState([]); // Track applied discount codes
  const [isCodeApplied, setIsCodeApplied] = useState(false); // Track if a code has been applied

  const handleDiscountChange = (e) => {
    setDiscountCode(e.target.value);
  };

  const handleApplyDiscount = async () => {
    setErrorMessage("");
    if (appliedCodes.includes(discountCode)) {
      setErrorMessage(t("codeAlreadyUsedMessage"));
      return;
    }
    try {
      const endpoint = `/discountCodes/checkCode/${discountCode}`;
      const response = await getReq(endpoint);

      if (response?.success) {
        const { discountType, discountValue, author } = response.discountCode;
        let discountApplicable = false;

        if (author === 'Crombo') {
          discountApplicable = true;
        } else {
          discountApplicable = shoppingCart.some(item => {
            return item.subadminEmail && item.subadminEmail === author;
          });
        }

        if (discountApplicable) {
          let newTotal = grandTotal;
          if (discountType === 'flatRate') {
            newTotal = Math.max(0, newTotal - discountValue);
          } else if (discountType === 'percentage') {
            newTotal = Math.max(0, newTotal * (1 - discountValue / 100));
          }
          setGrandTotal(newTotal);
          setAppliedCodes((prevCodes) => [...prevCodes, discountCode]); // Add code to applied codes
          setIsCodeApplied(true); // Mark code as applied
          setErrorMessage('');
        } else {
          setErrorMessage('The code is not applicable for this product.');
        }
      } else {
        setErrorMessage(response.message || t("invalidCodeMessage"));
      }
    } catch (error) {
      console.error("Error applying discount code:", error);
      setErrorMessage(t("errorApplyingCodeMessage"));
    }
  };

  return (
    <div className="flex flex-col font-semibold mb-12">
      <div className="flex flex-col items-end mt-2">
        <h4 className="mb-2 mr-24">{t("discountLabel")}</h4>
        <input
          className="form-field mb-2 mr-2"
          type="text"
          placeholder={t("discountPlace")}
          value={discountCode}
          onChange={handleDiscountChange}
          disabled={isCodeApplied} // Disable input if code is applied
          style={{ backgroundColor: isCodeApplied ? "#d3d3d3" : "white" }} // Grey out input if code is applied
        />
        <button
          className={`px-2 mr-2 border-4 border-cromboOrange bg-cromboOrange hover:border-cromboOrangeFade hover:bg-cromboOrangeFade rounded-md text-sm text-white font-bold transition-all duration-100 ${isCodeApplied ? "cursor-not-allowed" : "cursor-pointer"}`}
          onClick={handleApplyDiscount}
          disabled={isCodeApplied} // Disable button if code is applied
        >
          {t("discountButton")}
        </button>
        {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
      </div>
    </div>
  );
}

export default DiscountCodeCheck;