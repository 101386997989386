import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getReq } from "../../configs/ApiUrl.js";
import { LoadingSpinner } from "./util/LoadingSpinner.js";
import defaultImg from "../../images/defaultImg.webp";
import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi2";
import PageSubheader from "../PageSubheader.js";

function UserSell() {
  const { t } = useTranslation([
    "userProfile",
    "tables",
    "altText",
    "subadmin",
  ]);
  const [loading, setLoading] = useState(false);
  const [myProducts, setMyProducts] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch products directly for the authenticated user
        const myProductsResp = await getReq("/product/user/products");
        if (myProductsResp?.success) {
          setMyProducts(myProductsResp.myProducts);
        } else {
          console.error("Failed to fetch products.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const STATUS_STEPS = ["Added", "Approved", "Bought", "Delivered"];
  const STATUS_MAP = {
    pending: 0,
    approved: 1,
    sold: 2,
    deliveryApproved: 3,
  };
  const STATUS_MESSAGES = {
    pending: (title) => `${title} is awaiting admin approval`,
    approved: (title) => `${title} is live on our marketplace`,
    sold: (title) => `${title} is due to be delivered to the buyer`,
    deliveryApproved: (title) => `${title} has been delivered`,
  };

  const getClassNames = (currentStepIndex, stepIndex) => {
    const baseCircle =
      "w-12 h-12 rounded-full flex items-center justify-center";
    const baseLine = "flex-1 h-1 mb-6 min-w-[20px]";
    const circleCompleted = currentStepIndex >= stepIndex;
    const lineCompleted = currentStepIndex > stepIndex;

    return {
      circle: circleCompleted
        ? `${baseCircle} bg-green-500`
        : `${baseCircle} bg-white border-2 border-gray-300`,
      line: lineCompleted
        ? `${baseLine} bg-green-500`
        : `${baseLine} bg-gray-300`,
      showCheck: circleCompleted,
    };
  };

  const getStatusMessage = (status, title) => {
    const messageFn = STATUS_MESSAGES[status];
    return messageFn ? messageFn(title) : "";
  };

  return (
    <div className='flex flex-col items-center mt-8'>
      <main className='w-full flex flex-col justify-center bg-white drop-shadow'>
        {loading ? (
          <LoadingSpinner />
        ) : myProducts.length > 0 ? (
          <div className='flex flex-col'>
            {myProducts.map((product) => {
              const currentStepIndex = STATUS_MAP[product.status] || 0;
              const statusMessage = getStatusMessage(
                product.status,
                product.title
              );

              return (
                <React.Fragment key={product._id}>
                  <div className='product-card flex flex-col md:flex-row w-full px-4 py-6'>
                    <div className='w-full md:w-1/3'>
                      <div className='relative'>
                        <img
                          className='w-full h-48 object-cover'
                          src={
                            product.mainImage ||
                            product.imageNames[0] ||
                            defaultImg
                          }
                          alt={t("altText:firstImage")}
                          onError={(e) => {
                            e.target.src = defaultImg;
                          }}
                        />
                        <div className='absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white text-center py-1'>
                          <span className='text-xs truncate'>
                            {product.title.length > 15
                              ? `${product.title.substring(0, 15)}...`
                              : product.title}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='w-full md:w-2/3 mt-4 md:mt-0 md:pl-4'>
                      <div className='flex items-center'>
                        {STATUS_STEPS.map((label, idx) => {
                          const { circle, line, showCheck } = getClassNames(
                            currentStepIndex,
                            idx
                          );
                          return (
                            <React.Fragment key={idx}>
                              {idx !== 0 && <div className={line}></div>}
                              <div className='relative flex flex-col items-center mx-2'>
                                <div className={circle}>
                                  {showCheck && (
                                    <svg
                                      className='w-6 h-6 text-white'
                                      fill='none'
                                      stroke='currentColor'
                                      viewBox='0 0 24 24'>
                                      <path
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                        strokeWidth='2'
                                        d='M5 13l4 4L19 7'></path>
                                    </svg>
                                  )}
                                </div>
                                <div className='mt-2 text-sm font-semibold'>
                                  {label}
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </div>
                      <p className='mt-4 text-sm font-semibold text-gray-500'>
                        {statusMessage}
                      </p>
                    </div>
                  </div>
                  <hr className='border-t-2 border-cromboOrange' />
                </React.Fragment>
              );
            })}
          </div>
        ) : (
          <div className='flex items-center justify-center w-full h-96 bg-white'>
            <h1 className='text-xl font-bold text-center text-gray-500'>
              {t("userProfile:noProducts")}
            </h1>
          </div>
        )}
        <div className='flex justify-center my-12'>
          <Link to='/selling-add-furniture'>
            <button className='flex items-center text-cromboOrange font-bold text-xl'>
              <HiPlus
                className='mr-2 h-16 w-16'
                alt={t("altText:plus")}
              />
              {t("subadmin:newProductButton")}
            </button>
          </Link>
        </div>
      </main>
    </div>
  );
}

export default UserSell;
