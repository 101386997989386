import { useState, useContext, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import { FurnitureSearch } from "../../App.js";
import { postReq, getReq } from "../../configs/ApiUrl.js";
import LoadingButtonWide from "../LoadingButtonWide.js";
import { useTranslation } from "react-i18next";
import OrderCard from "./util/OrderCard.js";
import moment from "moment";
import { FaRegCreditCard, FaTruck } from "react-icons/fa";
import KlarnaPayment from "./util/KlarnaPayment.js";
import { OrderContext } from "./util/OrderContext.js";
import { IoMdClose } from "react-icons/io";
import DiscountCodeCheck from "./util/DiscountCodeCheck.js";

import PageSubheader from "../PageSubheader.js";
import PageHeader from "../PageHeader.js";

function CatalogueAddress() {
  const { t } = useTranslation([
    "forms",
    "utilityPages",
    "admin",
    "checkoutPage",
    "cartPage",
    "altText",
  ]);
  const navigate = useNavigate();
  const location = useLocation();

  // If something was passed in location.state, keep it; but we will also recalc grandTotal below
  const [deliverySlots, setDeliverySlots] = useState(
    location.state?.deliverySlots || []
  );

  const token = localStorage.getItem("token");
  const [userData, setUserData] = useState(null); // user profile
  const [loading, setLoading] = useState(false);

  // Address form states
  const [shippingAddress, setShippingAddress] = useState({
    username: "",
    email: "",
    street: "",
    postalCode: "",
    phone: "",
    state: "",
    city: "",
  });
  const [formAlert, setFormAlert] = useState("");
  const [emailVerify2, setEmailVerify2] = useState("");

  // Other UI states
  const [loginAlert, setLoginAlert] = useState("");
  const [loginInput, setLoginInput] = useState({
    loginEmail: "",
    loginPassword: "",
  });
  const [emailLoginCheck, setEmailLoginCheck] = useState("");
  const [passwordLength, setPasswordLength] = useState("");
  const [formDisplay] = useState({
    defaultShipping: "visible",
    duplicateShipping: "hidden",
  });
  const [inputFormat2] = useState({
    userNameInput: "",
    emailInput2: "",
    streetInput2: "",
    postalCodeInput2: "",
    phoneInput2: "",
    stateInput2: "",
    cityInput2: "",
  });

  // FurnitureSearch context for global cart, etc.
  const [searchTerm, setSearchTerm] = useContext(FurnitureSearch);
  const { setOrderData } = useContext(OrderContext);

  const sanitizeItem = (item) => {
    const product = item.productId || {};
    const productCost = item.productCost || 0;
    const movingCost = item.deliveryPrice || 0;
    const addOns = item.addOns || [];

    // Calculate add-ons total
    const addOnsTotal = addOns.reduce(
      (sum, addOn) => sum + (addOn.cost || 0), // **REMOVED quantity multiplication**
      0
    );

    const discount = item.discount || 0;
    const bonusDiscount = item.bonusDiscount || 0;

    return {
      productId: item.productId,
      name: product.title || "Unknown Product",
      image: product.mainImage || "",
      quantity: item.quantity || 1,
      productCost,
      movingCost,
      extraTotal: addOnsTotal,
      deliveryPrice: movingCost,
      discount,
      bonusDiscount,
      grandTotal:
        productCost + movingCost + addOnsTotal - discount - bonusDiscount,
    };
  };

  const [sanitizedCart, setSanitizedCart] = useState([]);

  // We'll keep a local "grandTotal" as a sum over all items
  const [grandTotal, setGrandTotal] = useState(0);

  // Recalculate on mount / cart changes
  useEffect(() => {
    const total = sanitizedCart.reduce((acc, item) => acc + item.grandTotal, 0);
    setGrandTotal(total);
  }, [sanitizedCart]);

  // Fetch user data (profile) on mount
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await getReq("/user/profile");
        if (response && response.user && Array.isArray(response.user.cart)) {
          const sanitizedCartData = response.user.cart.map(sanitizeItem);
          setSanitizedCart(sanitizedCartData);
          if (response && response.user) {
            setUserData(response.user);
            setShippingAddress({
              username: response.user.username || "",
              email: response.user.email || "",
              street: response.user.address?.street || "",
              postalCode: response.user.address?.postalCode || "",
              phone: response.user.phone || "",
              state: response.user.address?.state || "",
              city: response.user.address?.city || "",
            });
          }
          if (
            JSON.stringify(searchTerm.shoppingCart) !==
            JSON.stringify(sanitizedCartData)
          ) {
            setSearchTerm((prevSearchTerm) => ({
              ...prevSearchTerm,
              shoppingCart: sanitizedCartData,
            }));
          }
        } else {
          setSanitizedCart([]);
        }
      } catch (error) {
        console.error("Failed to fetch user profile:", error);
        setSanitizedCart([]);
      }
    };

    fetchUserData();
  }, []); // Empty dependency array

  // Removing an item from the cart
  const removeCartItem = async (index) => {
    if (index < 0 || index >= searchTerm.shoppingCart.length) {
      console.error("Invalid cart item index");
      return;
    }

    const updatedCart = [...searchTerm.shoppingCart];
    updatedCart.splice(index, 1);

    setSearchTerm((prev) => ({ ...prev, shoppingCart: updatedCart }));
    localStorage.setItem("shoppingCart", JSON.stringify(updatedCart));

    try {
      const response = await postReq("/user/update-cart", {
        userId: localStorage.getItem("userId"),
        cartItems: updatedCart.map((item) => ({
          productId: item.productId,
          quantity: item.quantity,
          deliveryPrice: item.deliveryPrice,
          addOns: item.addOns,
        })),
      });

      if (!response.success) {
        throw new Error(response.message);
      }
    } catch (error) {
      console.error("Error updating cart on the server:", error);
      // Rollback changes
      setSearchTerm((prev) => ({
        ...prev,
        shoppingCart: [...prev.shoppingCart, searchTerm.shoppingCart[index]],
      }));
      localStorage.setItem(
        "shoppingCart",
        JSON.stringify([
          ...searchTerm.shoppingCart,
          searchTerm.shoppingCart[index],
        ])
      );
    }
  };

  // Handle input changes in the shipping form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShippingAddress((prev) => ({ ...prev, [name]: value }));
  };

  const validateShippingAddress = () => {
    const fields = [
      "username",
      "email",
      "street",
      "postalCode",
      "phone",
      "state",
      "city",
    ];
    for (const field of fields) {
      if (!shippingAddress[field]) {
        console.log(`Validation failed. Missing field: ${field}`);
        setFormAlert(`Please complete the ${field} field.`);
        return false;
      }
    }
    console.log("Shipping address validation passed.");
    return true;
  };

  // Fetch product details
  const fetchProductDetails = async (productId) => {
    try {
      const response = await getReq(`/product/${productId}`);
      return response.product;
    } catch (error) {
      console.error("Error fetching product details:", error);
      return null;
    }
  };

  // Prepare the entire order object
  const prepareOrderData = async () => {
    if (!validateShippingAddress()) {
        return false;
    }

    const customerId = localStorage.getItem("userId");
    const orderItems = sanitizedCart;

    if (!customerId || !orderItems.length) {
        message.warning("Missing user or cart data", 1);
        return false;
    }

    // ✅ Extract the correct productId
    const firstItem = orderItems[0];
    const firstItemProductId = firstItem.productId?._id || firstItem.productId; // Ensure productId is a string

    if (!firstItemProductId || typeof firstItemProductId !== "string") {
        console.error("Invalid productId in cart:", firstItemProductId);
        message.error("No product found in cart", 1);
        return false;
    }

    // Fetch additional details for the first product
    const productDetails = await fetchProductDetails(firstItemProductId);
    if (!productDetails) {
        message.error("Failed to fetch product details", 1);
        return false;
    }

    // Construct the final order object
    const orderData = {
        customerId,
        shippingAddress,
        orderItems,
        deliverySlots,
        subadminEmail: productDetails.subadminEmail,
        courier: productDetails.productAuthor,
    };

    setOrderData(orderData);
    localStorage.setItem("orderData", JSON.stringify(orderData));
    return orderData;
};

  // Create the order on the backend
  const createOrder = async () => {
    setLoading(true);
    const orderData = await prepareOrderData();

    if (!orderData) {
      console.log("Order data is not prepared. Aborting createOrder.");
      setLoading(false);
      return;
    }

    console.log("Sending order data to the server:", orderData);

    try {
      const resp = await postReq("/order/create", orderData);
      if (resp?.createdOrder?._id) {
        console.log("Order created successfully:", resp.createdOrder);
        setSearchTerm({ ...searchTerm, shoppingCart: [], deliverySlots: [] });
        navigate("/order-success");
        message.success("Order Success", 2);
      } else {
        console.error("Order creation failed:", resp);
        message.error("Order Failed", 1);
      }
    } catch (error) {
      console.error("Order creation error:", error);
      message.error("Order Failed", 1);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateOrder = async (e) => {
    e.preventDefault();
    setLoading(true);
    await createOrder();
    setLoading(false);
  };

  // Possibly for the timeslots editing
  const handleSetTimeslots = () => {
    navigate("/calendar", {
      state: {
        grandTotal,
        shoppingCart: sanitizedCart,
        isSeller: false,
      },
    });
  };

  // For Klarna
  const navigateToConfirmation = () => {
    const saved = localStorage.getItem("orderData");
    if (saved) {
      navigate(
        "/order-success-klarna/order-success-klarna?order_id={checkout.order.id}"
      );
    } else {
      message.error("Order data is not available");
    }
  };

  // (Optional) handle sign in
  const handleSignIn = (e) => {
    e.preventDefault();
    // Implement sign in logic if user not logged in
    message.info("Sign-in logic here");
  };

  // If the cart is empty, show a placeholder
  const emptyCart = () => {
    if (!sanitizedCart.length) {
      return (
        <div className='flex flex-col items-center justify-center mt-12 mb-12'>
          <img
            src='/assets/icons/bag-icon.svg'
            alt={t("altText:logo")}
            width={100}
            className='mb-4'
          />
          <h2 className='text-center text-xl font-bold mb-2'>
            {t("cartPage:cartSubheader")}
          </h2>
          <p className='text-center text-stone-400'>
            {t("cartPage:cartEmpty")}
          </p>
        </div>
      );
    }
  };

  return (
    <div className='bg-gray-100 min-h-screen'>
      <header className='mb-16'>
        <PageHeader />
      </header>
      <PageSubheader title='Checkout' />
      <div className='max-w-7xl mx-auto px-4 py-4'>
        {/* 1. Delivery Address or login */}
        <div className='bg-white rounded-lg shadow p-6 mb-8'>
          <h2 className='text-xl font-semibold mb-4'>
            {t("forms:shippingHeader")}
          </h2>
          {!token ? (
            // If not logged in, show a sign-in form
            <form
              onSubmit={handleSignIn}
              className='space-y-4'>
              <div>
                <label
                  htmlFor='emailLogin'
                  className='block text-gray-600 font-medium'>
                  Email
                </label>
                <input
                  name='emailLogin'
                  id='emailLogin'
                  autoComplete='email'
                  className='mt-1 w-full border border-gray-300 rounded p-2'
                  onChange={(e) =>
                    setLoginInput({
                      ...loginInput,
                      loginEmail: e.target.value,
                    })
                  }
                />
                {emailLoginCheck && (
                  <div className='text-red-500 text-sm mt-1'>
                    {emailLoginCheck}
                  </div>
                )}
              </div>
              <div>
                <label
                  htmlFor='passwordLogin'
                  className='block text-gray-600 font-medium'>
                  Password
                </label>
                <input
                  name='passwordLogin'
                  id='passwordLogin'
                  type='password'
                  autoComplete='new-password'
                  className='mt-1 w-full border border-gray-300 rounded p-2'
                  onChange={(e) =>
                    setLoginInput({
                      ...loginInput,
                      loginPassword: e.target.value,
                    })
                  }
                />
                {loginAlert && (
                  <div className='text-red-500 text-sm mt-1'>{loginAlert}</div>
                )}
              </div>
              <button
                type='submit'
                className='w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition-colors'>
                Sign In
              </button>
            </form>
          ) : (
            // Logged in: show the shipping form
            <form>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <div>
                  <label
                    htmlFor='username'
                    className='block text-gray-600 font-medium'>
                    {t("forms:usernameLabel")}
                  </label>
                  <input
                    name='username'
                    id='username'
                    onChange={handleInputChange}
                    value={shippingAddress.username}
                    className='mt-1 w-full border border-gray-300 rounded p-2'
                  />
                </div>
                <div>
                  <label
                    htmlFor='email'
                    className='block text-gray-600 font-medium'>
                    {t("forms:emailLabel")}
                  </label>
                  <input
                    name='email'
                    id='email2'
                    onChange={handleInputChange}
                    value={shippingAddress.email}
                    className='mt-1 w-full border border-gray-300 rounded p-2'
                  />
                  {emailVerify2 && (
                    <div className='text-red-500 text-sm mt-1'>
                      {emailVerify2}
                    </div>
                  )}
                </div>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-4'>
                <div>
                  <label
                    htmlFor='street'
                    className='block text-gray-600 font-medium'>
                    {t("forms:streetLabel")}
                  </label>
                  <input
                    name='street'
                    id='street2'
                    onChange={handleInputChange}
                    value={shippingAddress.street}
                    className='mt-1 w-full border border-gray-300 rounded p-2'
                  />
                </div>
                <div>
                  <label
                    htmlFor='city'
                    className='block text-gray-600 font-medium'>
                    {t("forms:cityLabel")}
                  </label>
                  <input
                    name='city'
                    id='city2'
                    onChange={handleInputChange}
                    value={shippingAddress.city}
                    className='mt-1 w-full border border-gray-300 rounded p-2'
                  />
                </div>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-4'>
                <div>
                  <label
                    htmlFor='postalCode'
                    className='block text-gray-600 font-medium'>
                    {t("forms:postalLabel")}
                  </label>
                  <input
                    name='postalCode'
                    id='postalCode2'
                    onChange={handleInputChange}
                    value={shippingAddress.postalCode}
                    className='mt-1 w-full border border-gray-300 rounded p-2'
                  />
                </div>
                <div>
                  <label
                    htmlFor='phone'
                    className='block text-gray-600 font-medium'>
                    {t("forms:phoneLabel")}
                  </label>
                  <input
                    name='phone'
                    id='phone2'
                    onChange={handleInputChange}
                    value={shippingAddress.phone}
                    className='mt-1 w-full border border-gray-300 rounded p-2'
                  />
                </div>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-4'>
                <div>
                  <label
                    htmlFor='state'
                    className='block text-gray-600 font-medium'>
                    {t("forms:provinceLabel")}
                  </label>
                  <input
                    name='state'
                    id='state2'
                    onChange={handleInputChange}
                    value={shippingAddress.state}
                    className='mt-1 w-full border border-gray-300 rounded p-2'
                  />
                </div>
              </div>
              {formAlert && (
                <div className='text-red-500 text-sm font-semibold mt-2'>
                  {formAlert}
                </div>
              )}
            </form>
          )}
        </div>

        <div className='bg-white rounded-lg shadow p-6 mb-8'>
          <h2 className='text-xl font-semibold mb-4'>
            {t("checkoutPage:timeslotTitle")}
          </h2>
          {deliverySlots.length > 0 ? (
            deliverySlots.map((slot, ind) => (
              <div
                key={ind}
                className='p-3 bg-gray-50 rounded-md shadow my-2 w-full'>
                <span className='font-semibold text-orange-600 mr-2'>
                  {moment(slot.date).format("DD/MM")}
                </span>
                <span className='text-gray-500 text-sm'>{slot.time}</span>
              </div>
            ))
          ) : (
            <div className='text-gray-500 text-sm'>
              {t("checkoutPage:deliveryExplainer")}
            </div>
          )}
          <button
            onClick={handleSetTimeslots}
            className='mt-4 px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600 transition-colors flex items-center'>
            <FaTruck className='mr-2' />
            {t("checkoutPage:editTimeslotButton")}
          </button>
        </div>

        {/* The cart items (merged from CatalogueCart) */}
        <div className='bg-white rounded-lg shadow p-6 mb-8'>
          <h2 className='text-xl font-semibold mb-4'>
            {t("checkoutPage:orderDetails")}
          </h2>
          {emptyCart()}
          {searchTerm.shoppingCart.length > 0 && (
            <>
              <div className='overflow-x-auto'>
                <div className='flex flex-col'>
                  {sanitizedCart.map((item, i) => (
                    <div
                      key={i}
                      className='relative'>
                      {/* Remove item button */}
                      <button
                        className='absolute right-2 top-2 text-red-500 hover:text-red-400 z-10'
                        onClick={() => removeCartItem(i)}>
                        <IoMdClose size={20} />
                      </button>
                      <OrderCard
                        name={item.name}
                        image={item.image}
                        productCost={item.productCost}
                        movingCost={item.movingCost}
                        addOnsTotal={item.addOnsTotal}
                        bonusDiscount={item.bonusDiscount}
                        grandTotal={item.grandTotal}
                      />
                      <hr className='my-4' />
                    </div>
                  ))}
                </div>
              </div>
              {/* Discount code check */}
              <div className='mt-6'>
                <DiscountCodeCheck
                  grandTotal={grandTotal}
                  setGrandTotal={setGrandTotal}
                  shoppingCart={sanitizedCart}
                />
              </div>

              {/* Payment Buttons Row */}
              <div className='flex flex-col justify-end gap-4 mt-6 items-center'>
                {/* Bank Transfer Button */}
                <form onSubmit={handleCreateOrder}>
                  <button
                    disabled={loading || !sanitizedCart.length}
                    className={`btn-main px-6 py-3 flex items-center justify-center 
        ${loading ? "opacity-50 cursor-not-allowed" : ""}`}>
                    <FaRegCreditCard className='w-5 h-5 mr-2' />
                    {loading ? (
                      <LoadingButtonWide />
                    ) : (
                      <h1 className='text-white text-lg lg:text-2xl text-center font-semibold'>
                        {t("utilityPages:bankTransfer")}
                      </h1>
                    )}
                  </button>
                </form>

                {/* Klarna button */}
                <KlarnaPayment
                  shippingAddress={shippingAddress}
                  orderItems={sanitizedCart}
                  deliverySlots={deliverySlots}
                  totalAmount={grandTotal}
                  prepareOrderData={prepareOrderData}
                  navigateToConfirmation={navigateToConfirmation}
                />
              </div>

              {passwordLength && (
                <div className='text-red-500 text-sm mt-2'>
                  {passwordLength}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default CatalogueAddress;
