import axios from "axios";

const baseURL = `${process.env.REACT_APP_URL}`;

const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    return Promise.reject(error);
  }
);

const makeRequest = async (method, url, payload, options = {}) => {
  try {
    const config = {
      method: method,
      url: url,
      ...options,
    };
    // If the payload is an instance of FormData, set the appropriate headers
    if (payload instanceof FormData) {
      config.headers = {
        ...config.headers,
        "Content-Type": "multipart/form-data",
      };
      config.data = payload;
    } else if (payload) {
      // For JSON payloads
      config.headers = {
        ...config.headers,
        "Content-Type": "application/json",
      };
      config.data = JSON.stringify(payload);
    }
    const response = await instance(config);
    return response.data;
  } catch (err) {
    if (err?.response && err?.response.data?.message) {
      const customError = new Error(err.response.data.message);
      customError.response = err.response;
      throw customError;
    } else {
      throw new Error("Server Error");
    }
  }  
};

export const getReq = async (url, options) => makeRequest("get", url, null, options);
export const postReq = async (url, payload, options) => makeRequest("post", url, payload, options);
export const putReq = async (url, payload, options) => makeRequest("put", url, payload, options);
export const delReq = async (url, options) => makeRequest("delete", url, null, options);