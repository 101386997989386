import dayjs from "dayjs";
import i18next from "i18next";

// The code for the "generateDate" and "months" variables is copied from a video by Daily Web Coding, "Build 
// a react tailwind calendar ui from scratch", YouTube (1 November 2022), last viewed: 13 March 2024: 
// https://www.youtube.com/watch?v=s9-K02CP8hw These variables are used in conjunction with the calendar
// display in CatalogueCalendar.js.

const currentLanguage = i18next.language;

export const generateDate = (
    month = dayjs().month(),
    year = dayjs().year()
) => {
    const firstDateOfMonth = dayjs().year(year).month(month).startOf("month");
    const lastDateOfMonth = dayjs().year(year).month(month).endOf("month");

    const arrayOfDate = []

    // create prefix date
    for (let i = 0; i < firstDateOfMonth.day(); i++) {
        arrayOfDate.push({ currentMonth: false, date: firstDateOfMonth.day(i) });
    }

    // generate current date
    for (let i = firstDateOfMonth.date(); i <= lastDateOfMonth.date(); i++) {
        arrayOfDate.push({ currentMonth: true, date: firstDateOfMonth.date(i), today: firstDateOfMonth.date(i).toDate().toDateString() === dayjs().toDate().toDateString() });
    }

    // create suffix date
    const remaining = 42 - arrayOfDate.length;
    for (let i = lastDateOfMonth.date() + 1; i <= lastDateOfMonth.date() + remaining; i++) {
        arrayOfDate.push({ currentMonth: false, date: lastDateOfMonth.date(i) });
    }

    return arrayOfDate;
};

const monthsEn = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

const monthsSv = [
    "Januari",
    "Februari",
    "Mars",
    "April",
    "Maj",
    "Juni",
    "Juli",
    "Augusti",
    "September",
    "Oktober",
    "November",
    "December"
];

export const months = currentLanguage === 'sv' ? monthsSv : monthsEn;

// Time slots for the customer to select a pick-up or delivery.
export const slots = [
    "6:00-9:00",
    "9:00-12:00",
    "12:00-15:00",
    "15:00-18:00",
    "18:00-21:00"
];
