import React, { useState, useEffect } from "react";

const DiscountEditModal = ({ isOpen, onClose, code, value, onSave, flatRateData, percentageData, errorMessage, setErrorMessage }) => {
  const [newCode, setNewCode] = useState(code);
  const [newValue, setNewValue] = useState(value);

  useEffect(() => {
    setNewCode(code);
    setNewValue(value);
    setErrorMessage(""); // Clear the error message when the modal is opened
  }, [code, value, setErrorMessage]);

  const handleSave = () => {
    const existingData = [...flatRateData, ...percentageData].filter(item => item.code !== code);
    const codeExists = existingData.some(item => item.code === newCode);

    if (codeExists) {
      setErrorMessage(`The code "${newCode}" already exists.`);
      return; // Stop execution if the code already exists
    }

    onSave(newCode, newValue);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50">
      <div className="bg-white p-12 rounded-lg">
        <h2 className="text-xl mb-4">Edit Discount Code</h2>
        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
        <label className="block mb-2">
          Code:
          <input
            type="text"
            value={newCode}
            onChange={(e) => setNewCode(e.target.value)}
            className="form-field py-1 w-full"
          />
        </label>
        <label className="block mb-4">
          Value:
          <input
            type="number"
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            className="form-field py-1 w-full"
          />
        </label>
        <div className="flex justify-end mt-6">
          <button onClick={onClose} className="btn-secondary mr-2">
            Cancel
          </button>
          <button onClick={handleSave} className="btn-main">
            Edit
          </button>
        </div>
      </div>
    </div>
  );
};

export default DiscountEditModal;