import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HiPlus } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import { getReq } from "../../configs/ApiUrl";
import { LoadingSpinner } from "./util/LoadingSpinner";
import PageSubheader from "../PageSubheader";
import defaultImg from "../../images/defaultImg.webp";

function SubadminProductStatus() {
  const { t } = useTranslation(["admin", "subadmin", "altText", "userProfile"]);
  const [loading, setLoading] = useState(false);
  const [historyProducts, setHistoryProducts] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const length = 10;

  const fetchUserProfile = async () => {
    try {
      const profileResp = await getReq("/user/profile");
      if (profileResp?.user) {
        setUserEmail(profileResp.user.email);
      } else {
        console.error("Failed to fetch user profile:", profileResp.message);
      }
    } catch (error) {
      console.error("Error fetching user profile:", error.message);
    }
  };

  const getAllHistoryProducts = async () => {
    try {
      setLoading(true);
      const productsResp = await getReq(`/product/all`);

      if (productsResp?.allProducts && userEmail) {
        const filteredProducts = productsResp.allProducts.filter(
          (product) =>
            ["declined", "placed", "sold", "approved", "preApproved", "pending", "deliveryApproved"].includes(
              product.status
            ) && product.subadminEmail === userEmail
        );
        const sortedProducts = [...filteredProducts].sort((a, b) => {
          return new Date(a.productDate) - new Date(b.productDate);
        });
        setHistoryProducts(sortedProducts);
      } else {
        console.error("Failed to fetch products");
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserProfile(); // Fetch user email on component mount
  }, []);

  useEffect(() => {
    if (userEmail) {
      getAllHistoryProducts(); // Fetch history products after user email is determined
    }
  }, [userEmail]);

  const getClassNames = (status, index) => {
    const baseCircle = "w-12 h-12 rounded-full flex items-center justify-center";
    const baseLine = "flex-1 h-1 mb-6 min-w-[20px]";

    switch (status) {
      case "pending":
        if (index === 0)
          return {
            circle: `${baseCircle} bg-green-500`,
            line: `${baseLine} bg-gray-300`,
            showCheck: true,
          };
        break;

      case "approved":
        if (index <= 1)
          return {
            circle: `${baseCircle} bg-green-500`,
            line: index === 1 ? `${baseLine} bg-gray-300` : `${baseLine} bg-green-500`,
            showCheck: true,
          };
        break;

      case "preApproved":
        if (index <= 2)
          return {
            circle: `${baseCircle} bg-green-500`,
            line: index === 2 ? `${baseLine} bg-gray-300` : `${baseLine} bg-green-500`,
            showCheck: true,
          };
        break;

      case "placed":
        if (index <= 3)
          return {
            circle: `${baseCircle} bg-green-500`,
            line: index === 3 ? `${baseLine} bg-gray-300` : `${baseLine} bg-green-500`,
            showCheck: true,
          };
        break;

      case "sold":
        if (index <= 4)
          return {
            circle: `${baseCircle} bg-green-500`,
            line: index === 4 ? `${baseLine} bg-gray-300` : `${baseLine} bg-green-500`,
            showCheck: true,
          };
        break;

      case "deliveryApproved":
        if (index <= 5)
          return {
            circle: `${baseCircle} bg-green-500`,
            line: `${baseLine} bg-green-500`,
            showCheck: true,
          };
        break;

      default:
        break;
    }
    return {
      circle: `${baseCircle} bg-white border-2 border-gray-300`,
      line: `${baseLine} bg-gray-300`,
      showCheck: false,
    };
  };

  const getStatusMessage = (status, title) => {
    switch (status) {
      case "declined":
        return `${title} is no longer for sale`;
      case "pending":
        return `${title} is awaiting admin approval`;
      case "approved":
        return `${title} is live on our marketplace`;
      case "preApproved":
        return `${title} is live on our marketplace`;
      case "placed":
        return `${title} has been ordered but not paid for yet`;
      case "sold":
        return `${title} is due to be delivered to the buyer`;
      case "deliveryApproved":
        return `${title} has been delivered`;
      default:
        return "";
    }
  };

  return (
    <div className='flex flex-col items-center'>
      <PageSubheader
        backLink='/subadmin-menu'
        title={t("subadmin:productStatusButton")}
        description={t("subadmin:productStatusExplainer")}
      />
      <main className='w-full flex flex-col justify-center bg-white drop-shadow'>
        <div className='overflow-x-auto'>
          {loading ? (
            <LoadingSpinner />
          ) : historyProducts.length > 0 ? (
            <table className='table-auto w-full border-collapse rounded-t-xl -p-1 text-center'>
              <tbody>
                {historyProducts.map((currentObject) => {
                  const trimmedName =
                    currentObject.name?.length > length
                      ? `${currentObject.name.substring(0, length)}...`
                      : currentObject.name;
                  const statusMessage = getStatusMessage(
                    currentObject.status,
                    currentObject.title
                  );

                  return (
                    <tr
                      key={currentObject._id}
                      className='hover:bg-background'>
                      <td className='relative border-b-4 border-b-cromboOrangeFade'>
                        <div className='flex flex-col items-center'>
                          <img
                            className='w-full min-w-[200px] max-w-xs h-auto mx-auto object-cover'
                            src={
                              currentObject.mainImage ||
                              currentObject.imageNames[0] ||
                              defaultImg
                            }
                            alt={t("altText:firstImage")}
                            onError={(e) => {
                              e.target.src = defaultImg;
                            }}
                          />
                          <div className='table-overlay'>
                            <span className='text-xs truncate'>
                              {currentObject.title.length > 10
                                ? `${currentObject.title.substring(0, 15)}...`
                                : currentObject.title}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td className='flex-col justify-start px-12 py-4 bg-background border-b-4 border-cromboOrangeFade text-slate-500'>
                        <div className='flex items-center w-full'>
                          {[
                            "Added",
                            "Approved",
                            "Chosen",
                            "Placed",     // Added "Placed" here
                            "Sold",
                            "Delivered",
                          ].map((label, index) => {
                            const { circle, line, showCheck } = getClassNames(
                              currentObject.status,
                              index
                            );
                            return (
                              <React.Fragment key={index}>
                                {index !== 0 && <div className={line}></div>}
                                <div className='relative flex flex-col items-center mx-2'>
                                  <div className={circle}>
                                    {showCheck && (
                                      <svg
                                        className='w-6 h-6 text-white'
                                        fill='none'
                                        stroke='currentColor'
                                        viewBox='0 0 24 24'
                                        xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                          strokeLinecap='round'
                                          strokeLinejoin='round'
                                          strokeWidth='2'
                                          d='M5 13l4 4L19 7'></path>
                                      </svg>
                                    )}
                                  </div>
                                  <div className='mt-2 font-semibold text-lg'>
                                    {label}
                                  </div>
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                        <p className='mt-8 text-md font-semibold text-gray-500'>
                          {statusMessage}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className='flex items-center justify-center w-96 h-96 bg-white'>
              <h1 className='text-xl font-bold text-center text-gray-500'>
                {t("admin:noProducts")}
              </h1>
            </div>
          )}
        </div>
        <div className='flex justify-center my-12'>
          <Link to='/subadmin-add-product'>
            <button className='flex items-center text-cromboOrange font-bold text-2xl'>
              <HiPlus
                className='mr-2 h-20 w-20'
                alt={t("altText:plus")}
              />
              {t("subadmin:newProductButton")}
            </button>
          </Link>
        </div>
      </main>
    </div>
  );
}

export default SubadminProductStatus;
