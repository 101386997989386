import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Delete } from "../../icons/Trash-Icon.svg";
import { useTranslation } from "react-i18next";
import { getReq, delReq } from "../../configs/ApiUrl";
import { LoadingSpinner } from "./util/LoadingSpinner";
import DeleteWarningModal from "./util/DeleteWarningModal";
import locationMark from "../../images/locationMark.svg";
import AlwaysTop from "./util/AlwaysTop.js";
import defaultImg from "../../images/defaultImg.webp";

function ProductsHistory() {
  const { t } = useTranslation(["admin", "tables", "altText", "subadmin"]);
  const [loading, setLoading] = useState(false);
  const [historyItems, setHistoryItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [loadingStates, setLoadingStates] = useState({});
  const [userRole, setUserRole] = useState("");
  const [subadminEmail, setSubadminEmail] = useState("");
  const [courier, setCourier] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const fetchUserProfile = async () => {
    try {
      const userProfileResp = await getReq("/user/profile");
      const userId = userProfileResp?.user?._id;
      const email = userProfileResp?.user?.email;
      setUserEmail(email); // Set `userEmail` from profile response

      if (!userId) {
        console.error("User ID not found in profile response");
        return;
      }

      // Fetch and set the role
      const roleResp = await getReq(`/user/${userId}`);
      if (roleResp?.success) {
        const { role } = roleResp;
        setUserRole(role);
      } else {
        console.error("Failed to fetch user role:", roleResp.message);
      }
    } catch (error) {
      console.error("Error fetching user role:", error.message);
    }
  };

  const getAllHistoryItems = async () => {
    try {
      setLoading(true);
      const response = await getReq("/order/all");
      if (response?.success) {
        let filteredOrders = response.allOrders.filter((order) =>
          ["cancelled", "delivered"].includes(order.orderStatus)
        );

        if (userRole === "subadmin") {
          filteredOrders = filteredOrders.filter(
            (order) => order.subadminEmail === userEmail
          );
        }

        const sortedOrders = filteredOrders.sort(
          (a, b) => new Date(a.orderDate) - new Date(b.orderDate)
        );
        setHistoryItems(sortedOrders);
      }
    } catch (error) {
      console.error("Error fetching history items:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDecline = async (id) => {
    try {
      setLoadingStates((prevState) => ({
        ...prevState,
        [id]: true,
      }));

      const response = await delReq(`/order/delete/${id}`);
      if (response?.success) {
        setLoadingStates((prevState) => ({
          ...prevState,
          [id]: false,
        }));
        await getAllHistoryItems();
      } else {
        setLoadingStates((prevState) => ({
          ...prevState,
          [id]: false,
        }));
      }
    } catch (error) {
      console.error("Error declining item:", error);
      setLoadingStates((prevState) => ({
        ...prevState,
        [id]: false,
      }));
    }
  };

  useEffect(() => {
    AlwaysTop();
    fetchUserProfile();
  }, []);

  useEffect(() => {
    if (userRole && userEmail) {
      // Wait until both are set
      getAllHistoryItems();
    }
  }, [userRole, userEmail]);

  const openDeleteModal = (id) => {
    setSelectedItemId(id);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setSelectedItemId(null);
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    if (selectedItemId) {
      await handleDecline(selectedItemId);
      closeDeleteModal();
    }
  };

  return (
    <div className='flex flex-col items-center'>
      {showDeleteModal && (
        <DeleteWarningModal
          onConfirm={handleConfirmDelete}
          onClose={closeDeleteModal}
        />
      )}

      <div className='flex flex-col items-center px-2 w-full'>
        <main className='w-full flex flex-col justify-center mb-10 bg-white drop-shadow'>
          <div className='overflow-x-auto w-full'>
            {loading ? (
              <LoadingSpinner />
            ) : historyItems.length > 0 ? (
              <table className='table-auto w-full border-collapse rounded-t-xl'>
                <thead>
                  <tr>
                    <th className='px-24 py-2 bg-none border-b-4 border-cromboOrangeFade text-black font-semibold'>
                      {t("tables:image")}
                    </th>
                    <th className='px-4 py-2 border-b-4 border-cromboOrangeFade text-black font-semibold'>
                      {t("admin:grandTotalHeader")}
                    </th>
                    {userRole === "admin" && (
                      <>
                        <th className='px-4 py-2 border-b-4 border-cromboOrangeFade text-black font-semibold'>
                          {t("tables:address")}
                        </th>
                        <th className='px-4 py-2 border-b-4 border-cromboOrangeFade text-black font-semibold'>
                          {t("forms:phoneLabel")}
                        </th>
                      </>
                    )}
                    <th className='px-2 border-b-4 border-cromboOrangeFade text-black font-semibold'>
                      {t("admin:actionHeader")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {historyItems.map((item) => {
                    const itemName = item.orderItems[0]?.product?.title;
                    const itemImage =
                      item.orderItems[0]?.product?.imageNames?.[0] ||
                      defaultImg;
                    const itemGrandTotal = item.orderItems[0]?.grandTotal;
                    const itemAddress = `${item?.shippingAddress?.street}, ${item?.shippingAddress?.state}, ${item?.shippingAddress?.city}.`;
                    const itemPhone = item?.shippingAddress?.phone;

                    return (
                      <tr
                        key={item._id}
                        className='hover:bg-background'>
                        <td className='relative border-b-4 border-cromboOrangeFade px-2 py-2'>
                          <div className='relative flex flex-col items-center'>
                            <img
                              className='w-full max-w-xs h-auto mx-auto'
                              src={itemImage}
                              alt={t("altText:firstImage")}
                              onError={(e) => {
                                e.target.src = defaultImg;
                              }}
                            />
                            <div className='absolute bottom-0 left-0 bg-black bg-opacity-50 text-white text-center w-full py-1'>
                              <span className='truncate text-sm'>
                                {itemName?.length > 20
                                  ? `${itemName.substring(0, 15)}...`
                                  : itemName}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td className='px-4 py-2 bg-background border-b-4 border-cromboOrangeFade text-slate-500'>
                          <div className='text-xl font-bold text-cromboOrange whitespace-nowrap'>
                            {itemGrandTotal ?? "N/A"} kr.
                          </div>
                        </td>
                        {userRole === "admin" && (
                          <>
                            <td className='px-4 py-2 bg-background border-b-cromboOrangeFade border-4 border-r-2 border-white text-slate-500'>
                              <div className='flex flex-col items-center'>
                                <img
                                  className='w-8 h-8 mb-2'
                                  src={locationMark}
                                  alt={t("altText:location")}
                                />
                                <span className='text-center'>
                                  {itemAddress}
                                </span>
                              </div>
                            </td>
                            <td className='px-4 py-2 bg-background border-b-cromboOrangeFade border-4 border-r-2 border-white text-slate-500'>
                              <span className='py-1'>{itemPhone}</span>
                            </td>
                          </>
                        )}
                        <td className='px-4 py-2 bg-background border-2 border-b-4 border-b-cromboOrangeFade border-r text-slate-500 border-white text-center'>
                          <div className='flex flex-col sm:flex-row justify-center items-center gap-4 '>
                            <div
                              className='flex items-center justify-center p-4 rounded-lg bg-white border-2 border-white text-red-600 hover:text-red-700 cursor-pointer drop-shadow'
                              onClick={() => openDeleteModal(item._id)}>
                              <Delete className='h-6 w-6' />
                              <h4 className='ml-2 text-sm font-bold'>
                                {t("deleteButton")}
                              </h4>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className='flex items-center justify-center w-full h-96 bg-white'>
                <h1 className='text-xl font-bold text-center text-gray-500'>
                  {t(
                    userRole === "admin" ? "admin:noOrders" : "admin:noProducts"
                  )}
                </h1>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}

export default ProductsHistory;
