import React, { createContext, useState, useEffect } from 'react';
import { getReq } from '../../../configs/ApiUrl';

const AuthContext = createContext();

function safeJsonParse(item, defaultValue) {
    try {
        return JSON.parse(item);
    } catch (e) {
        console.warn(`Failed to parse JSON for item: ${item}`, e);
        return defaultValue;
    }
}

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({
        isAuthenticated: false,
        user: null,
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('token');
        const favouriteProducts = safeJsonParse(localStorage.getItem('u'), []); // Parse safely
        const userId = localStorage.getItem('userId');

        const fetchUserRole = async () => {
            try {
                if (token && userId) {
                    // Fetch user role from the backend
                    const roleResp = await getReq(`/user/${userId}`);
                    if (roleResp?.success) {
                        const { role } = roleResp;
                        setAuth({
                            isAuthenticated: true,
                            user: {
                                _id: userId,
                                role,
                                favouriteProducts,
                            },
                        });
                    } else {
                        console.error("Failed to fetch user role:", roleResp.message);
                    }
                }
            } catch (error) {
                console.error("Error fetching user role:", error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserRole();
    }, []);

    const login = (user, token) => {
        setAuth({ isAuthenticated: true, user });

        // Store only necessary fields in localStorage
        localStorage.setItem('token', token);
        localStorage.setItem('u', JSON.stringify(user.favouriteProducts || [])); // Ensure JSON format
        localStorage.setItem('userId', user._id);
    };

    const logout = () => {
        setAuth({ isAuthenticated: false, user: null });

        // Remove items from localStorage
        localStorage.removeItem('token');
        localStorage.removeItem('u');
        localStorage.removeItem('userId');
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <AuthContext.Provider value={{ auth, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
